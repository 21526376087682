/*
 * File: Map.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on April 18, 2022 at 11:28 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: April 22, 2024 at 2:07 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValueCreator } from '../../styles/colors';


/**
 * Styles
 */

export const Map = styled.div`
	width: 100%;
	height: 100%;

	.marker {
		background-color: ${({ theme }) => theme.brandPrimaryBase};
		width: 30px;
		height: 30px;
		border-radius: 50%;
		box-shadow: ${ColorValueCreator().elementShadow};
		border: 2px solid white;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		* {
			font-family: 'Lexend', sans-serif;
			font-size: 0.9rem;
			color: ${ColorValueCreator().white};
		}
		svg {
			height: 0.8rem;
		}
		path {
			fill: ${ColorValueCreator().white};
		}
	}
`;
