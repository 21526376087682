/*
 * File: storage.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on April 22, 2024 at 12:34 PM
 * Copyright © 2024 Lithios, LLC. All rights reserved.
 *
 * Last Modified: April 22, 2024 at 12:34 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Utilities
 */

export const storeItem = (key, value) => {
	try {
		window.sessionStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
	} catch (e) {}
};

export const getStorageItem = (key) => {
	try {
		const content = window.sessionStorage.getItem(key);
		try {
			const json = JSON.parse(content);
			return json;
		} catch (e) {}
		return content;
	} catch (e) {}
	return null;
};

export const removeStorageItem = (key) => {
	try {
		window.sessionStorage.removeItem(key);
	} catch (e) {}
};

export const clearSessionStorage = () => {
	try {
		window.sessionStorage.clear();
	} catch (e) {}
};
