/*
 * File: MapTab.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 6, 2023 at 11:59 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:21 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import { LocationDetail, Map } from '../../../../../../components';

// Constants
import { SAMPLE_POINTS_OF_INTEREST } from '../../../../../../../Constants';

// Styles
import * as S from './MapTab.styles';


/**
 * Component
 */

const MapTab = () => {

	// Create state handlers
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [overlayExpanded, setOverlayExpanded] = useState(false);

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Handle select location
	const selectLocation = (locationId) => {

		// Get active location
		const activeLocation = SAMPLE_POINTS_OF_INTEREST(currentCityApp).find((loc) => loc.id === locationId);

		// Set selected location
		setSelectedLocation(activeLocation);
	};

	// Generate overlay position
	const generateOverlayHeight = (location, isExpanded) => {
		if (!location) return '0px';
		return isExpanded ? '100%' : '40%';
	};

	// Render component function
	const renderComponent = () => (
		<>
			{/* Detail Overlay */}
			<S.DetailOverlay
				$height={generateOverlayHeight(selectedLocation, overlayExpanded)}
				className="animate scroller"
				id="scroller-map-tab"
				onClick={() => { setOverlayExpanded(!overlayExpanded); }}
			>
				<S.Grabber />
				{selectedLocation && <LocationDetail location={selectedLocation} />}
			</S.DetailOverlay>

			{/* Map */}
			<S.MapContainer>
				<Map
					zoom={13}
					isPreview={false}
					locationId="locator"
					center={currentCityApp?.centerCoordinates}
					flyTo={selectedLocation ? selectedLocation.coordinates : null}
					pointsOfInterest={SAMPLE_POINTS_OF_INTEREST(currentCityApp)?.filter((location) => location.coordinates != null).map((location, index) => ({
						id: location.id,
						coordinates: location.coordinates,
						type: 'featured',
						index,
						onClick: (id) => { selectLocation(id, true); }
					}))}
				/>
			</S.MapContainer>
		</>
	);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.Wrapper>{renderComponent()}</S.Wrapper>
	);
};


/**
 * Configuration
 */

MapTab.propTypes = {};
MapTab.defaultProps = {};


/**
 * Exports
 */

export default MapTab;
