/*
 * File: Onboarding.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:20 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useWindowResize } from 'beautiful-react-hooks';

// Utilities
import { createStateLocale } from '../../../../../utilities/locale';

// Components
import {
	AppNavigation,
	Meta
} from '../../../../../components';

// Step Components
import { StartStep } from './StartStep';
import { InterestsStep } from './InterestsStep';
import { NotificationsStep } from './NotificationsStep';
import { ConfirmationStep } from './ConfirmationStep';

// Styles
import * as S from './Onboarding.styles';


/**
 * Component
 */

const Onboarding = ({ meta, locale }) => {

	// Create reference for step components
	const [startStepHeight, setStartStepHeight] = useState(0);
	const [interestsStepHeight, setInterestsStepHeight] = useState(0);
	const [notificationsStepHeight, setNotificationsStepHeight] = useState(0);
	const [confirmationStepHeight, setConfirmationStepHeight] = useState(0);
	const startStepRef = useRef();
	const interestsStepRef = useRef();
	const notificationsStepRef = useRef();
	const confirmationStepRef = useRef();

	// Create state handlers
	const [currentStep, setCurrentStep] = useState(1);
	const [shouldUpdateHeight, setShouldUpdateHeight] = useState(true);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Handle actions on component load
	useEffect(() => {
		if (shouldUpdateHeight) {

			// Set component heights
			setStartStepHeight(startStepRef?.current?.clientHeight);
			setInterestsStepHeight(interestsStepRef?.current?.clientHeight);
			setNotificationsStepHeight(notificationsStepRef?.current?.clientHeight);
			setConfirmationStepHeight(confirmationStepRef?.current?.clientHeight);

			// Update state
			setShouldUpdateHeight(false);
		}
	}, [shouldUpdateHeight]);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set component heights
		setStartStepHeight(startStepRef?.current?.clientHeight);
		setInterestsStepHeight(interestsStepRef?.current?.clientHeight);
		setNotificationsStepHeight(notificationsStepRef?.current?.clientHeight);
		setConfirmationStepHeight(confirmationStepRef?.current?.clientHeight);
	});

	// Get step height for step component
	const getStepHeight = () => {
		switch (currentStep) {
			case 1:
				return startStepHeight;
			case 2:
				return interestsStepHeight;
			case 3:
				return notificationsStepHeight;
			case 4:
				return confirmationStepHeight;
			default:
				return 0;
		}
	};

	// Render component function
	const renderComponent = () => (
		<S.StepContainer className="animate" stepHeight={getStepHeight()}>
			<S.Step className={currentStep === 1 ? 'animate show' : 'animate'}>
				<StartStep ref={startStepRef} updateStep={setCurrentStep} updateHeight={setShouldUpdateHeight} />
			</S.Step>
			<S.Step className={currentStep === 2 ? 'animate show' : 'animate'}>
				<InterestsStep ref={interestsStepRef} updateStep={setCurrentStep} updateHeight={setShouldUpdateHeight} />
			</S.Step>
			<S.Step className={currentStep === 3 ? 'animate show' : 'animate'}>
				<NotificationsStep ref={notificationsStepRef} updateStep={setCurrentStep} updateHeight={setShouldUpdateHeight} />
			</S.Step>
			<S.Step className={currentStep === 4 ? 'animate show' : 'animate'}>
				<ConfirmationStep ref={confirmationStepRef} updateStep={setCurrentStep} updateHeight={setShouldUpdateHeight} isVisible={currentStep === 4} />
			</S.Step>
		</S.StepContainer>
	);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{ app: currentCityApp || { municipalityName: 'MyCity' } }} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>{renderComponent()}</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Onboarding.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Onboarding.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Onboarding;
