/*
 * File: UtilitiesScreen.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 6, 2023 at 11:59 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:21 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Card, LocaleLink, Padding } from '../../../../../../../components';

// Styles
import { ColorValueCreator } from '../../../../../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	background-color: ${ColorValueCreator().neutralBackground};

	h2 {
		text-align: left;
	}
`;

export const UtilitiesCard = styled(Card)`
	box-shadow: ${ColorValueCreator().elementDarkShadow};
	padding: 15px 0px;
`;

export const TitleSection = styled.div`
	border-bottom: 1px solid ${ColorValueCreator().neutralForm};
	padding: 0px 0px 4px;

	h4 {
		text-align: left;
	}
`;

export const UtilitiesSection = styled.div`
	border-bottom: 1px solid ${ColorValueCreator().neutralForm};
	padding: 16px 0px;

	* {
		text-align: left;
	}
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
`;

export const ButtonLink = styled(LocaleLink)`
	text-decoration: none;
	width: 100%;

	button {
		width: 100%;
		margin-top: 20px;
		background-color: ${({ theme }) => theme.brandPrimaryBase};
		border-color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;

export const DetailGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
	margin: 10px 0px;
`;

export const Detail = styled.div`
	h3 {
		margin-top: -4px;
	}
`;

export const TitleContainer = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	margin: 14px 0px 10px;
`;
