/*
 * File: Notifications.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on December 4, 2023 at 11:50 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:20 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Padding } from '../../../../../components';

// Styles
import { ColorValueCreator } from '../../../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

export const NavigationBar = styled.div`
	height: 58px;
	color: ${ColorValueCreator().white};
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: ${ColorValueCreator().navigationShadow};
	position: relative;
	z-index: 99;

	h1 {
		color: ${({ theme }) => theme.brandPrimaryBase};
		flex-grow: 1;
		text-align: left;
	}
	svg {
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
	& > div {
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
	align-items: start;
`;

export const NotificationsContent = styled.div`
	flex-grow: 1;
	overflow: scroll;
	height: 100%;
`;

export const NotificationCollection = styled.div`
	display: flex;
`;
