/*
 * File: EventCard.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:54 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: April 22, 2024 at 2:11 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValueCreator } from '../../styles/colors';

// Components
import { LocaleLink } from '../LocaleLink';


/**
 * Styles
 */

export const EventCard = styled(LocaleLink)`
	background-color: ${({ theme }) => theme.cardBackground};
	border-radius: 4px;
	box-shadow: ${ColorValueCreator().elementDarkShadow};
	display: flex;
	gap: 16px;
	padding: 16px;
	text-decoration: none;
`;

export const EventImage = styled.div`
	width: 100px;
	height: 144px;
	flex-shrink: 0;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: cover;
	border-radius: 6px;
	overflow: hidden;
`;

export const EventDetails = styled.div`
	flex-grow: 1;
`;

export const TitleRow = styled.div`
	display: flex;
	gap: 6px;

	h4 {
		flex-grow: 1;
		text-align: left;
		color: ${ColorValueCreator().dark} !important;
	}
	button {
		margin-top: 4px;
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;

export const Details = styled.div`
	margin: 6px 0px 0px;
	display: flex;
	flex-direction: column;
	gap: 3px;
`;

export const DetailRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	gap: 8px;

	* {
		text-align: left;
	}
	svg {
		color: ${({ theme }) => theme.brandSecondaryBase};
		font-size: 0.9rem;
		width: 14px;
	}
`;

export const TagContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 4px;
	margin: 10px 0px;
`;

export const Tag = styled.div`
	height: 22px;
	display: flex;
	align-items: center;
	gap: 5px;
	background-color: ${({ $colorBase }) => ColorValueCreator()[`${$colorBase}Lighter`]};
	color: ${({ $colorBase }) => ColorValueCreator()[`${$colorBase}Base`]};
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 11px;

	svg {
		font-size: 0.9rem;
	}
`;
