/*
 * File: EventsTab.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 6, 2023 at 11:59 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:22 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

// Components
import { EventCard, Typography } from '../../../../../../components';

// Constants
import { SAMPLE_EVENTS } from '../../../../../../../Constants';

// Styles
import * as S from './EventsTab.styles';


/**
 * Constants
 */

const INTERESTS = [
	{
		id: 'arts',
		title: 'Arts',
		icon: ['fal', 'theater-masks']
	},
	{
		id: 'food',
		title: 'Food',
		icon: ['fal', 'utensils']
	},
	{
		id: 'parks',
		title: 'Parks',
		icon: ['fal', 'tree']
	}
];


/**
 * Component
 */

const EventsTab = () => {

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Render component function
	const renderComponent = () => (
		<>

			{/* Banner */}
			<S.HeaderBanner $image={`${process.env.CDN_URL}/public/assets/events-banner.png`}>
				<S.ContentPadding left right>
					<Typography tag="h1" weight="bold">
						Cool things are happening in your city.
					</Typography>
				</S.ContentPadding>
			</S.HeaderBanner>

			{/* Categories Section */}
			<S.CategoriesSection>
				<S.ContentPadding left right>

					{/* Title */}
					<Typography tag="h4" weight="semibold">Categories</Typography>

					{/* Category Grid */}
					<S.CategoryGrid>
						{INTERESTS.map((interest) => (
							<S.Category className="animate" key={interest.id}>
								<FontAwesomeIcon icon={interest.icon} />
								<Typography weight="semibold">{interest.title}</Typography>
							</S.Category>
						))}
					</S.CategoryGrid>
				</S.ContentPadding>
			</S.CategoriesSection>

			{/* Events Section */}
			<S.EventsSection>
				<S.ContentPadding left right>

					{/* Title */}
					<Typography tag="h4" weight="semibold">Upcoming Events Near You</Typography>

					{/* Events Container */}
					<S.EventsContainer>
						{SAMPLE_EVENTS(currentCityApp).map((event) => <EventCard event={event} key={event.id} />)}
					</S.EventsContainer>

				</S.ContentPadding>
			</S.EventsSection>
		</>
	);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.Wrapper>{renderComponent()}</S.Wrapper>
	);
};


/**
 * Configuration
 */

EventsTab.propTypes = {};
EventsTab.defaultProps = {};


/**
 * Exports
 */

export default EventsTab;
