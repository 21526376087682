/*
 * File: Home.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:21 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// Utilities
import { createStateLocale } from '../../../../../utilities/locale';

// Components
import {
	AppNavigation,
	ChatWidget,
	IconButton,
	Meta,
	Typography
} from '../../../../../components';
import { EventsTab } from './EventsTab';
import { HomeTab } from './HomeTab';
import { MapTab } from './MapTab';
import { MoreTab } from './MoreTab';
import { ProfileTab } from './ProfileTab';

// Styles
import * as S from './Home.styles';


/**
 * Constants
 */

const TABS = {
	HOME: 'home',
	EVENTS: 'events',
	MAP: 'map',
	PROFILE: 'profile',
	MORE: 'more'
};


/**
 * Component
 */

const Home = ({ meta, locale }) => {

	// Get location state
	const location = useLocation();
	const { hash } = location;

	// Get hooks
	const navigate = useNavigate();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get default tab
	const defaultTab = hash ? Object.values(TABS).find((tab) => hash.replace('#', '') === tab) : TABS.HOME;

	// Create state handlers
	const [currentTab, setCurrentTab] = useState(defaultTab || TABS.HOME);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Render component function
	const renderComponent = () => {
		switch (currentTab) {
			case TABS.HOME:
				return <HomeTab />;
			case TABS.EVENTS:
				return <EventsTab />;
			case TABS.MAP:
				return <MapTab />;
			case TABS.PROFILE:
				return <ProfileTab />;
			case TABS.MORE:
				return <MoreTab />;
			default:
				break;
		}
		return null;
	};

	// Render tab title
	const renderTabTitle = () => {
		switch (currentTab) {
			case TABS.HOME:
				return 'Home';
			case TABS.EVENTS:
				return 'Events';
			case TABS.MAP:
				return 'Map';
			case TABS.PROFILE:
				return 'Profile';
			case TABS.MORE:
				return 'More';
			default:
				break;
		}
		return null;
	};

	// Handle changes to current tab
	useEffect(() => {

		// Update url
		window.history.replaceState(null, null, `${stateLocale.localePath}/app/${currentCityApp?.accessId}/${searchParams.toString() ? `?${searchParams.toString()}` : ''}#${currentTab}`);

	}, [currentTab]);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{ app: currentCityApp || { municipalityName: 'MyCity' } }} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>

					{/* Navigation Bar */}
					<S.NavigationBar>
						<S.ContentPadding left right>
							<Typography tag="h1" weight="bold">{renderTabTitle()}</Typography>
							<S.LogoContainer>
								{currentCityApp?.accessId !== 'city-app' && (
									<S.Logo
										alt={`${currentCityApp?.municipalityName} Logo`}
										title={`${currentCityApp?.municipalityName} Logo`}
										src={currentCityApp?.logoImage}
									/>
								)}
							</S.LogoContainer>
							<S.NavButton>
								<IconButton icon={['fal', 'bell']} size={1.4} onClick={() => { navigate(`${stateLocale.localePath}/app/${currentCityApp?.accessId}/notifications${searchParams.toString() ? `?${searchParams.toString()}` : ''}`); }} />
							</S.NavButton>
						</S.ContentPadding>
					</S.NavigationBar>

					{/* Tab Content */}
					<S.TabContent className="scroller" id="scroller-home">
						{renderComponent()}
					</S.TabContent>

					{/* Chat Widget */}
					<ChatWidget />

					{/* Tab Bar */}
					<S.TabBar>
						<S.TabContainer onClick={() => { setCurrentTab(TABS.HOME); }}>
							<S.Tab $isActive={currentTab === TABS.HOME}>
								<FontAwesomeIcon icon={['fal', 'home-alt']} />
								<Typography variation="3" weight="light">Home</Typography>
							</S.Tab>
						</S.TabContainer>
						<S.TabContainer onClick={() => { setCurrentTab(TABS.EVENTS); }}>
							<S.Tab $isActive={currentTab === TABS.EVENTS}>
								<FontAwesomeIcon icon={['fal', 'calendar']} />
								<Typography variation="3" weight="light">Events</Typography>
							</S.Tab>
						</S.TabContainer>
						<S.TabContainer onClick={() => { setCurrentTab(TABS.MAP); }}>
							<S.Tab $isActive={currentTab === TABS.MAP}>
								<FontAwesomeIcon icon={['fal', 'map']} />
								<Typography variation="3" weight="light">Map</Typography>
							</S.Tab>
						</S.TabContainer>
						<S.TabContainer onClick={() => { setCurrentTab(TABS.PROFILE); }}>
							<S.Tab $isActive={currentTab === TABS.PROFILE}>
								<FontAwesomeIcon icon={['fal', 'user']} />
								<Typography variation="3" weight="light">Profile</Typography>
							</S.Tab>
						</S.TabContainer>
						<S.TabContainer onClick={() => { setCurrentTab(TABS.MORE); }}>
							<S.Tab $isActive={currentTab === TABS.MORE}>
								<FontAwesomeIcon icon={['fal', 'ellipsis-h']} />
								<Typography variation="3" weight="light">More</Typography>
							</S.Tab>
						</S.TabContainer>
					</S.TabBar>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Home.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Home.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Home;
