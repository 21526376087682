/*
 * File: Error.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:22 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../../../utilities/locale';

// Components
import {
	Meta, ErrorComponent, AppNavigation, LocaleLink
} from '../../../../../components';

// Content
import BlueWaveDecoration from '../../../../../assets/images/BlueWaveDecoration.svg';

// Styles
import * as S from './Error.styles';


/**
 * Component
 */

const Error = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{ app: currentCityApp || { municipalityName: 'MyCity' } }} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>
					<S.ContentPadding left right dynamic>

						{/* Logo Container */}
						<S.LogoContainer>
							<LocaleLink to={`/app/${currentCityApp?.accessId}`}>
								<S.Logo
									alt={`${currentCityApp?.municipalityName} Logo`}
									title={`${currentCityApp?.municipalityName} Logo`}
									src={currentCityApp?.logoImage}
								/>
							</LocaleLink>
						</S.LogoContainer>

						{/* Error Component */}
						<ErrorComponent className="fullComponent" />

						{/* Decoration */}
						<S.Decoration
							$color={currentCityApp?.primaryColor}
							dangerouslySetInnerHTML={{ __html: BlueWaveDecoration }}
						/>

					</S.ContentPadding>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Error.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Error.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Error;
