/*
 * File: LocationDetail.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 19, 2022 at 12:20 AM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: April 22, 2024 at 2:11 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { Padding } from '../Padding';

// Constants
import { ColorValueCreator } from '../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	height: 100%;
	width: 100%;
`;

export const EventDetailSection = styled.div`
	padding: 12px 0px;
	width: 100%;

	${({ $showBorder }) => $showBorder && css`
		border-bottom: 1px solid ${({ theme }) => theme.layoutBorder};
	`}
	.secondary {
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const EventDetailTitle = styled.div`
	margin: 0px 0px 0px;

	p {
		color: ${({ theme }) => theme.secondaryText};
		text-align: left;
		margin: 0px 0px 4px;
	}
`;

export const TitleRow = styled.div`
	display: flex;
	gap: 6px;

	h4 {
		flex-grow: 1;
		text-align: left;
		color: ${ColorValueCreator().dark} !important;
	}
	button {
		margin-top: 4px;
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;

export const Details = styled.div`
	margin: 6px 0px 0px;
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

export const DetailRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	gap: 8px;

	* {
		text-align: left;
	}
	svg {
		color: ${({ theme }) => theme.brandPrimaryBase};
		font-size: 0.9rem;
		width: 14px;
	}
`;

export const Description = styled.div`
	margin: 6px 0px 0px;

	* {
		text-align: left;
	}
`;

export const Footer = styled.div`
	width: 100%;
	height: 40px;
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
	align-items: start;
`;

export const ReviewSummary = styled.div`
	display: flex;
	gap: 12px;
	margin: 2px 0px 0px;
	align-items: center;
`;

export const StarsContainer = styled.div`
	display: flex;
	gap: 3px;
	align-items: center;

	svg {
		font-size: 0.9rem;
		color: ${({ theme }) => theme.brandSecondaryBase};
	}
`;

export const ImageSection = styled.div`
	display: grid;
	grid-template-columns: repeat(${({ $columnNum }) => $columnNum}, 1fr);
	gap: 12px;
	margin: 12px 0px 0px;
`;

export const Image = styled.div`
	height: 120px;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: cover;
	border-radius: 6px;
`;

export const ReviewsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin: 12px 0px 0px;
`;

export const ReviewCard = styled.div`

	p:first-of-type {
		margin: 5px 0px 2px;
	}
	* {
		text-align: left;
	}
`;
