/*
 * File: InterestsStep.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 9, 2023 at 9:09 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:20 AM
 * Modified By: Brendan Michaelsen
 */

// Modules
import React, { useEffect, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

// Components
import {
	Typography, Button
} from '../../../../../../components';

// Styles
import * as S from './InterestsStep.styles';


/**
 * Constants
 */

const INTERESTS = [
	{
		id: 'arts',
		title: 'Arts',
		icon: ['fal', 'theater-masks']
	},
	{
		id: 'food',
		title: 'Food',
		icon: ['fal', 'utensils']
	},
	{
		id: 'music',
		title: 'Music',
		icon: ['fal', 'music']
	},
	{
		id: 'parks',
		title: 'Parks',
		icon: ['fal', 'tree']
	},
	{
		id: 'sports',
		title: 'Sports',
		icon: ['fal', 'volleyball-ball']
	},
	{
		id: 'monuments',
		title: 'Monuments',
		icon: ['fal', 'landmark']
	}
];


/**
 * Component
 */

export const InterestsStep = forwardRef(({
	className, updateStep
}, ref) => {

	// Create state handlers
	const [selectedInterests, setSelectedInterests] = useState([]);

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Handle toggle interest
	const toggleInterest = ({ id }) => {
		let interests = [...selectedInterests];
		if (interests.includes(id)) {
			interests = interests.filter((i) => i !== id);
		} else {
			interests.push(id);
		}
		setSelectedInterests(interests);
	};

	// Perform actions on component load
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className ? `${className} scroller` : 'scroller'} id="scroller-interests-step">

			{/* Logo Container */}
			<S.LogoContainer>
				<S.Logo
					alt={`${currentCityApp?.municipalityName} Logo`}
					title={`${currentCityApp?.municipalityName} Logo`}
					src={currentCityApp?.logoImage}
				/>
			</S.LogoContainer>

			{/* Progress Bar */}
			<S.ProgressBarContainer>
				<S.ContentPadding left right>
					<S.ProgressBar>
						<S.ProgressLeft />
						<S.ProgressRight />
					</S.ProgressBar>
				</S.ContentPadding>
			</S.ProgressBarContainer>

			{/* Content Container */}
			<S.ContentContainer>
				<S.ContentPadding left right>
					<S.Content>

						{/* Title */}
						<Typography tag="h2" weight="bold">What interests you?</Typography>

						{/* Interests Grid */}
						<S.InterestsGrid>
							{INTERESTS.map((interest) => (
								<S.Interest className="animate" key={interest.id} $isActive={selectedInterests.includes(interest.id)} onClick={() => { toggleInterest(interest); }}>
									<FontAwesomeIcon icon={interest.icon} />
									<Typography weight="semibold">{interest.title}</Typography>
								</S.Interest>
							))}
						</S.InterestsGrid>

					</S.Content>
				</S.ContentPadding>
			</S.ContentContainer>

			{/* Bottom Container */}
			<S.BottomActionContainer>
				<S.ContentPadding left right>
					<Button
						variant="text"
						size="large"
						onClick={() => { updateStep(3); }}
					>
						<Typography tag="h4" weight="semibold">Next</Typography>
						<FontAwesomeIcon icon={['fal', 'arrow-right']} />
					</Button>
				</S.ContentPadding>
			</S.BottomActionContainer>

		</S.StepContainer>
	);
});


/**
 * Configuration
 */

InterestsStep.displayName = 'InterestsStep';
InterestsStep.propTypes = {
	className: PropTypes.string,
	updateStep: PropTypes.func
};
InterestsStep.defaultProps = {
	className: null,
	updateStep: null
};
