/*
 * File: Card.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: November 6, 2023 at 10:43 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './Card.styles';


/**
 * Component
 */

export const Card = ({ className, children }) => (
	<S.Card className={className}>
		{children}
	</S.Card>
);


/**
 * Configuration
 */

Card.displayName = 'Card';
Card.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
Card.defaultProps = {
	className: null,
	children: null
};
