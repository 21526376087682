/*
 * File: AppNavigation.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:29 PM
 * Copyright © 2022 Lithios LLC. All rights reserved.
 *
 * Last Modified: November 9, 2023 at 10:49 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { createContext } from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './AppNavigation.styles';


/**
 * Context
 */

export const AppNavigationContext = createContext();


/**
 * Component
 */

export const AppNavigation = ({
	children
}) => (
	<S.Wrapper>{children}</S.Wrapper>
);


/**
 * Configuration
 */

AppNavigation.displayName = 'AppNavigation';
AppNavigation.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};
AppNavigation.defaultProps = {
	children: null
};
