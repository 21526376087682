/*
 * File: Notifications.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on December 4, 2023 at 11:50 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:21 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Utilities
import { createStateLocale } from '../../../../../utilities/locale';

// Components
import {
	AppNavigation,
	EmptyComponent,
	ErrorComponent,
	IconButton,
	Meta,
	Spinner,
	Typography
} from '../../../../../components';

// Styles
import * as S from './Notifications.styles';


/**
* Component
*/

const Notifications = ({ meta, locale }) => {

	// Create state handlers
	const [pageStatus, setPageStatus] = useState('idle');
	const [notifications, setNotifications] = useState([]);

	// Get hooks
	const navigate = useNavigate();

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Create reference for components
	const isMounted = useRef(true);

	// Handle fetch data for page
	const fetchDataForPage = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Set timeout
			setTimeout(() => {

				// Check if component is mounted
				if (isMounted.current) {

					// Set notifications
					setNotifications([]);

					// Update page status
					setPageStatus('success');
				}

			}, 2000);

		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle actions on page status change
	useEffect(() => {

		// Set page status if necessary
		if (pageStatus === 'idle') {

			// Fetch data state for page
			fetchDataForPage();
		}
	}, [pageStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle render component
	const renderComponent = () => {
		if (pageStatus === 'idle' || pageStatus === 'loading') {
			return <Spinner />;
		}
		if (pageStatus === 'error') {
			return <ErrorComponent />;
		}
		return notifications.length === 0 ? (
			<EmptyComponent
				title="No current notifications"
				message={`Check back to view your unread notifications from ${currentCityApp?.municipalityName || 'MyCity'}`}
			/>
		) : <S.NotificationCollection />;
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{ app: currentCityApp || { municipalityName: 'MyCity' } }} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>

					{/* Navigation Bar */}
					<S.NavigationBar>
						<S.ContentPadding left right>
							<Typography tag="h1" weight="bold">Notifications</Typography>
							<IconButton icon={['fal', 'xmark']} size={1.4} onClick={() => { navigate(-1); }} />
						</S.ContentPadding>
					</S.NavigationBar>

					{/* Notifications Content */}
					<S.NotificationsContent className="scroller" id="scroller-notifications">
						{renderComponent()}
					</S.NotificationsContent>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
* Configuration
*/

Notifications.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Notifications.defaultProps = {
	meta: {},
	locale: {}
};


/**
* Exports
*/

export default Notifications;
