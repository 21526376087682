/*
 * File: ToolTip.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: April 22, 2024 at 2:08 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

// Colors
import { ColorValueCreator } from '../../styles/colors';

// Constants
import { UI_MODE_OPTIONS } from '../../../Constants';


/**
 * Styles
 */

export const ToolTip = styled(ReactTooltip)`
	&.__react_component_tooltip {
		background-color: ${({ uiMode, theme }) => (uiMode === UI_MODE_OPTIONS.LIGHT ? theme.primaryBackground : theme.secondaryBackground)} !important;
		color: ${({ theme }) => (theme.primaryText)} !important;
		box-shadow: ${ColorValueCreator().elementShadow};
		border-radius: 8px;
		padding: 12px 20px;

		&.show {
			opacity: 1;
		}
		&::before,
		&::after {
			background-color: ${({ uiMode, theme }) => (uiMode === UI_MODE_OPTIONS.LIGHT ? theme.primaryBackground : theme.secondaryBackground)} !important;
		}
	}
`;

export const Inner = styled.span`
	font-size: 0.9rem;

	svg {
		margin-right: 6px;
		margin-left: 0px;

		&.success {
			color: ${({ theme }) => theme.statusSuccessBase};
		}
		&.error {
			color: ${({ theme }) => theme.statusDangerBase};
		}
	}
`;
