/*
 * File: ConfirmationStep.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 28, 2024 at 10:15 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, forwardRef, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Utilities
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseFirstLastName } from '../../../../../../utilities/utilities';

// Components
import {
	Typography,
	ConfettiBurst
} from '../../../../../components';

// Constants
import { CITY_APP_ROLES } from '../../../../../../Constants';

// Styles
import * as S from './ConfirmationStep.styles';


/**
 * Component
 */

export const ConfirmationStep = forwardRef(({
	superheroState, isVisible, className
}, ref) => {

	// Create state handlers
	const [fireConfetti, setFireConfetti] = useState(false);

	// Create refs
	const confettiTargetRef = useRef();

	// Use hooks
	const navigate = useNavigate();

	// Handle continue action
	const handleContinue = () => {

		// Move to city app
		navigate(`/superhero/${superheroState.accessId}?role=${CITY_APP_ROLES.COMMANDER}`);
	};

	// Perform actions on visibility change
	useEffect(() => {
		if (isVisible === true) {

			// Fire confetti
			setTimeout(() => {
				setFireConfetti(true);
			}, 500);
		}
	}, [isVisible]);

	// Return component
	return (
		<>
			<S.StepContainer ref={ref} className={className}>

				{/* Logo */}
				<S.LogoContainer>
					<S.Logo src={`${process.env.CDN_URL}/public/assets/lithios-logo.png`} />
				</S.LogoContainer>

				{/* Form */}
				<S.Form>

					{/* Icon */}
					<S.IconContainer>
						<S.Icon>
							<FontAwesomeIcon icon={['fas', 'check']} />
							<S.ConfettiTarget ref={confettiTargetRef} />
						</S.Icon>
					</S.IconContainer>

					{/* Content */}
					<Typography tag="h2" weight="bold">
						<span>{superheroState.superheroName}</span>
						{' '}
						is powered up and ready to save the city
						{' '}
						<span>{parseFirstLastName(superheroState.creatorName)?.firstName || 'Brendan'}</span>
						!
					</Typography>

					{/* Continue Action */}
					<S.ActionButton onClick={handleContinue} size="large" className="floatShadow">See my hero in action!</S.ActionButton>

				</S.Form>
			</S.StepContainer>

			{/* Confetti */}
			{fireConfetti && (
				<ConfettiBurst
					fireAway={fireConfetti}
					targetRef={confettiTargetRef}
					force={0.4}
					duration={3000}
					particleCount={80}
				/>
			)}
		</>
	);
});


/**
 * Configuration
 */

ConfirmationStep.displayName = 'ConfirmationStep';
ConfirmationStep.propTypes = {
	className: PropTypes.string,
	superheroState: PropTypes.shape(),
	isVisible: PropTypes.bool
};
ConfirmationStep.defaultProps = {
	className: null,
	superheroState: {},
	isVisible: false
};
