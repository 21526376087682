/*
 * File: ChatWidget.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: April 25, 2024 at 8:55 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useWindowResize } from 'beautiful-react-hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Components
import { ToolTip } from '../ToolTip';

// Styles
import * as S from './ChatWidget.styles';


/**
 * Constants
 */

const TOOLTIP_WIDTH = 220;


/**
 * Component
 */

export const ChatWidget = ({
	className
}) => {

	// Set state
	const [shouldShowRewards] = useState(true);
	const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

	// Get current locale from hook
	const locale = useSelector((state) => state.locale.value);

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Get actions from hooks
	const navigate = useNavigate();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Create references for elements
	const widgetRef = useRef();

	// Handle actions on component load
	useEffect(() => {
		setTimeout(() => {

			// Show tooltip
			ReactTooltip.show(widgetRef?.current);

			// Update position
			if (widgetRef?.current) {
				setTooltipPosition({ x: widgetRef.current.getBoundingClientRect().x - TOOLTIP_WIDTH - 5, y: window.innerHeight - 112 });
			}
		}, 10);
	}, []);

	// Handle actions on window resize
	useWindowResize(() => {

		// Update position
		if (widgetRef?.current) {
			setTooltipPosition({ x: widgetRef.current.getBoundingClientRect().x - TOOLTIP_WIDTH - 5, y: window.innerHeight - 112 });
		}
	});

	// Render component
	return (
		<S.Wrapper className={className} $position={tooltipPosition} $width={TOOLTIP_WIDTH}>
			{shouldShowRewards && (
				<S.ChatWidget
					ref={widgetRef}
					data-tip="Need help? Let us help you!"
					data-for="chat-widget-tooltip"
					onClick={() => { navigate(`${locale.localePath}/app/${currentCityApp?.accessId}/chat${searchParams.toString() ? `?${searchParams.toString()}` : ''}`); }}
				>
					<FontAwesomeIcon icon={['fas', 'comment']} />
				</S.ChatWidget>
			)}
			<ToolTip
				id="chat-widget-tooltip"
				className="chatWidgetTooltip"
				place="left,top"
				delayShow={2000}
				globalEventOff="click"
			/>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

ChatWidget.displayName = 'ChatWidget';
ChatWidget.propTypes = {
	className: PropTypes.string
};
ChatWidget.defaultProps = {
	className: null,
};
