/*
 * File: Padding.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 9, 2022 at 5:45 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: November 6, 2023 at 10:43 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './Padding.styles';


/**
 * Component
 */

export const Padding = forwardRef(({
	left, right, dynamic, negative, isLarge, className, children
}, ref) => (negative
	? <S.NegativePadding ref={ref} className={className} left={left} right={right}>{children}</S.NegativePadding>
	: <S.Padding ref={ref} className={className} isLarge={isLarge} left={left} right={right} dynamic={dynamic}>{children}</S.Padding>));


/**
 * Configuration
 */

Padding.displayName = 'Padding';
Padding.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	className: PropTypes.string,
	left: PropTypes.bool,
	right: PropTypes.bool,
	dynamic: PropTypes.bool,
	isLarge: PropTypes.bool,
	negative: PropTypes.bool
};
Padding.defaultProps = {
	children: null,
	className: null,
	left: false,
	right: false,
	dynamic: false,
	isLarge: false,
	negative: false
};
