/*
 * File: EventDetail.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on December 4, 2023 at 11:50 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:22 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { Padding } from '../../../../../components';

// Styles
import { ColorValueCreator } from '../../../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.errorComponent {
		height: 100%;
		display: flex;
		align-items: center;
	}
`;

export const NavigationBar = styled.div`
	height: 58px;
	color: ${ColorValueCreator().white};
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: ${ColorValueCreator().navigationShadow};
	position: relative;
	z-index: 99;

	h1 {
		color: ${({ theme }) => theme.brandPrimaryBase};
		flex-grow: 1;
		text-align: left;
	}
	svg {
		color: ${({ theme }) => theme.brandPrimaryBase};
		margin-right: 10px;
	}
	& > div {
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
	align-items: start;
`;

export const EventContent = styled.div`
	width: 100%;
	height: 100%;
	flex-grow: 1;
	overflow: scroll;

	& > div {
		height: 100%;
		width: 100%;
	}
`;

export const EventImage = styled.div`
	width: 100%;
	height: 160px;
	flex-shrink: 0;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: cover;
	border-radius: 6px;
	overflow: hidden;
	margin: 14px 0px 0px;
`;

export const EventDetailSection = styled.div`
	padding: 20px 0px;
	width: 100%;

	${({ $showBorder }) => $showBorder && css`
		border-bottom: 1px solid ${({ theme }) => theme.layoutBorder};
	`}
`;

export const EventDetailTitle = styled.div`
	margin: 0px 0px 0px;

	p {
		color: ${({ theme }) => theme.secondaryText};
		text-align: left;
		margin: 0px 0px 4px;
	}
`;

export const TitleRow = styled.div`
	display: flex;
	gap: 6px;

	h4 {
		flex-grow: 1;
		text-align: left;
		color: ${ColorValueCreator().dark} !important;
	}
	button {
		margin-top: 4px;
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;

export const Details = styled.div`
	margin: 6px 0px 0px;
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

export const DetailRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	gap: 8px;

	* {
		text-align: left;
	}
	svg {
		color: ${({ theme }) => theme.brandPrimaryBase};
		font-size: 0.9rem;
		width: 14px;
	}
`;

export const TagSection = styled.div`
	margin: 15px 0px 0px;

	& > p {
		color: ${({ theme }) => theme.secondaryText};
		text-align: left;
	}
`;

export const TagContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 4px;
	margin: 5px 0px;
`;

export const Tag = styled.div`
	height: 22px;
	display: flex;
	align-items: center;
	gap: 5px;
	background-color: ${({ $colorBase }) => ColorValueCreator()[`${$colorBase}Lighter`]};
	color: ${({ $colorBase }) => ColorValueCreator()[`${$colorBase}Base`]};
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 11px;

	svg {
		font-size: 0.9rem;
	}
`;

export const Description = styled.div`
	margin: 6px 0px 0px;

	* {
		text-align: left;
	}
`;

export const MapContainer = styled.div`
	width: 100%;
	height: 200px;
	position: relative;
	margin: 15px 0px 0px;

	& > div {
		border-radius: 6px;
	}
`;

export const Footer = styled.div`
	width: 100%;
	height: 40px;
`;
