/*
 * File: SetupStep.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:23 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import { ColorValueCreator } from '../../../../../styles/colors';

// Components
import { Button } from '../../../../../components/Button';

// Constants
import { mobileBreakpoint, smallBreakpoint } from '../../../../../styles/constants';

// Create color values
const ColorValues = ColorValueCreator();


/**
 * Styles
 */

export const StepContainer = styled.div`
	width: 100%;
`;

export const Form = styled.form`
	width: 100%;
	max-width: 480px;
	margin: 0px auto;

	@media only screen {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	@media only screen and (min-width: ${smallBreakpoint}em) {
		padding-top: 100px;
		padding-bottom: 50px;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding-top: 120px;
		padding-bottom: 50px;
	}
	h2 {
		text-align: left;
		margin: 0px 0px 50px;

		span {
			text-decoration: underline;
			text-decoration-color: ${({ theme }) => theme.brandSecondaryBase};
		}
	}
	p {
		text-align: left;
   		margin-bottom: 20px;
	}
	button {
		width: 100%;
	}
`;

export const LogoContainer = styled.div`
	margin: 20px 0px 0px;
	text-align: center;
`;

export const Logo = styled.img`
	height: 40px;
`;

export const ActionButton = styled(Button)`
	margin: 40px auto 0px;
`;

export const SetupRow = styled.div`
	display: flex;
	align-items: center;
	margin-top: 20px;
	padding: 5px 20px 10px 0px;
	pointer-events: none;

	&.first {
		margin-top: 30px;
	}
	&.animate {
		transition: all 1s;
		-webkit-transition: all 1s;
		-moz-transition: all 1s;
		-o-transition: all 1s;
	}
`;

export const ProgressHolder = styled.div`
	width: 45px;
	height: 45px;
	flex-shrink: 0;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	&.complete {
		border: 2px solid ${ColorValues.brandSecondaryBase};
		background-color: ${ColorValues.brandSecondaryBase};
		box-shadow: ${ColorValues.elementShadow};
	}
`;

export const ContentHolder = styled.div`
	p {
		flex: 1;
		margin: 0px 0px 0px 18px;
		position: relative;
		pointer-events: none;
	}
`;

export const CompleteIcon = styled(FontAwesomeIcon)`
	width: 24px;
	height: 24px;
	opacity: 0;
	visibility: hidden;
	transform: scale(1.35);
	transition: visibility 0s linear 0.3s, opacity 0.3s 0s, transform 0.3s;
	position: absolute;
	color: ${ColorValues.white};

	&.show {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
		transition: visibility 0s linear 0s, opacity 0.3s 0s, transform 0.3s;
	}
`;

export const AnimationContainer = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
`;

export const PendingState = styled.div`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	position: absolute;
	background-color: ${ColorValues.brandSecondaryBase};
`;
