/*
 * File: MoreTab.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 6, 2023 at 11:59 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:21 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { Padding } from '../../../../../../components';

// Styles
import { ColorValueCreator } from '../../../../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const RowsContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	a,
	a:link,
	a:visited {
		text-decoration: none;
	}
`;

export const OptionRow = styled.div`
	display: flex;
	height: 58px;

	${({ $topBorder }) => ($topBorder ? css`
		border-top: 1px solid ${ColorValueCreator().neutralForm};
	` : css`
		border-bottom: 1px solid ${ColorValueCreator().neutralForm};
	`)};
	h5 {
		text-align: left;
		flex-grow: 1;
	}
	svg {
		font-size: 1.1rem;
	}
`;

export const ContentPadding = styled(Padding)`
	flex-direction: row;
	align-items: center;
`;

export const Spacer = styled.div`
	flex-grow: 1;
	width: 100%;
`;
