/*
 * File: BuildFlow.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:22 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Padding } from '../../../../components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
`;

export const StepContainer = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	display: flex;
	flex-grow: 1;
`;

export const Step = styled.div`
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	position: absolute;
	top: 0px;
	pointer-events: none;
	width: 100%;
	flex-grow: 1;
	display: flex;
	height: 100%;

	&.show {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.3s;
		position: relative;
		pointer-events: initial;
	}
`;

export const Decoration = styled.div`
	width: 100%;
	position: absolute;
	left: 0px;
	bottom: 0px;
	z-index: 1;

	svg {
		max-height: 14vh;
		width: 100%;
		height: auto;
		position: relative;
		bottom: -10px;
	}
	path {
		fill: ${({ $color }) => $color};
	}
`;

export const StepPadding = styled(Padding)`
	position: relative;
	z-index: 3;
`;
