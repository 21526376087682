/*
 * File: CityAppRoutes.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on April 22, 2024 at 11:07 AM
 * Copyright © 2024 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:19 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';

// Utilities
import { filterProps, prepareComponent } from '../../../utilities/routes';
import { createLocaleVariants } from '../../../utilities/locale';

// Slice
import { initialState as initialLocale } from '../../../store/slices/locale/locale.slice';

// Navigation
import { CityAppRouteWrapper } from '../../../navigation';

// Pages
import {
	Login,
	Onboarding,
	Home,
	CityAppError,
	Notifications,
	EventDetail,
	Chat
} from '../pages';


/**
 * Routes
 */

export const CityAppRoutes = (props) => {

	// Get current meta from props
	const metaObj = props?.meta;

	// Get current locale from props
	const localeObj = props?.locale?.localePath != null ? props?.locale : initialLocale.value;

	// Render routes
	return [].concat(...[

		/**
		 * Entry
		 */

		prepareComponent(createLocaleVariants({
			path: '/app/:accessId/login',
			exact: true,
			shouldIndex: true,
			priority: '1.00',
			functions: [],
			component(path) {
				return (
					<CityAppRouteWrapper locale={localeObj}>
						<Login
							{...filterProps(path, props)}
							meta={{
								title: 'Log In | {{app.municipalityName}}',
								description: '',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</CityAppRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/app/:accessId/onboarding',
			exact: true,
			shouldIndex: true,
			priority: '1.00',
			functions: [],
			component(path) {
				return (
					<CityAppRouteWrapper locale={localeObj}>
						<Onboarding
							{...filterProps(path, props)}
							meta={{
								title: 'Onboarding | {{app.municipalityName}}',
								description: '',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</CityAppRouteWrapper>
				);
			}
		})),


		/**
		 * General
		 */

		prepareComponent(createLocaleVariants({
			path: '/app/:accessId/notifications',
			exact: true,
			shouldIndex: true,
			priority: '1.00',
			functions: [],
			component(path) {
				return (
					<CityAppRouteWrapper locale={localeObj}>
						<Notifications
							{...filterProps(path, props)}
							meta={{
								title: 'Notifications | {{app.municipalityName}}',
								description: '',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</CityAppRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/app/:accessId/chat',
			exact: true,
			shouldIndex: true,
			priority: '1.00',
			functions: [],
			component(path) {
				return (
					<CityAppRouteWrapper locale={localeObj}>
						<Chat
							{...filterProps(path, props)}
							meta={{
								title: 'Chat | {{app.municipalityName}}',
								description: '',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</CityAppRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/app/:accessId/event/:slug',
			exact: true,
			shouldIndex: true,
			priority: '1.00',
			functions: [],
			component(path) {
				return (
					<CityAppRouteWrapper locale={localeObj}>
						<EventDetail
							{...filterProps(path, props)}
							meta={{
								title: '{{event.title}} | {{app.municipalityName}} Event',
								description: '',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</CityAppRouteWrapper>
				);
			}
		})),


		/**
		 * Core
		 */

		prepareComponent(createLocaleVariants({
			path: '/app/:accessId',
			exact: true,
			shouldIndex: true,
			priority: '1.00',
			functions: [],
			component(path) {
				return (
					<CityAppRouteWrapper locale={localeObj}>
						<Home
							{...filterProps(path, props)}
							meta={{
								title: '{{app.municipalityName}}',
								description: '',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</CityAppRouteWrapper>
				);
			}
		})),


		/**
		 * Error
		 */

		prepareComponent({
			path: '/app/:accessId/*',
			shouldIndex: false,
			isError: true,
			code: 404,
			component(path) {
				return (
					<CityAppError
						code={404}
						{...filterProps(path, props)}
						locale={localeObj}
						meta={{
							title: 'Page Not Found | {{app.municipalityName}}',
							description: 'Whoops! We can\'t seem to find the page you\'re looking for.',
							bodyClasses: 'hide-captcha',
							showSocial: false,
							shouldIndex: false,
							...metaObj
						}}
					/>
				);
			}
		})

	].filter(Boolean));
};
