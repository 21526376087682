/*
 * File: UtilitiesScreen.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 6, 2023 at 11:59 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:21 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

// Components
import {
	Button, IconButton, Typography
} from '../../../../../../../components';

// Styles
import * as S from './UtilitiesScreen.styles';


/**
 * Component
 */

const UtilitiesScreen = ({ hideScreen }) => {

	// Render component function
	const renderComponent = () => (
		<S.ContentPadding left right style={{ marginBottom: '30px' }}>

			{/* Title */}
			<S.TitleContainer>
				<IconButton
					icon={['fal', 'arrow-left']}
					size={1.4}
					onClick={() => { hideScreen(); }}
				/>
				<Typography tag="h2" weight="semibold">Utilities</Typography>
			</S.TitleContainer>

			{/* Utilities Card */}
			<S.UtilitiesCard>
				<S.ContentPadding left right>

					{/* Title Section */}
					<S.TitleSection>
						<Typography tag="h4" weight="semibold">Current Invoices</Typography>
					</S.TitleSection>

					{/* Water Section */}
					<S.UtilitiesSection>
						<Typography tag="h5" weight="semibold">Water</Typography>
						<S.DetailGrid>
							<S.Detail>
								<Typography tag="p" variation="3" weight="regular">Amount Due</Typography>
								<Typography tag="h3" weight="semibold">$114.20</Typography>
							</S.Detail>
							<S.Detail>
								<Typography tag="p" variation="3" weight="regular">Bill Date</Typography>
								<Typography tag="h3" weight="semibold">2/20/22</Typography>
							</S.Detail>
							<S.Detail>
								<Typography tag="p" variation="3" weight="regular">Payment Due Date</Typography>
								<Typography tag="h3" weight="semibold">2/28/22</Typography>
							</S.Detail>
						</S.DetailGrid>
					</S.UtilitiesSection>

					{/* Trash Pickup Section */}
					<S.UtilitiesSection>
						<Typography tag="h5" weight="semibold">Trash Pickup</Typography>
						<S.DetailGrid>
							<S.Detail>
								<Typography tag="p" variation="3" weight="regular">Amount Due</Typography>
								<Typography tag="h3" weight="semibold">$53.10</Typography>
							</S.Detail>
							<S.Detail>
								<Typography tag="p" variation="3" weight="regular">Bill Date</Typography>
								<Typography tag="h3" weight="semibold">2/20/22</Typography>
							</S.Detail>
							<S.Detail>
								<Typography tag="p" variation="3" weight="regular">Payment Due Date</Typography>
								<Typography tag="h3" weight="semibold">2/28/22</Typography>
							</S.Detail>
						</S.DetailGrid>
					</S.UtilitiesSection>

					{/* Pay Button */}
					<S.ButtonLink to="https://ubwss.raleighnc.gov/wss/login" target="_blank">
						<Button>
							<Typography weight="semibold">Pay Now</Typography>
							<FontAwesomeIcon icon={['fal', 'arrow-up-right-from-square']} />
						</Button>
					</S.ButtonLink>

				</S.ContentPadding>
			</S.UtilitiesCard>
		</S.ContentPadding>
	);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.Wrapper>{renderComponent()}</S.Wrapper>
	);
};


/**
 * Configuration
 */

UtilitiesScreen.propTypes = {
	hideScreen: PropTypes.func
};
UtilitiesScreen.defaultProps = {
	hideScreen: null
};


/**
 * Exports
 */

export default UtilitiesScreen;
