/*
 * File: ConfirmationStep.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 9, 2023 at 9:09 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:20 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Padding } from '../../../../../../components';

// Styles
import { ColorValueCreator } from '../../../../../../styles/colors';


/**
 * Styles
 */

export const StepContainer = styled.div`
	background-color: ${ColorValueCreator().neutralBackground};
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	width: 100%;
	overflow: scroll;
`;

export const LogoContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
`;

export const Logo = styled.img`
	width: 86px;
`;

export const ContentContainer = styled.div`
	flex-grow: 1;
	position: relative;
	z-index: 2;
	align-items: start;
	justify-content: center;
	display: flex;
`;

export const Content = styled.div`
	margin-top: 80px;
	width: 100%;
	position: relative;

	* {
		text-align: left;
	}
	svg {
		font-size: 4.5rem;
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
	h1 {
		margin: 30px 0px 12px;
	}
	button {
		width: 100%;
		margin: 35px 0px 0px;
	}
`;

export const ConfettiTarget = styled.div`
	position: absolute;
	top: 20px;
	left: 20px;
	z-index: 2;
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
	align-items: start;
`;

export const Decoration = styled.img`
	width: 80%;
	position: absolute;
	left: 0px;
	bottom: 0px;
	z-index: 1;
`;
