/*
 * File: EventDetail.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on December 4, 2023 at 11:50 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:22 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { createStateLocale } from '../../../../../utilities/locale';

// Components
import {
	AppNavigation,
	ErrorComponent,
	IconButton,
	Map,
	Meta,
	Typography
} from '../../../../../components';

// Constants
import { SAMPLE_EVENTS } from '../../../../../../Constants';

// Styles
import * as S from './EventDetail.styles';


/**
* Component
*/

const EventDetail = ({ meta, locale }) => {

	// Get component parameters
	const { slug } = useParams();

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Create state handlers
	const [event] = useState(SAMPLE_EVENTS(currentCityApp).find((eventObj) => eventObj.slug === slug));
	const [isFavorited, setIsFavorited] = useState(false);

	// Get actions from hooks
	const navigate = useNavigate();

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle component initialization
	useEffect(() => {}, []);

	// Handle render component
	const renderComponent = () => (
		event ? (
			<>

				{/* Image */}
				<S.EventImage $image={event.image} />

				{/* Overview */}
				<S.EventDetailSection $showBorder>

					{/* Section Title */}
					<S.EventDetailTitle>
						<Typography tag="p" variation="3" weight="regular">Event Overview</Typography>
					</S.EventDetailTitle>

					{/* Content */}
					<S.TitleRow>
						<Typography tag="h4" weight="bold">{event.title}</Typography>
						<IconButton
							icon={isFavorited ? ['fas', 'bookmark'] : ['fal', 'bookmark']}
							size={1.1}
							onClick={() => { setIsFavorited(!isFavorited); }}
						/>
					</S.TitleRow>

					{/* Details */}
					<S.Details>
						{event.date && (
							<S.DetailRow>
								<FontAwesomeIcon icon={['fal', 'calendar']} />
								<Typography tag="p" variation="2" weight="light">{event.date}</Typography>
							</S.DetailRow>
						)}
						{event.time && (
							<S.DetailRow>
								<FontAwesomeIcon icon={['fal', 'clock']} />
								<Typography tag="p" variation="2" weight="light">{event.time}</Typography>
							</S.DetailRow>
						)}
						{event.location && (
							<S.DetailRow>
								<FontAwesomeIcon icon={['fal', 'location-dot']} />
								<Typography tag="p" variation="2" weight="light">{event.location}</Typography>
							</S.DetailRow>
						)}
						{event.price && (
							<S.DetailRow>
								<FontAwesomeIcon icon={['fal', 'dollar-sign']} />
								<Typography tag="p" variation="2" weight="light">{event.price}</Typography>
							</S.DetailRow>
						)}
					</S.Details>

					{/* Tags */}
					<S.TagSection>
						<Typography tag="p" variation="3" weight="regular">Tags</Typography>
						<S.TagContainer>
							{event.tags.map((tag) => (
								<S.Tag $colorBase={tag.colorBase} key={tag.title}>
									<FontAwesomeIcon icon={tag.icon} />
									<Typography tag="p" variation="3" weight="light">{tag.title}</Typography>
								</S.Tag>
							))}
						</S.TagContainer>
					</S.TagSection>
				</S.EventDetailSection>

				{/* Details */}
				<S.EventDetailSection>

					{/* Section Title */}
					<S.EventDetailTitle>
						<Typography tag="p" variation="3" weight="regular">Event Details</Typography>
					</S.EventDetailTitle>

					{/* Content */}
					<S.TitleRow>
						<Typography tag="h4" weight="bold">About</Typography>
					</S.TitleRow>

					{/* Description */}
					<S.Description>
						<Typography tag="p" weight="light" variation="2">
							{event.description}
						</Typography>
					</S.Description>

					{/* Map */}
					{event?.coordinates && (
						<S.MapContainer>
							<Map
								zoom={13}
								isPreview={false}
								locationId="locator"
								center={currentCityApp?.centerCoordinates}
								flyTo={event ? { latitude: event.coordinates.latitude, longitude: event.coordinates.longitude } : null}
								pointsOfInterest={[
									{
										id: event.id,
										coordinates: event.coordinates,
										type: 'featured',
										index: 0
									}
								]}
							/>
						</S.MapContainer>
					)}

					{/* Footer */}
					<S.Footer />

				</S.EventDetailSection>
			</>
		) : <ErrorComponent className="errorComponent" />
	);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{ event, app: currentCityApp || { municipalityName: 'MyCity' } }} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>

					{/* Navigation Bar */}
					<S.NavigationBar>
						<S.ContentPadding left right>
							<IconButton icon={['fal', 'arrow-left']} size={1.4} onClick={() => { navigate(-1); }} />
							<Typography tag="h1" weight="bold">Events</Typography>
						</S.ContentPadding>
					</S.NavigationBar>

					{/* Event Content */}
					<S.EventContent className="scroller" id="scroller-eventdetail">
						<S.ContentPadding left right>
							{renderComponent()}
						</S.ContentPadding>
					</S.EventContent>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
* Configuration
*/

EventDetail.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
EventDetail.defaultProps = {
	meta: {},
	locale: {}
};


/**
* Exports
*/

export default EventDetail;
