/*
 * File: index.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on April 21, 2024 at 10:26 PM
 * Copyright © 2024 Lithios, LLC. All rights reserved.
 *
 * Last Modified: April 23, 2024 at 12:43 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const getCityApp = ({ accessId }) => api.get(`/cityapp/${accessId}`);

export const postCityAppCommand = ({ cityAppId, command, data }) => api.post(`/cityapp/${cityAppId}/command`, { command, data });
