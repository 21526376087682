/*
 * File: Typography.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on January 27, 2022 at 10:52 AM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: April 23, 2024 at 12:13 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Constants
import { mediumBreakpoint, largeBreakpoint } from '../../styles/constants';


/**
 * Constants
 */

export const WEIGHTS = {
	extralight: 200,
	light: 300,
	regular: 400,
	medium: 500,
	semibold: 600,
	bold: 700,
	extrabold: 800,
	black: 900
};

export const SIZES = {
	h1: {
		small: '1.7rem', // 48px
		medium: '2.0rem', // 48px
		large: '2.0rem', // 48px
	},
	h2: {
		small: '1.625rem', // 34px
		medium: '1.875rem', // 34px
		large: '2.125rem', // 34px
	},
	h3: {
		small: '1.45rem', // 28px
		medium: '1.6rem', // 28px
		large: '1.75rem', // 28px
	},
	h4: {
		small: '1.175rem', // 22px
		medium: '1.275rem', // 22px
		large: '1.375rem', // 22px
	},
	h5: {
		small: '1rem', // 16px
		medium: '1rem', // 16px
		large: '1rem', // 16px
	},
	h6: {
		small: '0.75rem', // 12px
		medium: '0.75rem', // 12px
		large: '0.75rem', // 12px
	},
	p1: {
		small: '1rem', // 16px
		medium: '1rem', // 16px
		large: '1rem', // 16px
	},
	p2: {
		small: '0.875rem', // 12px
		medium: '0.875rem', // 12px
		large: '0.875rem', // 12px
	},
	p3: {
		small: '0.6875rem', // 11px
		medium: '0.6875rem', // 11px
		large: '0.6875rem', // 11px
	},
	span1: {
		small: '1rem', // 16px
		medium: '1rem', // 16px
		large: '1rem', // 16px
	},
	span2: {
		small: '0.75rem', // 12px
		medium: '0.75rem', // 12px
		large: '0.75rem', // 12px
	},
	span3: {
		small: '0.6875rem', // 11px
		medium: '0.6875rem', // 11px
		large: '0.6875rem', // 11px
	},
	'spanbutton-medium': {
		small: '0.875rem', // 14px
		medium: '0.875rem', // 14px
		large: '0.875rem', // 14px
	},
	'spanbutton-small': {
		small: '0.75rem', // 12px
		medium: '0.75rem', // 12px
		large: '0.75rem', // 12px
	},
	label: {
		small: '0.75rem', // 12px
		medium: '0.75rem', // 12px
		large: '0.75rem', // 12px
	},
};

export const LINE_HEIGHTS = {
	h1: 'normal',
	h2: 'normal',
	h3: '160%',
	h4: '140%',
	h5: '140%',
	h6: '160%',
	p1: '160%',
	p2: '160%',
	p3: '160%',
	span1: 'normal',
	span2: 'normal',
	span3: 'normal',
	'spanbutton-medium': 'normal',
	'spanbutton-small': 'normal',
	label: 'normal',
};


/**
 * Styles
 */

export const Typography = styled.span`
 	font-weight: ${({ weight }) => WEIGHTS[weight]};
 	line-height: ${({ as, variation }) => (as === 'span' || as === 'p' ? LINE_HEIGHTS[as + variation] : LINE_HEIGHTS[as])};
 	margin-bottom: ${({ as }) => (as === 'label' ? '0.375em' : '0')};
 	text-align: ${({ center }) => (center ? 'center' : 'inherit')};
	font-size: ${({ as, variation }) => (as === 'span' || as === 'p' ? SIZES[as + variation].small : SIZES[as].small)};
	font-style: ${({ fontStyle }) => fontStyle};

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		font-size: ${({ as, variation }) => (as === 'span' || as === 'p' ? SIZES[as + variation].medium : SIZES[as].medium)};
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		font-size: ${({ as, variation }) => (as === 'span' || as === 'p' ? SIZES[as + variation].large : SIZES[as].large)};
	}

 	${({ truncate }) => truncate && css`
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	`}
`;
