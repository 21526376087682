/*
 * File: index.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on June 11, 2024 at 11:43 AM
 * Copyright © 2024 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 14, 2024 at 10:57 AM
 * Modified By: Brendan Michaelsen
 */

// Polyfill IE
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Modules
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { HelmetProvider } from 'react-helmet-async';

// Styles
import { config } from '@fortawesome/fontawesome-svg-core';
import { loadIcons } from '../styles/fontawesome';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'mapbox-gl/dist/mapbox-gl.css';

// Stores
import store from '../store';

// App
import MyCityApp from './mycity/App';
import SuperheroApp from './superhero/App';

// Configuration
config.autoAddCss = false;


/**
 * Constants
 */

const isDevelopment = process.env.ENV === 'development';
const isHot = import.meta.webpackHot != null;


/**
 * State
 */

const currentMode = document.getElementById('data-app-mode').innerHTML ? document.getElementById('data-app-mode').innerHTML.trim() : 'mycity'; // mycity or superhero


/**
 * Accept Hot Reload
 */

if (isHot && isDevelopment) {
	import.meta.webpackHot.accept();
}


/**
 * Load Icons
 */

loadIcons();


/**
 * Render App
 */

// Determine render function
const isMarkupPresent = document.getElementById('root').hasChildNodes();
const renderFunction = isMarkupPresent ? ReactDOM.hydrate : ReactDOM.render;

// Prepare content
renderFunction(
	<React.StrictMode>
		<Provider store={store}>
			<HelmetProvider>
				<BrowserRouter>
					{currentMode === 'mycity' ? <MyCityApp isHot={isHot} /> : <SuperheroApp isHot={isHot} />}
				</BrowserRouter>
				<Toaster />
			</HelmetProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
