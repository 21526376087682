/*
 * File: EventCard.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: April 25, 2024 at 8:57 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchParams } from 'react-router-dom';

// Components
import { Typography } from '../Typography';
import { IconButton } from '../IconButton';

// Styles
import * as S from './EventCard.styles';


/**
 * Component
 */

export const EventCard = ({ className, event, favorited }) => {

	// Create state handlers
	const [isFavorited, setIsFavorited] = useState(favorited);

	// Get current locale from hook
	const locale = useSelector((state) => state.locale.value);

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Render component
	return (
		<S.EventCard className={className} to={`${locale.localePath}/app/${currentCityApp?.accessId}/event/${event.slug}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`}>

			{/* Image */}
			<S.EventImage $image={event.image} />

			{/* Details */}
			<S.EventDetails>

				{/* Title */}
				<S.TitleRow>
					<Typography tag="h4" weight="bold">{event.title}</Typography>
					<IconButton
						icon={isFavorited ? ['fas', 'bookmark'] : ['fal', 'bookmark']}
						size={1.1}
						onClick={() => { setIsFavorited(!isFavorited); }}
					/>
				</S.TitleRow>

				{/* Details */}
				<S.Details>
					{event.date && (
						<S.DetailRow>
							<FontAwesomeIcon icon={['fal', 'calendar']} />
							<Typography tag="p" variation="3" weight="light">{event.date}</Typography>
						</S.DetailRow>
					)}
					{event.time && (
						<S.DetailRow>
							<FontAwesomeIcon icon={['fal', 'clock']} />
							<Typography tag="p" variation="3" weight="light">{event.time}</Typography>
						</S.DetailRow>
					)}
					{event.location && (
						<S.DetailRow>
							<FontAwesomeIcon icon={['fal', 'location-dot']} />
							<Typography tag="p" variation="3" weight="light">{event.location}</Typography>
						</S.DetailRow>
					)}
					{event.price && (
						<S.DetailRow>
							<FontAwesomeIcon icon={['fal', 'dollar-sign']} />
							<Typography tag="p" variation="3" weight="light">{event.price}</Typography>
						</S.DetailRow>
					)}
				</S.Details>

				{/* Tags */}
				<S.TagContainer>
					{event.tags.map((tag) => (
						<S.Tag $colorBase={tag.colorBase} key={tag.title}>
							<FontAwesomeIcon icon={tag.icon} />
							<Typography tag="p" variation="3" weight="light">{tag.title}</Typography>
						</S.Tag>
					))}
				</S.TagContainer>

			</S.EventDetails>
		</S.EventCard>
	);
};


/**
 * Configuration
 */

EventCard.displayName = 'EventCard';
EventCard.propTypes = {
	className: PropTypes.string,
	event: PropTypes.shape(),
	favorited: PropTypes.bool
};
EventCard.defaultProps = {
	className: null,
	event: null,
	favorited: false
};
