/*
 * File: HomeTab.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 6, 2023 at 11:59 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:22 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

// Utilities
import { formatNumberWithCommas, fullStateName, parseFirstLastName } from '../../../../../../../utilities/utilities';

// Components
import { Typography } from '../../../../../../components';

// Styles
import * as S from './HomeTab.styles';


/**
 * Constants
 */

const NEWS_FEED_STORIES = (cityApp) => [
	{
		image: `${process.env.CDN_URL}/public/assets/road-sign.png`,
		tag: 'Alerts',
		title: 'Road Construction on Main Street',
		date: '8/2/24',
		url: 'https://transportation.ncsu.edu/2023/09/upcoming-road-work-on-avent-ferry-rd-western-blvd-october-1-5/',
	},
	{
		image: `${process.env.CDN_URL}/public/assets/freedom-park.png`,
		tag: 'Openings',
		title: `${fullStateName(cityApp?.state)} Freedom Park Opens`,
		date: '8/25/24',
		url: 'https://www.visitraleigh.com/plan-a-trip/visitraleigh-insider-blog/post/downtown-raleigh-north-carolina-freedom-park/'
	},
	{
		image: `${process.env.CDN_URL}/public/assets/spiders.png`,
		tag: 'Openings',
		title: `SPIDERS Comes to ${cityApp?.state} Museum of Natural Sciences`,
		date: '6/23/24',
		url: 'https://naturalsciences.org/exhibits/special-exhibitions/spiders'
	}
];


/**
 * Component
 */

const HomeTab = () => {

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Render component function
	const renderComponent = () => (
		<>
			{/* Welcome Banner */}
			<S.WelcomeBanner $image={`${process.env.CDN_URL}/public/assets/building-decoration-transparency.png`}>
				<S.ContentPadding left right>
					<Typography tag="h1" weight="bold">
						Welcome back,
						{' '}
						{parseFirstLastName(currentCityApp?.representativeName).firstName}
					</Typography>
				</S.ContentPadding>
			</S.WelcomeBanner>

			{/* Resident Card */}
			<S.HomeSection>
				<S.ContentPadding left right>
					<S.ResidentCard>
						<Typography tag="h2" weight="bold">{formatNumberWithCommas(currentCityApp?.numberOfResidents || 0)}</Typography>
						<Typography tag="p" variation="2">Total city residents</Typography>
					</S.ResidentCard>
				</S.ContentPadding>
			</S.HomeSection>

			{/* Announcements */}
			<S.HomeSection $secondaryBackground>
				<S.ContentPadding left right>
					<S.SectionTitle>
						<Typography tag="h4" weight="semibold">Announcements</Typography>
					</S.SectionTitle>
					<S.AnnouncementSection>
						<S.AnnouncementIcon>
							<FontAwesomeIcon icon={['fal', 'megaphone']} />
						</S.AnnouncementIcon>
						<S.AnnouncementContent>
							<Typography tag="p" variation="3">
								{currentCityApp?.announcement}
								{' '}
								<Typography tag="span" variation="3" weight="semibold">Check website for more details.</Typography>
							</Typography>
						</S.AnnouncementContent>
					</S.AnnouncementSection>
				</S.ContentPadding>
			</S.HomeSection>

			{/* Spotlight */}
			<S.HomeSection>
				<S.ContentPadding left right>
					<S.SectionTitle>
						<Typography tag="h4" weight="semibold">Spotlight</Typography>
					</S.SectionTitle>
				</S.ContentPadding>
				<S.SectionLink to="https://www.ncstatefair.org/" target="_blank">
					<S.SpotlightBanner $image={`${process.env.CDN_URL}/public/assets/nc-state-fair.png`}>
						<S.SpotlightContent>
							<S.ContentPadding left right>
								<Typography tag="h3" weight="semibold">State Fair Open from Oct 12-22</Typography>
								<Typography tag="p" variation="3">10/1/24</Typography>
							</S.ContentPadding>
						</S.SpotlightContent>
						<S.SpotlightOverlay />
					</S.SpotlightBanner>
				</S.SectionLink>
			</S.HomeSection>

			{/* News Feed */}
			<S.HomeSection $secondaryBackground>
				<S.ContentPadding left right>
					<S.SectionTitle>
						<Typography tag="h4" weight="semibold">News Feed</Typography>
					</S.SectionTitle>
					<S.FeedContainer>
						{NEWS_FEED_STORIES(currentCityApp).map((story) => (
							<S.SectionLink to={`${story.url}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`} key={story.url} target="_blank">
								<S.FeedStory key={story.title}>
									<S.StoryImage $image={story.image} />
									<S.StoryContent>
										<Typography tag="span" variation="2" weight="medium">{story.tag}</Typography>
										<Typography tag="h5" weight="semibold">{story.title}</Typography>
										<Typography tag="p" variation="2">{story.date}</Typography>
									</S.StoryContent>
								</S.FeedStory>
							</S.SectionLink>
						))}
					</S.FeedContainer>
				</S.ContentPadding>
			</S.HomeSection>
		</>
	);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.Wrapper>{renderComponent()}</S.Wrapper>
	);
};


/**
 * Configuration
 */

HomeTab.propTypes = {};
HomeTab.defaultProps = {};


/**
 * Exports
 */

export default HomeTab;
