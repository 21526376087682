/*
 * File: ProfileTab.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 6, 2023 at 11:59 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:21 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

// Components
import { Typography, EventCard } from '../../../../../../components';

// Constants
import { SAMPLE_EVENTS } from '../../../../../../../Constants';

// Styles
import * as S from './ProfileTab.styles';


/**
 * Component
 */

const ProfileTab = () => {

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Render component function
	const renderComponent = () => (
		<>

			{/* Banner */}
			<S.Banner $image={`${process.env.CDN_URL}/public/assets/profile-banner.png`} />

			{/* Profile Section */}
			<S.ProfileSection left right>

				{/* Profile Image */}
				<S.ProfileImageContainer>
					<S.ProfileImage
						src={`${process.env.CDN_URL}/public/assets/profile-image.jpg`}
						title="Profile Image"
						alt="Profile Image"
					/>
				</S.ProfileImageContainer>

				{/* Details Section */}
				<S.DetailContainer>
					<Typography tag="h3" weight="semibold">{currentCityApp?.representativeName}</Typography>
					<Typography tag="p" variation="2">
						<FontAwesomeIcon icon={['fal', 'location-dot']} />
						{currentCityApp?.municipalityName}
						,
						{' '}
						{currentCityApp?.state}
					</Typography>
				</S.DetailContainer>
			</S.ProfileSection>

			{/* Events Section */}
			<S.EventsSection>
				<S.ContentPadding left right>

					{/* Title */}
					<Typography tag="h4" weight="semibold" className="upcomingTitle">Your Upcoming Events</Typography>

					{/* Events Container */}
					<S.EventsContainer>
						{[SAMPLE_EVENTS(currentCityApp)[0]].map((event) => <EventCard event={event} favorited key={event.id} />)}
					</S.EventsContainer>

				</S.ContentPadding>
			</S.EventsSection>
		</>
	);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.Wrapper>{renderComponent()}</S.Wrapper>
	);
};


/**
 * Configuration
 */

ProfileTab.propTypes = {};
ProfileTab.defaultProps = {};


/**
 * Exports
 */

export default ProfileTab;
