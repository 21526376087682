/*
 * File: RepresentativeStep.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:23 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import validator from 'validator';

// Utilities
import { toastError } from '../../../../../utilities/toaster';

// Services
import { completeBuildSession } from '../../../../../services/buildsession';

// Components
import {
	Typography,
	TextInput,
} from '../../../../../components';

// Styles
import * as S from './RepresentativeStep.styles';


/**
 * Component
 */

export const RepresentativeStep = forwardRef(({
	updateStep, className, sessionId, updateMunicipalityState
}, ref) => {

	// Create state handlers
	const [representativeNameError, setRepresentativeNameError] = useState(null);
	const [representativeEmailError, setRepresentativeEmailError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle continue function
	const handleContinue = async () => {

		// Get parameters
		const { representativeName, representativeEmail } = inputValues;

		// Validate parameters
		if (!representativeName || validator.isEmpty(representativeName, { ignore_whitespace: true })) {
			setRepresentativeNameError('Please enter your name to continue');
			return;
		}
		if (!representativeEmail || !validator.isEmail(representativeEmail)) {
			setRepresentativeEmailError('Please enter your email address to continue');
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Update build session
		completeBuildSession({ sessionId, representativeName, representativeEmail }).then(({ data }) => {

			// Set loading state
			setIsLoading(false);

			// Update state
			updateMunicipalityState({ representativeName, representativeEmail, accessId: data.accessId });

			// Move to next step
			updateStep(4);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setRepresentativeNameError(null);
			setRepresentativeEmailError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, null, response?.data?.message);
			} else {
				toastError(uiMode, null, 'Whoops. We\'re having trouble creating your Smart City App. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Logo */}
			<S.LogoContainer>
				<S.Logo src={`${process.env.CDN_URL}/public/assets/lithios-logo.png`} />
			</S.LogoContainer>

			{/* Form */}
			<S.Form>

				{/* Content */}
				<Typography tag="h2" weight="bold">
					Enter a few contact details and we&apos;ll
					{' '}
					<span>customize your experience</span>
					.
				</Typography>

				{/* Inputs */}
				<TextInput
					label="Your Name"
					name="representativeName"
					type="text"
					error={representativeNameError}
					placeholder="e.g. John Smith"
					value={inputValues.representativeName || ''}
					containerClassName="formInput"
					onFocus={() => { setRepresentativeNameError(null); }}
					onKeyUp={() => { setRepresentativeNameError(null); }}
					onBlur={() => { setRepresentativeNameError(null); }}
					onChange={handleOnChange}
				/>
				<TextInput
					label="Your Email"
					name="representativeEmail"
					type="text"
					error={representativeEmailError}
					placeholder="e.g. john@mycity.org"
					value={inputValues.representativeEmail || ''}
					containerClassName="formInput"
					autoComplete="email"
					onFocus={() => { setRepresentativeEmailError(null); }}
					onKeyUp={() => { setRepresentativeEmailError(null); }}
					onBlur={() => { setRepresentativeEmailError(null); }}
					onChange={handleOnChange}
				/>

				{/* Continue Action */}
				<S.ActionButton disabled={isLoading} isLoading={isLoading} onClick={handleContinue} size="large" className="floatShadow">Generate My App</S.ActionButton>

			</S.Form>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

RepresentativeStep.displayName = 'RepresentativeStep';
RepresentativeStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string,
	sessionId: PropTypes.string,
	updateMunicipalityState: PropTypes.func
};
RepresentativeStep.defaultProps = {
	className: null,
	sessionId: null,
	updateMunicipalityState: null
};
