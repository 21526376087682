/*
 * File: Login.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:21 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Padding } from '../../../../../components';

// Styles
import { ColorValueCreator } from '../../../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: ${({ theme }) => theme.primaryBackground};
`;

export const LogoContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
`;

export const Logo = styled.img`
	width: 86px;
`;

export const FormContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	flex-grow: 1;
	position: relative;
	z-index: 2;

	button {
		padding: 0px;
		margin: 15px 0px 0px;
	}
	.fullWidthInput {
		width: 100%;
		margin: 25px 0px 0px;
	}
`;

export const BottomActionContainer = styled.div`
	z-index: 2;
	margin: 80px 0px 40px;

	& > div {
		align-items: center;
	}
	button {
		width: 100%;
		margin: 0px 0px 15px;
	}
	p {
		color: ${ColorValueCreator().white};

		a *,
		a:link *,
		a:visited * {
			font-size: inherit !important;
		}
	}
`;

export const Decoration = styled.div`
	width: 100%;
	position: absolute;
	left: 0px;
	bottom: -10px;
	z-index: 1;

	svg {
		max-height: 40vh;
		width: 100%;
		height: auto;
	}
	path {
		fill: ${({ $color }) => $color};
	}
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
	align-items: start;
`;
