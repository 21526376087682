/*
 * File: HomeTab.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 6, 2023 at 11:59 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:22 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Card, LocaleLink, Padding } from '../../../../../../components';

// Styles
import { ColorValueCreator } from '../../../../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const WelcomeBanner = styled.div`
	width: 100%;
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: 100% 100%;
	background-size: 50%;
	background-repeat: no-repeat;
	padding-top: 50px;
	padding-bottom: 50px;

	h1 {
		color: ${ColorValueCreator().white};
		text-align: left;
	}
`;

export const HomeSection = styled.div`
	background-color: ${({ theme, $secondaryBackground }) => ($secondaryBackground ? theme.secondaryBackground : theme.primaryBackground)};
`;

export const ResidentCard = styled(Card)`
	box-shadow: ${ColorValueCreator().elementDarkShadow};
	width: 100%;
	padding: 20px;
	margin: 24px 0px 24px;

	* {
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
	h2 {
		margin-bottom: 2px;
	}
`;

export const SectionTitle = styled.div`
	margin: 20px 0px 18px;

	* {
		color: ${({ theme }) => theme.brandPrimaryBase};
		text-align: left;
	}
`;

export const SpotlightBanner = styled.div`
	width: 100%;
	height: 250px;
	display: flex;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: cover;
	align-items: end;
	padding-bottom: 1.5em;
	position: relative;
`;

export const SpotlightContent = styled.div`
	align-items: center;
	justify-content: center;
	z-index: 2;
	position: relative;

	* {
		color: ${ColorValueCreator().white};
		text-align: left;
		line-height: normal;
	}
	h3 {
		margin-bottom: 8px;
	}
`;

export const SpotlightOverlay = styled.div`
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	opacity: 0.5;
	z-index: 1;
`;

export const FeedContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	margin-bottom: 50px;
`;

export const FeedStory = styled(Card)`
	padding: 15px;
	display: flex;
	box-shadow: ${ColorValueCreator().elementDarkShadow};
	gap: 16px;
`;

export const StoryImage = styled.div`
	width: 76px;
	height: 76px;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: cover;
	flex-shrink: 0;
`;

export const StoryContent = styled.div`
	flex-grow: 1;

	* {
		text-align: left;
	}
	span {
		color: ${({ theme }) => theme.brandPrimaryBase};
		margin-bottom: 4px;
		margin-top: -3px;
		display: block;
	}
	p {
		margin-top: 4px;
		color: ${ColorValueCreator().neutralText};
	}
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
	align-items: start;
`;

export const AnnouncementSection = styled.div`
	display: flex;
	padding: 0px 0px 30px;
	gap: 22px;
	align-items: center;
`;

export const AnnouncementIcon = styled.div`
	svg {
		color: ${({ theme }) => theme.brandPrimaryBase};
		font-size: 2.2rem;
	}
`;

export const AnnouncementContent = styled.div`
	* {
		text-align: left;
	}
`;

export const SectionLink = styled(LocaleLink)`
	text-decoration: none;
`;
