/*
 * File: index.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on June 11, 2024 at 12:22 PM
 * Copyright © 2024 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 13, 2024 at 2:03 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const getSuperhero = ({ accessId }) => api.get(`/superhero/${accessId}`);

export const getSuperheroes = () => api.get('/superheroes');
