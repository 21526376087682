/*
 * File: CityAppRouteWrapper.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on April 22, 2024 at 12:24 PM
 * Copyright © 2024 Lithios, LLC. All rights reserved.
 *
 * Last Modified: May 2, 2024 at 1:28 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
	useParams, useSearchParams, useNavigate, Navigate
} from 'react-router-dom';
import socketIOClient from 'socket.io-client';
import { useDebouncedCallback } from 'beautiful-react-hooks';

// Utilities
import { createStateLocale } from '../utilities/locale';

// Slices
import { fetchCityApp } from '../store/slices/cityApp/cityApp.slice';

// Services
import { postCityAppCommand } from '../services/cityapp';

// Navigation
import { PublicRouteWrapper } from './PublicRouteWrapper';

// Constants
import {
	CITY_APP_COMMANDS, CITY_APP_ROLES, SOCKET_ACTIONS, SOCKET_COMMAND_KEY,
} from '../../Constants';
import { AppNavContent } from '../styles/constants';

// Components
import {
	Spinner
} from '../components';


/**
 * Route Wrapper
 */

export const CityAppRouteWrapper = ({
	children, locale
}) => {

	// Get component parameters
	const { accessId } = useParams();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();
	const role = searchParams.get('role');

	// Use hooks
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current statuses from hook
	const cityApp = useSelector((state) => state.cityApp.value);
	const cityAppStatus = useSelector((state) => state.cityApp.status);

	// Create refs for components
	const socketRef = useRef();
	const cityAppRef = useRef();

	// Handle mouse click for app
	const mouseClick = async (e) => {

		// Send commands if commander role
		if (role === CITY_APP_ROLES.COMMANDER) {

			// Get window size
			const { innerWidth: width, innerHeight: height } = window;

			// Get envelope dimensions
			const envelope = AppNavContent * parseFloat(getComputedStyle(document.documentElement).fontSize);

			// Calculate frame width
			const frameWidth = Math.min(envelope, width);

			// Apply envelope to click
			let clickX = e.clientX;
			if (envelope < width) {
				clickX -= ((width - envelope) / 2.0);
			}

			// Ignore click if outside bounds
			if (clickX > frameWidth || clickX < 0) return;

			// Generate percentage coordinates
			const x = clickX / frameWidth;
			const y = e.clientY / height;

			// Post command to listeners
			if (cityApp?.id || cityAppRef?.current?.id) {
				await postCityAppCommand({ cityAppId: cityApp?.id || cityAppRef?.current?.id, command: CITY_APP_COMMANDS.CLICK, data: { x, y } });
			}
		}
	};

	// Handle debounce function for updating positions
	const debounceScroll = useDebouncedCallback(async (e) => {

		// Get target element parameters
		const { id, scrollTop } = e.target;

		// Get window size
		const { innerHeight: height } = window;

		// Generate percentage offset
		const y = scrollTop / height;

		// Post command to listeners
		if (cityApp?.id || cityAppRef?.current?.id) {
			await postCityAppCommand({ cityAppId: cityApp?.id || cityAppRef?.current?.id, command: CITY_APP_COMMANDS.SCROLL, data: { elementId: id, scrollTop: y } });
		}

	}, [cityApp], 200);

	// Handle actions on city app change
	useEffect(() => {

		// Set city app ref
		cityAppRef.current = cityApp;

	}, [cityApp]);

	// Handle actions on session id change
	useEffect(() => {

		// Check if city app id exists and role specified
		if (cityApp?.id && role === CITY_APP_ROLES.PUBLIC) {

			// Disconnect and refresh socket
			if (socketRef.current) socketRef?.current?.disconnect();

			// Create a WebSocket connection
			socketRef.current = socketIOClient(process.env.SOCKET_URL, {
				transports: ['websocket'],
				query: { cityAppId: cityApp?.id },
			});

			// Listen for incoming commands
			socketRef.current.on(SOCKET_COMMAND_KEY, (message) => {

				// Get command parameters
				const { action, command, data } = message.payload;

				// Handle action
				if (action === SOCKET_ACTIONS.CITY_APP_COMMAND) {

					// Handle command
					if (command === CITY_APP_COMMANDS.CLICK) {

						// Get data parameters
						const { x: percentageX, y: percentageY } = data;

						// Get window size
						const { innerWidth: width, innerHeight: height } = window;

						// Get envelope dimensions
						const envelope = AppNavContent * parseFloat(getComputedStyle(document.documentElement).fontSize);

						// Calculate frame width
						const frameWidth = Math.min(envelope, width);

						// Generate explicit coordinates
						let x = percentageX * frameWidth;
						const y = percentageY * height;

						// Add offset to x coordinate if necessary
						if (envelope < width) {
							x += ((width - envelope) / 2.0);
						}

						// Click on element from coordinate
						let element = null;
						try {

							// Click element
							element = document.elementFromPoint(x, y);
							element.click();

							// Click inner button
							const buttons = element.getElementsByTagName('button');
							if (buttons.length === 1) buttons.item(0).click();

							// Click inner link
							const links = element.getElementsByTagName('a');
							if (links.length === 1) links.item(0).click();

						} catch (e) {
							let depthAttempt = 0;
							let clicked = false;
							while (!clicked && depthAttempt < 5) {
								try {
									element = element.parentElement;
									element.click();
									clicked = true;
								} catch (e2) {
									depthAttempt += 1;
								}
							}
						}

					} else if (command === CITY_APP_COMMANDS.SCROLL) {

						// Get data parameters
						const { elementId, scrollTop } = data;

						// Get window size
						const { innerHeight: height } = window;

						// Generate explicit offset
						const y = scrollTop * height;

						// Get element with id
						const element = document.getElementById(elementId);

						// Scroll element
						if (element) element.scrollTo({ top: y, behavior: 'smooth' });
					}
				}
			});

			// Destroys the socket reference when the connection is closed
			return () => { socketRef?.current?.disconnect(); };
		}

		// Default return
		return () => { };

	}, [cityApp?.id]);

	// Handle actions on component load
	useEffect(() => {

		// Fetch city app
		if (cityAppStatus === 'idle') {
			dispatch(fetchCityApp({ accessId }));
		}

		// Start timer if public role
		if (role === CITY_APP_ROLES.PUBLIC) {
			setTimeout(() => {

				// Navigate back to welcome screen
				navigate('/welcome');

			}, 1 * 60 * 1000);
		}

		// Update page zoom if public role
		if (role === CITY_APP_ROLES.PUBLIC) {

			// Store initial zoom value
			const initialValue = document.body.style.zoom;

			// Set new zoom value
			document.body.style.zoom = '180%';

			// Restore default value
			return () => { document.body.style.zoom = initialValue; };
		}

		// Send commands if commander role
		if (role === CITY_APP_ROLES.COMMANDER) {

			// Get event listener for scroll
			window.addEventListener('scroll', (e) => { debounceScroll(e); }, true);

			// Remove event listener
			return () => { window.removeEventListener('scroll', (e) => { debounceScroll(e); }, true); };
		}

		// Default return
		return () => { };

	}, []);

	// Set up component render function
	const renderComponent = () => {
		if (cityAppStatus === 'idle' || cityAppStatus === 'loading') {
			return <Spinner showMeta />;
		}
		if (cityAppStatus === 'failed') {
			return <Navigate to="/welcome" />;
		}
		return children;
	};

	// Render wrapper
	return (
		<PublicRouteWrapper locale={stateLocale} onClick={mouseClick}>
			{renderComponent()}
		</PublicRouteWrapper>
	);
};


/**
 * Configuration
 */

CityAppRouteWrapper.propTypes = {
	children: PropTypes.element,
	locale: PropTypes.shape(),
};
CityAppRouteWrapper.defaultProps = {
	children: null,
	locale: null,
};
