/*
 * File: CityCreationStep.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:23 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import validator from 'validator';

// Utilities
import { toastError } from '../../../../../utilities/toaster';

// Services
import { updateBuildSession } from '../../../../../services/buildsession';

// Components
import {
	Typography,
	TextInput,
	Select
} from '../../../../../components';

// Constants
import { STATE_ABBREVIATIONS, STATE_OPTIONS } from '../../../../../../Constants';

// Styles
import * as S from './CityCreationStep.styles';


/**
 * Component
 */

export const CityCreationStep = forwardRef(({
	updateStep, className, sessionId, updateMunicipalityState
}, ref) => {

	// Create state handlers
	const [municipalityNameError, setMunicipalityNameError] = useState(null);
	const [stateError, setStateError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle continue function
	const handleContinue = async () => {

		// Get parameters
		const { municipalityName, state } = inputValues;

		// Validate parameters
		if (!municipalityName || validator.isEmpty(municipalityName, { ignore_whitespace: true })) {
			setMunicipalityNameError('Please enter the full name of your city');
			return;
		}
		if (!state || !STATE_ABBREVIATIONS.includes(state)) {
			setStateError('Please select the state your city is located in');
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Update build session
		updateBuildSession({ municipalityName, state, sessionId }).then(() => {

			// Set loading state
			setIsLoading(false);

			// Update state
			updateMunicipalityState({ municipalityName, state });

			// Move to next step
			updateStep(2);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setMunicipalityNameError(null);
			setStateError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, null, response?.data?.message);
			} else {
				toastError(uiMode, null, 'Whoops. We\'re having trouble creating your Smart City App. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Logo */}
			<S.LogoContainer>
				<S.Logo src={`${process.env.CDN_URL}/public/assets/lithios-logo.png`} />
			</S.LogoContainer>

			{/* Form */}
			<S.Form>

				{/* Content */}
				<Typography tag="h2" weight="bold">
					Enter a few details about
					{' '}
					<span>your city</span>
					{' '}
					to get started.
				</Typography>

				{/* Inputs */}
				<TextInput
					label="Full Name of City"
					name="municipalityName"
					type="text"
					error={municipalityNameError}
					placeholder="e.g. City of Raleigh"
					value={inputValues.municipalityName || ''}
					containerClassName="formInput"
					onFocus={() => { setMunicipalityNameError(null); }}
					onKeyUp={() => { setMunicipalityNameError(null); }}
					onBlur={() => { setMunicipalityNameError(null); }}
					onChange={handleOnChange}
				/>
				<Select
					label="State"
					name="state"
					options={STATE_OPTIONS.map(({ name, abbreviation }) => ({ label: name, value: abbreviation }))}
					error={stateError}
					placeholder="e.g. North Carolina"
					values={Array.isArray(inputValues.state) ? inputValues.state : [inputValues.state || '']}
					containerClassName="formInput"
					onFocus={() => { setStateError(null); }}
					onKeyUp={() => { setStateError(null); }}
					onBlur={() => { setStateError(null); }}
					onChange={handleOnChange}
				/>

				{/* Continue Action */}
				<S.ActionButton disabled={isLoading} isLoading={isLoading} onClick={handleContinue} size="large" className="floatShadow">Continue</S.ActionButton>

			</S.Form>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

CityCreationStep.displayName = 'CityCreationStep';
CityCreationStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string,
	sessionId: PropTypes.string,
	updateMunicipalityState: PropTypes.func
};
CityCreationStep.defaultProps = {
	className: null,
	sessionId: null,
	updateMunicipalityState: null
};
