/*
 * File: ToolTip.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 4, 2023 at 7:45 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Constants
import { UI_MODE_OPTIONS } from '../../../Constants';

// Styles
import * as S from './ToolTip.styles';


/**
 * Component
 */

export const ToolTip = ({
	place, type, effect, ...rest
}) => {

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Create state handlers
	const [isMounted, setIsMounted] = useState(false);

	// Handle component initialization
	useEffect(() => {

		// Set state
		setIsMounted(true);

	}, []);

	// Handle tooltip rendering
	const renderTooltip = (dataTip) => {
		if (type === 'success') {
			return (
				<S.Inner>
					<FontAwesomeIcon className="success" icon={['fal', 'check-circle']} />
					{dataTip}
				</S.Inner>
			);
		} if (type === 'error') {
			return (
				<S.Inner>
					<FontAwesomeIcon className="error" icon={['fal', 'circle-xmark']} />
					{dataTip}
				</S.Inner>
			);
		}
		return <S.Inner>{dataTip}</S.Inner>;
	};

	// Render component
	return isMounted && (
		<S.ToolTip
			uiMode={uiMode?.mode || UI_MODE_OPTIONS.LIGHT}
			place={place}
			type={type === 'default' ? 'light' : type}
			effect={effect}
			getContent={(dataTip) => renderTooltip(dataTip)}
			{...rest}
		/>
	);
};


/**
 * Configuration
 */

ToolTip.displayName = 'ToolTip';
ToolTip.propTypes = {
	place: PropTypes.string,
	type: PropTypes.oneOf(['default', 'success', 'error']),
	effect: PropTypes.oneOf(['solid', 'float'])
};
ToolTip.defaultProps = {
	place: 'top',
	type: 'default',
	effect: 'solid',
};
