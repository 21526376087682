/*
 * File: utilities.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on April 21, 2024 at 8:50 PM
 * Copyright © 2024 Lithios, LLC. All rights reserved.
 *
 * Last Modified: April 25, 2024 at 8:20 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Constants
import { STATE_OPTIONS } from '../Constants';


/**
 * Utilities
 */

// eslint-disable-next-line no-promise-executor-return
export const timeout = (ms) => new Promise((res) => setTimeout(res, ms));

export const fullStateName = (abbr) => (STATE_OPTIONS.find((state) => state.abbreviation === abbr) || {}).name;

export const formatNumberWithCommas = (n) => n.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

export const generateSlugFromContent = (content, limitLength = true) => {
	const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
	const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
	const p = new RegExp(a.split('').join('|'), 'g');

	// Build slug
	let slug = content
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
		.replace(/&/g, '-and-') // Replace & with 'and'
		.replace(/[^\w-]+/g, '') // Remove all non-word characters
		.replace(/--+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, ''); // Trim - from end of text

	// Limit length of slug
	if (limitLength) {
		slug = slug.substring(0, 42)
			.replace(/-+$/, ''); // Trim - from end of text
	}

	// Return slug
	return slug;
};


/**
 * Parsers
 */

export const parseFirstLastName = (name) => {
	if (!name) return {};
	const nameArray = name.split(/\s+/);
	const firstName = nameArray[0].trim();
	const lastName = name.replace(firstName, '').trim();
	return { firstName, lastName };
};
