/*
 * File: PublicScreen.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 13, 2024 at 12:28 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValueCreator } from '../../../../styles/colors';

// Constants
import {
	mediumBreakpoint,
	xxLargeBreakpoint
} from '../../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
	background-color: ${({ theme }) => theme.brandPrimaryBase};
`;

export const StepContainer = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	display: flex;
	flex-grow: 1;
`;

export const Step = styled.div`
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	position: absolute;
	top: 0px;
	pointer-events: none;
	width: 100%;
	flex-grow: 1;
	display: flex;
	z-index: 99;
	height: 100%;

	&.show {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.5s;
		position: relative;
		pointer-events: initial;
	}
`;

export const StepInner = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const StepContentContainer = styled.div`
	margin-top: -15vh;
`;

export const QRCodeContainer = styled.div`
	position: relative;
	width: 40vw;
	height: 40vw;
	max-width: 40vh;
	max-height: 40vh;
	margin: 80px 0px 0px;
`;

export const BuildProgressContainer = styled.div`
	position: relative;
	width: 30vw;
	height: 30vw;
	max-width: 30vh;
	max-height: 30vh;
	margin: 50px 0px 0px;
	background-color: ${({ theme }) => theme.primaryBackground};
	border-radius: 50%;
	box-shadow: ${ColorValueCreator().elementDarkerShadow};
`;

export const CompletionIconContainer = styled.div`
	position: relative;
	width: 30vw;
	height: 30vw;
	max-width: 30vh;
	max-height: 30vh;
	margin: 50px 0px 0px;
	background-color: ${({ theme }) => theme.primaryBackground};
	border-radius: 50%;
	box-shadow: ${ColorValueCreator().elementDarkerShadow};
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		color: ${({ theme }) => theme.brandSecondaryBase};
		font-size: 4.0rem;

		@media only screen and (min-width: ${mediumBreakpoint}em) {
			font-size: 9.0rem;
		}
	}
`;

export const QRCode = styled.img`
	background-color: ${({ theme }) => theme.primaryBackground};
	border-radius: 20px;
	box-shadow: ${ColorValueCreator().elementDarkerShadow};
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 2;
`;

export const AnimationContainer = styled.div`
	position: absolute;
	width: 180%;
	height: 180%;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	top: -40%;
	left: -40%;
	opacity: ${({ $opacity }) => $opacity};

	&.largeAnimation {
		width: 300%;
		height: 300%;
		top: -100%;
		left: -100%;
	}
`;

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 90vw;
	position: relative;

	@media only screen and (min-width: ${xxLargeBreakpoint}em) {
		width: auto;
		max-width: 60vw;
	}
	h1 {
		color: ${ColorValueCreator().white};
		font-size: 2.0rem;
		position: relative;
		z-index: 2;

		@media only screen and (min-width: ${mediumBreakpoint}em) {
			font-size: 3.6rem;
		}
		span {
			text-decoration: underline;
			text-decoration-color: ${({ theme }) => theme.brandSecondaryBase};
		}
	}
	h3 {
		background-color: ${({ theme }) => theme.primaryBackground};
		border-radius: 10px;
		padding: 10px 20px;
		box-shadow: ${ColorValueCreator().elementDarkerShadow};
		display: inline-block;
		margin: 0px 0px 30px;
		position: relative;
		z-index: 3;
	}
`;

export const InstructionsContainer = styled.div`
	position: relative;
	z-index: 3;
	max-width: 90vw;
	text-align: center;

	h1 {
		color: ${ColorValueCreator().white};
		font-size: 2.0rem;
		position: relative;
		z-index: 2;
		margin: 0px 0px 10px;

		@media only screen and (min-width: ${mediumBreakpoint}em) {
			font-size: 4.0rem;
		}
		span {
			text-decoration: underline;
			text-decoration-color: ${({ theme }) => theme.brandSecondaryBase};
		}
	}
	h3 {
		background-color: ${({ theme }) => theme.primaryBackground};
		border-radius: 10px;
		padding: 10px 20px;
		box-shadow: ${ColorValueCreator().elementDarkerShadow};
		display: inline-block;
		margin: 0px 0px 30px;

		span {
			margin-right: 10px;
		}
	}
`;

export const Decoration = styled.img`
	position: absolute;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	height: 50vh;
`;

export const ConfettiTarget = styled.div`
	position: absolute;
	top: 20px;
	z-index: 2;
`;
