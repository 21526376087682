/*
 * File: colors.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:32 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: April 22, 2024 at 9:37 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Color Values
 */

export const ColorValueCreator = (cityApp) => ({

	// Brand
	brandPrimaryBase: cityApp && cityApp.primaryColor ? cityApp.primaryColor : '#1A4ED4',
	brandSecondaryBase: cityApp && cityApp.secondaryColor ? cityApp.secondaryColor : '#3AD98C',

	// Primary
	white: '#FFFFFF',
	dark: '#000000',

	// Neutral
	neutralButtonBase: '#747888',
	neutralButtonLighter: '#EEF0F6',
	neutralBackground: '#F9FAFF',
	neutralBackgroundDarker: '#39393B',
	neutralHighlight: '#E2E2E2',
	neutralHighlightDarker: '#505054',
	neutralForm: '#DBDFEC',
	neutralText: '#7F8496',
	neutralBorder: 'rgba(74,74,74,.05)',

	// Success
	statusSuccessBase: '#25953E',
	statusSuccessLighter: '#EAFFEE',
	statusSuccessDarker: '#04290D',

	// Warning
	statusWarningBase: '#E1D037',
	statusWarningLighter: '#FFFDE7',
	statusWarningDarker: '#302B05',

	// Danger
	statusDangerBase: '#E13737',
	statusDangerLighter: '#FFEBEB',
	statusDangerDarker: '#360404',

	// Info
	statusInfoBase: '#1A4ED4',
	statusInfoLighter: '#E7EDFF',
	statusInfoDarker: '#0B1D4D',

	// Shadows
	navigationShadow: '0px 1px 1px rgb(0 0 0 / 15%)',
	elementShadow: '0px -3px 20px rgba(0, 0, 0, 0.06)',
	elementDarkShadow: '0px 2px 16px rgba(0 0 0 / 10%)',
	elementDarkerShadow: '0px 2px 52px rgba(0 0 0 / 30%)',
});


/**
 * Themes
 */

export const DarkTheme = (cityApp) => {

	// Define color values
	const ColorValues = ColorValueCreator(cityApp);

	// Build theme
	return {

		// Brand
		brandPrimaryBase: ColorValues.brandPrimaryBase,
		brandSecondaryBase: ColorValues.brandSecondaryBase,

		// Success
		statusSuccessBase: ColorValues.statusSuccessBase,
		statusSuccessLighter: ColorValues.statusSuccessDarker,

		// Warning
		statusWarningBase: ColorValues.statusWarningBase,
		statusWarningLighter: ColorValues.statusWarningDarker,

		// Danger
		statusDangerBase: ColorValues.statusDangerBase,
		statusDangerLighter: ColorValues.statusDangerDarker,

		// Info
		statusInfoBase: ColorValues.statusInfoBase,
		statusInfoLighter: ColorValues.statusInfoDarker,

		// Background
		primaryBackground: ColorValues.dark,
		secondaryBackground: ColorValues.brandPrimaryBase,
		cardBackground: ColorValues.dark,

		// Text
		primaryText: ColorValues.white,
		secondaryText: ColorValues.neutralText,

		// Button
		primaryButton: ColorValues.brandSecondaryBase,
		secondaryButton: ColorValues.neutralButtonBase,
		darkButton: ColorValues.dark,
		lightButton: ColorValues.neutralBackgroundDarker,

		// Decoration
		primaryDecoration: ColorValues.white,
		secondaryDecoration: ColorValues.neutralBorder,
		layoutBorder: ColorValues.neutralText,
		navigationBorder: ColorValues.neutralText,
		inactiveElement: ColorValues.neutralText,
		placeholderPrimary: ColorValues.neutralBackgroundDarker,
		placeholderHighlight: ColorValues.neutralHighlightDarker,

		// Gradient
		primaryGradientStart: 'rgba(0,0,0,0)',
		primaryGradientEnd: 'rgba(0,0,0,1)',

		// Input
		inputBackground: ColorValues.dark,
		inputSecondayBackground: ColorValues.neutralBackground,
		inputBorder: ColorValues.neutralText,
		inputFocusBorder: ColorValues.neutralButtonBase,
		inputPlaceholder: ColorValues.neutralText,
	};
};

export const LightTheme = (cityApp) => {

	// Define color values
	const ColorValues = ColorValueCreator(cityApp);

	// Build theme
	return {

		// Brand
		brandPrimaryBase: ColorValues.brandPrimaryBase,
		brandSecondaryBase: ColorValues.brandSecondaryBase,

		// Success
		statusSuccessBase: ColorValues.statusSuccessBase,
		statusSuccessLighter: ColorValues.statusSuccessLighter,

		// Warning
		statusWarningBase: ColorValues.statusWarningBase,
		statusWarningLighter: ColorValues.statusWarningLighter,

		// Danger
		statusDangerBase: ColorValues.statusDangerBase,
		statusDangerLighter: ColorValues.statusDangerLighter,

		// Info
		statusInfoBase: ColorValues.statusInfoBase,
		statusInfoLighter: ColorValues.statusInfoLighter,

		// Background
		primaryBackground: ColorValues.white,
		secondaryBackground: ColorValues.neutralBackground,
		cardBackground: ColorValues.white,

		// Text
		primaryText: ColorValues.dark,
		secondaryText: ColorValues.neutralText,

		// Button
		primaryButton: ColorValues.brandSecondaryBase,
		secondaryButton: ColorValues.neutralButtonBase,
		darkButton: ColorValues.dark,
		lightButton: ColorValues.white,

		// Decoration
		primaryDecoration: ColorValues.dark,
		secondaryDecoration: ColorValues.neutralBorder,
		layoutBorder: ColorValues.neutralForm,
		navigationBorder: 'transparent',
		inactiveElement: ColorValues.neutralText,
		placeholderPrimary: ColorValues.neutralBackground,
		placeholderHighlight: ColorValues.neutralHighlight,

		// Gradient
		primaryGradientStart: 'rgba(255,255,255,0)',
		primaryGradientEnd: 'rgba(255,255,255,1)',

		// Input
		inputBackground: ColorValues.white,
		inputSecondayBackground: ColorValues.neutralBackground,
		inputBorder: ColorValues.neutralForm,
		inputFocusBorder: ColorValues.neutralButtonBase,
		inputPlaceholder: ColorValues.neutralForm,
	};
};
