/*
 * File: api.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on December 5, 2023 at 11:50 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 5, 2023 at 11:51 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import axios from 'axios';


/**
 * API Handler
 */

const api = axios.create({
	baseURL: `${process.env.API_URL}${process.env.API_MOUNT}`,
	withCredentials: true,
});


/**
 * Configure API
 */

api.interceptors.request.use(async (config) => {
	const headers = { ...config.headers };
	return { ...config, headers };
});

api.interceptors.response.use((response) => response, (error) => {
	if (error && error.response && error.response.status === 401 && !(error.response?.config?.url || '').endsWith('/login')) {
		window.location = '/login';
	}
	return Promise.reject(error);
});


/**
 * Exports
 */

export default api;
