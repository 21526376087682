/*
 * File: MoreTab.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 6, 2023 at 11:59 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:21 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

// Components
import { LocaleLink, Typography } from '../../../../../../components';

// Screen Components
import { UtilitiesScreen } from './UtilitiesScreen';

// Styles
import * as S from './MoreTab.styles';


/**
 * Constants
 */

const SCREENS = {
	UTILITIES: 'utilities'
};


/**
 * Component
 */

const MoreTab = () => {

	// Create state handlers
	const [visibleScreen, setVisibleScreen] = useState(null);

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Render component function
	const renderComponent = () => {
		if (visibleScreen) {
			switch (visibleScreen) {
				case SCREENS.UTILITIES:
					return <UtilitiesScreen hideScreen={() => { setVisibleScreen(null); }} />;
				default:
					break;
			}
		}
		return (
			<S.RowsContainer>

				{/* Transportation */}
				<LocaleLink to="https://raleighnc.gov/transportation" target="_blank">
					<S.OptionRow>
						<S.ContentPadding left right>
							<Typography tag="h5" weight="semibold">Transportation</Typography>
							<FontAwesomeIcon icon={['fal', 'arrow-up-right-from-square']} />
						</S.ContentPadding>
					</S.OptionRow>
				</LocaleLink>

				{/* Parking */}
				<LocaleLink to="https://raleighnc.gov/parking" target="_blank">
					<S.OptionRow>
						<S.ContentPadding left right>
							<Typography tag="h5" weight="semibold">Parking</Typography>
							<FontAwesomeIcon icon={['fal', 'arrow-up-right-from-square']} />
						</S.ContentPadding>
					</S.OptionRow>
				</LocaleLink>

				{/* Utilities */}
				<S.OptionRow onClick={() => {
					setVisibleScreen(SCREENS.UTILITIES);
				}}
				>
					<S.ContentPadding left right>
						<Typography tag="h5" weight="semibold">Utilities</Typography>
					</S.ContentPadding>
				</S.OptionRow>

				{/* FAQ */}
				<LocaleLink to="https://www.lithiosapps.com/about-us" target="_blank">
					<S.OptionRow>
						<S.ContentPadding left right>
							<Typography tag="h5" weight="semibold">FAQ</Typography>
							<FontAwesomeIcon icon={['fal', 'arrow-up-right-from-square']} />
						</S.ContentPadding>
					</S.OptionRow>
				</LocaleLink>

				{/* Contact Us */}
				<LocaleLink to="https://www.lithiosapps.com/contact-us" target="_blank">
					<S.OptionRow>
						<S.ContentPadding left right>
							<Typography tag="h5" weight="semibold">Contact Us</Typography>
							<FontAwesomeIcon icon={['fal', 'arrow-up-right-from-square']} />
						</S.ContentPadding>
					</S.OptionRow>
				</LocaleLink>

				{/* Spacer */}
				<S.Spacer />

				{/* Logout */}
				<LocaleLink to={`/app/${currentCityApp?.accessId}/login${searchParams.toString() ? `?${searchParams.toString()}` : ''}`}>
					<S.OptionRow $topBorder>
						<S.ContentPadding left right>
							<Typography tag="h5" weight="semibold">Logout</Typography>
							<FontAwesomeIcon icon={['fal', 'arrow-right-from-bracket']} />
						</S.ContentPadding>
					</S.OptionRow>
				</LocaleLink>

			</S.RowsContainer>
		);
	};

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.Wrapper>{renderComponent()}</S.Wrapper>
	);
};


/**
 * Configuration
 */

MoreTab.propTypes = {};
MoreTab.defaultProps = {};


/**
 * Exports
 */

export default MoreTab;
