/*
 * File: ConfirmationStep.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 9, 2023 at 9:09 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:20 AM
 * Modified By: Brendan Michaelsen
 */

// Modules
import React, {
	useEffect, forwardRef, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import {
	Typography, Button, ConfettiBurst
} from '../../../../../../components';

// Styles
import * as S from './ConfirmationStep.styles';


/**
 * Component
 */

export const ConfirmationStep = forwardRef(({
	className, isVisible
}, ref) => {

	// Create state handlers
	const [fireConfetti, setFireConfetti] = useState(false);

	// Get actions from hooks
	const navigate = useNavigate();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Create refs
	const confettiTargetRef = useRef();

	// Handle actions on component load
	useEffect(() => {
		if (isVisible) {

			// Fire confetti
			setTimeout(() => {
				setFireConfetti(true);
			}, 1000);

			// Reset confetti
			setTimeout(() => {
				setFireConfetti(false);
			}, 5000);
		}
	}, [isVisible]);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className ? `${className} scroller` : 'scroller'} id="scroller-confirmation-step">

			{/* Logo Container */}
			<S.LogoContainer>
				<S.Logo
					alt={`${currentCityApp?.municipalityName} Logo`}
					title={`${currentCityApp?.municipalityName} Logo`}
					src={currentCityApp?.logoImage}
				/>
			</S.LogoContainer>

			{/* Content Container */}
			<S.ContentContainer>
				<S.ContentPadding left right>
					<S.Content>
						<FontAwesomeIcon icon={['fal', 'party-horn']} />
						<S.ConfettiTarget ref={confettiTargetRef} />
						<Typography tag="h1" weight="bold">You’re all done!</Typography>
						<Typography tag="h4" weight="regular">
							Continue to your account to start exploring
							{' '}
							{currentCityApp?.municipalityName}
							.
						</Typography>
						<Button onClick={() => { navigate(`${clientLocale.localePath}/app/${currentCityApp?.accessId}${searchParams.toString() ? `?${searchParams.toString()}` : ''}#home`); }}>Continue</Button>
					</S.Content>
				</S.ContentPadding>
			</S.ContentContainer>

			{/* Decoration */}
			<S.Decoration src={`${process.env.CDN_URL}/public/assets/building-decoration-2-transparency.png`} />

			{/* Confetti */}
			{fireConfetti && (
				<ConfettiBurst
					fireAway={fireConfetti}
					targetRef={confettiTargetRef}
					force={0.4}
					duration={3000}
					particleCount={80}
				/>
			)}

		</S.StepContainer>
	);
});


/**
 * Configuration
 */

ConfirmationStep.displayName = 'ConfirmationStep';
ConfirmationStep.propTypes = {
	className: PropTypes.string,
	isVisible: PropTypes.bool
};
ConfirmationStep.defaultProps = {
	className: null,
	isVisible: false
};
