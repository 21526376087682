/*
 * File: PublicScreen.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:19 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useMemo, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import socketIOClient from 'socket.io-client';
import { v4 } from 'uuid';
import { useWindowResize } from 'beautiful-react-hooks';
import Lottie from 'react-lottie-player';
import { flatten } from 'lottie-colorify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { createStateLocale } from '../../../../utilities/locale';

// Slices
import { clearCityApp } from '../../../../store/slices/cityApp/cityApp.slice';

// Components
import {
	Emoji,
	Meta,
	Typography,
	ConfettiBurst
} from '../../../../components';

// Constants
import { CITY_APP_ROLES, SOCKET_ACTIONS, SOCKET_COMMAND_KEY } from '../../../../../Constants';

// Animation
import pulseAnimationData from '../../../../assets/animations/pulse.json';
import spinnerAnimationData from '../../../../assets/animations/spinner.json';

// Styles
import * as S from './PublicScreen.styles';


/**
 * Component
 */

const PublicScreen = ({ meta, locale }) => {

	// Create reference for step components
	const [qrStepHeight, setQRStepHeight] = useState(0);
	const [welcomeStepHeight, setWelcomeStepHeight] = useState(0);
	const [buildStepHeight, setBuildStepHeight] = useState(0);
	const [completeStepHeight, setCompleteStepHeight] = useState(0);
	const qrStepRef = useRef();
	const welcomeStepRef = useRef();
	const buildStepRef = useRef();
	const completeStepRef = useRef();

	// Create state handlers
	const [qrCodeURI, setQRCodeURI] = useState(null);
	const [municipalityName, setMunicipalityName] = useState(null);
	const [representativeName, setRepresentativeName] = useState(null);
	const [currentStep, setCurrentStep] = useState(1);
	const [shouldUpdateHeight, setShouldUpdateHeight] = useState(true);
	const [fireConfetti, setFireConfetti] = useState(false);
	const [setupComplete, setSetupComplete] = useState(false);

	// Generate build session id
	const sessionId = useMemo(() => v4(), []);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Use hooks
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// Create refs
	const socketRef = useRef();
	const confettiTargetRef = useRef();

	// Generate QR code
	const generateQRCode = async () => {
		const code = await QRCode.toDataURL(`${process.env.APP_URL}/build/${sessionId}`, {
			scale: 10
		});
		setQRCodeURI(code);
	};

	// Handle actions on session id change
	useEffect(() => {

		// Generate QR code URI
		generateQRCode();

		// Disconnect and refresh socket
		if (socketRef.current) socketRef?.current?.disconnect();

		// Create a WebSocket connection
		socketRef.current = socketIOClient(process.env.SOCKET_URL, {
			transports: ['websocket'],
			query: { sessionId },
		});

		// Listen for incoming commands
		socketRef.current.on(SOCKET_COMMAND_KEY, (message) => {

			// Get parameters from payload
			const {
				action, accessId, municipalityName: name, representativeName: repName, setupComplete: isSetupComplete
			} = message.payload;

			// Handle action
			if (action === SOCKET_ACTIONS.START_SESSION) {

				// Update current step
				setCurrentStep(2);

			} else if (action === SOCKET_ACTIONS.UPDATE_SESSION) {

				// Check if session complete
				if (!isSetupComplete) {

					// Update step with data
					setMunicipalityName(name);
					setCurrentStep(3);

				} else {

					// Update step with setup
					setSetupComplete(true);
				}

			} else if (action === SOCKET_ACTIONS.COMPLETE_SESSION) {

				// Update current step
				setCurrentStep(4);
				setRepresentativeName(repName);

				// Fire confetti
				setTimeout(() => {
					setFireConfetti(true);
				}, 100);

				// Reset confetti
				setTimeout(() => {
					setFireConfetti(false);
				}, 5000);

				// Redirect to newly created city app
				setTimeout(() => {

					// Move to city app
					navigate(`/app/${accessId}?role=${CITY_APP_ROLES.PUBLIC}`);

				}, 6000);
			}
		});

		// Destroys the socket reference when the connection is closed
		return () => { socketRef?.current?.disconnect(); };

	}, [sessionId]);

	// Handle actions on component load
	useEffect(() => {

		// Clear city app
		dispatch(clearCityApp());

	}, []);

	// Handle actions on component load
	useEffect(() => {
		if (shouldUpdateHeight) {

			// Set component heights
			setQRStepHeight(qrStepRef?.current?.clientHeight);
			setWelcomeStepHeight(welcomeStepRef?.current?.clientHeight);
			setBuildStepHeight(buildStepRef?.current?.clientHeight);
			setCompleteStepHeight(completeStepRef?.current?.clientHeight);

			// Update state
			setShouldUpdateHeight(false);
		}
	}, [shouldUpdateHeight]);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set component heights
		setQRStepHeight(qrStepRef?.current?.clientHeight);
		setWelcomeStepHeight(welcomeStepRef?.current?.clientHeight);
		setBuildStepHeight(buildStepRef?.current?.clientHeight);
		setCompleteStepHeight(completeStepRef?.current?.clientHeight);
	});

	// Get step height for step component
	const getStepHeight = () => {
		switch (currentStep) {
			case 1:
				return qrStepHeight;
			case 2:
				return welcomeStepHeight;
			case 3:
				return buildStepHeight;
			case 4:
				return completeStepHeight;
			default:
				return 0;
		}
	};

	// Handle render component
	const renderComponent = () => (
		<>
			<S.StepContainer className="animate" stepHeight={getStepHeight()}>

				{/* QR Step */}
				<S.Step className={currentStep === 1 ? 'animate show' : 'animate'}>
					<S.StepInner ref={qrStepRef}>
						<S.StepContentContainer>

							{/* Instructions */}
							<S.InstructionsContainer>
								<Typography tag="h1" weight="bold">
									Build your own
									{' '}
									<span>Smart City App</span>
									{' '}
									in less than
									{' '}
									<span>30 seconds</span>
								</Typography>
								<Typography tag="h4" weight="bold">
									<span style={{ marginRight: '8px' }}>No coding required</span>
									<Emoji symbol="😎" size={1.5} label="Celebration" />
								</Typography>
							</S.InstructionsContainer>

							{/* Content */}
							<S.ContentContainer>
								<S.QRCodeContainer>
									<S.QRCode src={qrCodeURI} />
									<S.AnimationContainer $opacity={0.7}>
										<Lottie
											loop
											play
											animationData={flatten('#FFFFFF', pulseAnimationData)}
											style={{
												width: '100%',
												height: '100%'
											}}
										/>
									</S.AnimationContainer>
								</S.QRCodeContainer>
							</S.ContentContainer>
						</S.StepContentContainer>
					</S.StepInner>
				</S.Step>

				{/* Welcome Step */}
				<S.Step className={currentStep === 2 ? 'animate show' : 'animate'}>
					<S.StepInner ref={welcomeStepRef}>
						<S.StepContentContainer>

							{/* Content */}
							<S.ContentContainer>
								<Typography tag="h4" weight="bold">
									<span style={{ marginRight: '8px' }}>Welcome</span>
									<Emoji symbol="👋" size={1.5} label="Wave" />
								</Typography>
								<Typography tag="h1" weight="bold">
									We&apos;re collecting a few details to build your
									{' '}
									<span>Smart City App</span>
									...
								</Typography>

								{/* Animation */}
								<S.AnimationContainer className="largeAnimation" $opacity={0.7}>
									<Lottie
										loop
										play
										animationData={flatten('#FFFFFF', pulseAnimationData)}
										style={{
											width: '100%',
											height: '100%'
										}}
									/>
								</S.AnimationContainer>

							</S.ContentContainer>
						</S.StepContentContainer>
					</S.StepInner>
				</S.Step>

				{/* Build Step */}
				<S.Step className={currentStep === 3 ? 'animate show' : 'animate'}>
					<S.StepInner ref={buildStepRef}>
						<S.StepContentContainer>

							{/* Instructions */}
							<S.InstructionsContainer>
								<Typography tag="h1" weight="bold">
									Creating the perfect app for
									{' '}
									<span>{municipalityName}</span>
									...
								</Typography>
								{setupComplete && (
									<Typography tag="h4" weight="bold">
										<span style={{ marginRight: '8px' }}>Answer a few more questions on your device to complete your app</span>
										<Emoji symbol="🫡" size={1.5} label="Salute" />
									</Typography>
								)}
							</S.InstructionsContainer>

							{/* Content */}
							<S.ContentContainer>
								<S.BuildProgressContainer>
									<S.AnimationContainer $opacity={1}>
										<Lottie
											loop
											play
											animationData={flatten('#3AD98C', spinnerAnimationData)}
											style={{
												width: '100%',
												height: '100%'
											}}
										/>
									</S.AnimationContainer>
								</S.BuildProgressContainer>
							</S.ContentContainer>
						</S.StepContentContainer>
					</S.StepInner>
				</S.Step>

				{/* Complete Step */}
				<S.Step className={currentStep === 4 ? 'animate show' : 'animate'}>
					<S.StepInner ref={completeStepRef}>
						<S.StepContentContainer>

							{/* Content */}
							<S.ContentContainer>
								<Typography tag="h4" weight="bold">
									<span>Good to go!</span>
								</Typography>
								<Typography tag="h1" weight="bold">
									Your
									{' '}
									<span>{municipalityName}</span>
									{' '}
									app is ready,
									{' '}
									<span>{representativeName}</span>
									.
								</Typography>
								<S.CompletionIconContainer>
									<FontAwesomeIcon className="checkmark" icon={['fas', 'check']} />
									<S.ConfettiTarget ref={confettiTargetRef} />
								</S.CompletionIconContainer>
							</S.ContentContainer>

						</S.StepContentContainer>

						{/* Confetti */}
						{fireConfetti && (
							<ConfettiBurst
								fireAway={fireConfetti}
								targetRef={confettiTargetRef}
								force={0.4}
								duration={3000}
								particleCount={80}
							/>
						)}
					</S.StepInner>
				</S.Step>
			</S.StepContainer>

			{/* Decoration */}
			<S.BadgeDecoration src={`${process.env.CDN_URL}/public/assets/smart-50-badge.png`} />

			{/* Decoration */}
			<S.Decoration src={`${process.env.CDN_URL}/public/assets/building-decoration-white-extended.png`} />
		</>
	);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{}} locale={stateLocale} />

			{/* Component Content */}
			<S.Wrapper>{renderComponent()}</S.Wrapper>
		</>
	);
};


/**
 * Configuration
 */

PublicScreen.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
PublicScreen.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default PublicScreen;
