/*
 * File: ChatWidget.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: April 22, 2024 at 2:10 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValueCreator } from '../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	.__react_component_tooltip {
		top: ${({ $position }) => $position.y}px !important;
		left: ${({ $position }) => $position.x}px !important;
		width: ${({ $width }) => $width}px !important;
	}
	.chatWidgetTooltip {
		&.__react_component_tooltip {
			background-color: ${({ theme }) => theme.brandPrimaryBase} !important;
			color: ${ColorValueCreator().white} !important;

			* {
				font-size: 0.8rem !important;
				text-align: center !important;
			}
			&::before,
			&::after {
				background-color: ${({ theme }) => theme.brandPrimaryBase} !important;
			}
		}
	}
`;

export const ChatWidget = styled.div`
	width: 60px;
	height: 60px;
	border-radius: 30px;
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	text-align: center;
	position: fixed;
	bottom: 60px;
	right: 10px;
	box-shadow: ${ColorValueCreator().elementDarkShadow};
	opacity: 1;
	cursor: pointer;
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
	animation: bounce 0.8s infinite alternate;

	svg {
		font-size: 1.6rem;
		color: ${ColorValueCreator().white};
	}
	@keyframes bounce {
		from {
			transform: translateY(0px);
		}
		to {
			transform: translateY(-3px);
		}
	}
	.widgetCloseButton {
		opacity: 0;
	}
	&:hover {
		.widgetCloseButton {
			opacity: 1;
		}
	}
`;
