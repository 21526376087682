/*
 * File: index.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:35 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 12:43 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { configureStore } from '@reduxjs/toolkit';

// Reducers
import localeReducer from './slices/locale/locale.slice';
import uiReducer from './slices/ui/ui.slice';
import conversationReducer from './slices/conversation/conversation.slice';
import cityAppReducer from './slices/cityApp/cityApp.slice';
import superheroReducer from './slices/superhero/superhero.slice';


/**
 * Store
 */

export default configureStore({
	reducer: {
		locale: localeReducer,
		ui: uiReducer,
		conversation: conversationReducer,
		cityApp: cityAppReducer,
		superhero: superheroReducer
	},
});
