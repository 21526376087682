/*
 * File: NotificationsStep.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 9, 2023 at 9:09 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:20 AM
 * Modified By: Brendan Michaelsen
 */

// Modules
import React, { useEffect, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

// Components
import {
	Typography, Button
} from '../../../../../../components';

// Styles
import * as S from './NotificationsStep.styles';


/**
 * Constants
 */

const NOTIFICATION_OPTIONS = {
	DAILY: 'daily',
	WEEKLY: 'weekly',
	MONTHLY: 'monthly'
};


/**
 * Component
 */

export const NotificationsStep = forwardRef(({
	className, updateStep
}, ref) => {

	// Create state handlers
	const [selectedOption, setSelectedOption] = useState(null);

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Perform actions on component load
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className ? `${className} scroller` : 'scroller'} id="scroller-notifications-step">

			{/* Logo Container */}
			<S.LogoContainer>
				<S.Logo
					alt={`${currentCityApp?.municipalityName} Logo`}
					title={`${currentCityApp?.municipalityName} Logo`}
					src={currentCityApp?.logoImage}
				/>
			</S.LogoContainer>

			{/* Progress Bar */}
			<S.ProgressBarContainer>
				<S.ContentPadding left right>
					<S.ProgressBar>
						<S.ProgressLeft />
						<S.ProgressRight />
					</S.ProgressBar>
				</S.ContentPadding>
			</S.ProgressBarContainer>

			{/* Content Container */}
			<S.ContentContainer>
				<S.ContentPadding left right>
					<S.Content>

						{/* Title */}
						<Typography tag="h2" weight="bold">How often do you want notifications?</Typography>

						{/* Notifications Grid */}
						<S.NotificationsGrid>

							{/* Daily */}
							<S.NotificationRow onClick={() => { setSelectedOption(NOTIFICATION_OPTIONS.DAILY); }}>
								<S.NotificationSelector $isActive={selectedOption === NOTIFICATION_OPTIONS.DAILY} />
								<Typography tag="h4">Daily</Typography>
							</S.NotificationRow>

							{/* Weekly */}
							<S.NotificationRow onClick={() => { setSelectedOption(NOTIFICATION_OPTIONS.WEEKLY); }}>
								<S.NotificationSelector $isActive={selectedOption === NOTIFICATION_OPTIONS.WEEKLY} />
								<Typography tag="h4">Weekly</Typography>
							</S.NotificationRow>

							{/* Monthly */}
							<S.NotificationRow onClick={() => { setSelectedOption(NOTIFICATION_OPTIONS.MONTHLY); }}>
								<S.NotificationSelector $isActive={selectedOption === NOTIFICATION_OPTIONS.MONTHLY} />
								<Typography tag="h4">Monthly</Typography>
							</S.NotificationRow>

						</S.NotificationsGrid>
					</S.Content>
				</S.ContentPadding>
			</S.ContentContainer>

			{/* Bottom Container */}
			<S.BottomActionContainer>
				<S.ContentPadding left right>
					<Button
						variant="text"
						size="large"
						onClick={() => { updateStep(4); }}
					>
						<Typography tag="h4" weight="semibold">Finish</Typography>
						<FontAwesomeIcon icon={['fal', 'arrow-right']} />
					</Button>
				</S.ContentPadding>
			</S.BottomActionContainer>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

NotificationsStep.displayName = 'NotificationsStep';
NotificationsStep.propTypes = {
	className: PropTypes.string,
	updateStep: PropTypes.func
};
NotificationsStep.defaultProps = {
	className: null,
	updateStep: null
};
