/*
 * File: Chat.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on December 4, 2023 at 11:50 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:22 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import validator from 'validator';

// Utilities
import { createStateLocale } from '../../../../../utilities/locale';
import { toastError } from '../../../../../utilities/toaster';

// Slices
import { pushMessageToConversation } from '../../../../../store/slices/conversation/conversation.slice';

// Services
import { sendChatMessage } from '../../../../../services/chat';

// Components
import {
	AppNavigation,
	ErrorComponent,
	IconButton,
	Meta,
	Spinner,
	Typography
} from '../../../../../components';

// Styles
import * as S from './Chat.styles';


/**
* Component
*/

const Chat = ({ meta, locale }) => {

	// Create state handlers
	const [pageStatus, setPageStatus] = useState('idle');
	const [inputValues, setInputValues] = useState({});

	// Get hooks
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get conversation from state
	const conversation = useSelector((state) => state.conversation.value);

	// Create reference for components
	const isMounted = useRef(true);
	const messagesEndRef = useRef(null);

	// Handle send message
	const sendMessage = async () => {

		// Get parameters
		const { content } = inputValues;

		// Validate parameters
		if (!content || validator.isEmpty(content, { ignore_whitespace: true })) {
			return;
		}

		// Append message
		dispatch(pushMessageToConversation({ userSent: true, text: content }));

		// Clear text
		setInputValues({});

		// Send chat message
		try {

			// Send message
			const { data: { response } } = await sendChatMessage({ text: content, cityAppId: currentCityApp?.id });

			// Append response message
			dispatch(pushMessageToConversation({ userSent: false, ...response }));

		} catch (e) {

			// Show error
			toastError(uiMode, currentCityApp, 'Whoops. We\'re having trouble sending your message. Please try again.');
		}
	};

	// Handle fetch data for page
	const fetchDataForPage = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Set timeout
			setTimeout(() => {

				// Check if component is mounted
				if (isMounted.current) {

					// Update page status
					setPageStatus('success');
				}

			}, 1000);

		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value
		} = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};


	// Handle actions on conversation message page
	useEffect(() => {

		// Scroll messages into view
		messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });

	}, [conversation.messages]);

	// Handle actions on page status change
	useEffect(() => {

		// Set page status if necessary
		if (pageStatus === 'idle') {

			// Fetch data state for page
			fetchDataForPage();

		} else if (pageStatus === 'success') {

			// Scroll view
			messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
		}
	}, [pageStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle render component
	const renderComponent = () => {
		if (pageStatus === 'idle' || pageStatus === 'loading') {
			return <Spinner />;
		}
		if (pageStatus === 'error') {
			return <ErrorComponent />;
		}
		return conversation.messages.map((message) => (message.userSent
			? (
				<S.RightMessageContainer ref={messagesEndRef}>
					<S.RightMessage>
						<Typography tag="p" variation="2">{message.text}</Typography>
					</S.RightMessage>
				</S.RightMessageContainer>
			)
			: (
				<S.LeftMessageContainer ref={messagesEndRef}>
					<S.ChatIcon>
						<FontAwesomeIcon icon={['fas', 'comment']} />
					</S.ChatIcon>
					<S.LeftMessage>
						<Typography tag="p" variation="2">{message.text}</Typography>
					</S.LeftMessage>
				</S.LeftMessageContainer>
			)));
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{ app: currentCityApp || { municipalityName: 'MyCity' } }} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>

					{/* Navigation Bar */}
					<S.NavigationBar>
						<S.ContentPadding left right>
							<Typography tag="h1" weight="bold">Chat</Typography>
							<IconButton icon={['fal', 'xmark']} size={1.4} onClick={() => { navigate(-1); }} />
						</S.ContentPadding>
					</S.NavigationBar>

					{/* Chat Content */}
					<S.ConversationContainer className="scroller" id="scroller-chat">
						<S.ContentPadding left right className="fullHeight">
							<S.ChatContent>
								{renderComponent()}
								<S.ConversationFooter />
							</S.ChatContent>
						</S.ContentPadding>
					</S.ConversationContainer>

					{/* Message Bar */}
					<S.MessageBar>
						<input
							placeholder="Start typing here..."
							className="animate"
							name="content"
							type="text"
							value={inputValues.content || ''}
							onChange={handleOnChange}
						/>
						<IconButton
							icon={['fas', 'paper-plane-top']}
							size={1.4}
							onClick={sendMessage}
						/>
					</S.MessageBar>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
* Configuration
*/

Chat.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Chat.defaultProps = {
	meta: {},
	locale: {}
};


/**
* Exports
*/

export default Chat;
