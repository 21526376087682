/*
 * File: Error.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:20 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Padding } from '../../../../components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	background-color: ${({ theme }) => theme.brandPrimaryBase};

	.fullComponent {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 99;
	}
`;

export const LogoContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
`;

export const Logo = styled.img`
	width: 86px;
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

export const Decoration = styled.img`
	position: absolute;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	height: 50vh;
`;
