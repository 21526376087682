/*
 * File: index.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on April 21, 2024 at 10:27 PM
 * Copyright © 2024 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 12:29 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const startBuildSession = ({ sessionId }) => api.post('/cityapp/build/start', { sessionId });

export const updateBuildSession = ({
	sessionId, municipalityName, state, setupComplete = false
}) => api.post('/cityapp/build/update', {
	sessionId, municipalityName, state, setupComplete
});

export const completeBuildSession = ({ sessionId, representativeName, representativeEmail }) => api.post('/cityapp/build/complete', { sessionId, representativeName, representativeEmail });

export const startSuperheroBuildSession = ({ sessionId }) => api.post('/superhero/build/start', { sessionId });

export const updateSuperheroBuildSession = ({
	sessionId, superheroName, superheroPower, creatorName, setupComplete = false
}) => api.post('/superhero/build/update', {
	sessionId, superheroName, superheroPower, creatorName, setupComplete
});

export const completeSuperheroBuildSession = ({ sessionId }) => api.post('/superhero/build/complete', { sessionId });
