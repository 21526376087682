/*
 * File: fontawesome.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:32 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: April 23, 2024 at 10:14 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { library } from '@fortawesome/fontawesome-svg-core';

// Icons
import {
	faBell,
	faHomeAlt,
	faCalendar,
	faMap,
	faUser,
	faEllipsisH,
	faMegaphone,
	faArrowRight,
	faArrowLeft,
	faTheaterMasks,
	faUtensils,
	faMusic,
	faTree,
	faVolleyballBall,
	faLandmark,
	faPartyHorn,
	faArrowUpRightFromSquare,
	faArrowRightFromBracket,
	faMapPin,
	faLocationDot,
	faBookmark,
	faClock,
	faDollarSign,
	faBasketballHoop,
	faXmark,
	faCheckCircle,
	faCircleXmark,
	faStar,
	faGlobe,
	faPhone
} from '@fortawesome/pro-light-svg-icons';
import {
	faBookmark as faBookmarkSolid,
	faComment,
	faPaperPlaneTop,
	faStar as faStarSolid,
	faCheck as faCheckSolid
} from '@fortawesome/pro-solid-svg-icons';


/**
 * Init
 */

export function loadIcons() {

	// Add icons to library
	library.add(
		faBell,
		faHomeAlt,
		faCalendar,
		faMap,
		faUser,
		faEllipsisH,
		faMegaphone,
		faArrowRight,
		faArrowLeft,
		faTheaterMasks,
		faUtensils,
		faMusic,
		faTree,
		faVolleyballBall,
		faLandmark,
		faPartyHorn,
		faArrowUpRightFromSquare,
		faArrowRightFromBracket,
		faMapPin,
		faLocationDot,
		faBookmark,
		faClock,
		faDollarSign,
		faBookmarkSolid,
		faBasketballHoop,
		faXmark,
		faCheckCircle,
		faCircleXmark,
		faComment,
		faPaperPlaneTop,
		faStar,
		faStarSolid,
		faGlobe,
		faPhone,
		faCheckSolid
	);
}
