/*
 * File: EventsTab.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 6, 2023 at 11:59 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:22 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Padding } from '../../../../../../components';

// Styles
import { ColorValueCreator } from '../../../../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

export const HeaderBanner = styled.div`
	width: 100%;
	height: 173px;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: cover;
	flex-shrink: 0;

	& > div {
		align-items: center;
		justify-content: center;
		height: 100%;

		h1 {
			text-align: left;
			color: ${({ theme }) => theme.brandPrimaryBase};
		}
	}
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
`;

export const CategoriesSection = styled.div`
	padding: 20px 0px 30px;

	h4 {
		color: ${({ theme }) => theme.brandPrimaryBase};
		text-align: left;
	}
`;

export const EventsSection = styled.div`
	padding: 20px 0px 30px;
	background-color: ${ColorValueCreator().neutralBackground};
	flex-grow: 1;

	h4 {
		color: ${({ theme }) => theme.brandPrimaryBase};
		text-align: left;
	}
`;

export const CategoryGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin: 14px 0px 0px;
	gap: 12px;
`;

export const Category = styled.div`
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	justify-content: center;
	height: 120px;
	cursor: pointer;

	* {
		color: ${ColorValueCreator().white};
	}
	svg {
		font-size: 2.4rem;
	}
`;

export const EventsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 14px 0px 0px;
	gap: 10px;
`;
