/*
 * File: NotificationsStep.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 9, 2023 at 9:09 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:20 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Padding } from '../../../../../../components';

// Styles
import { ColorValueCreator } from '../../../../../../styles/colors';


/**
 * Styles
 */

export const StepContainer = styled.div`
	background-color: ${ColorValueCreator().neutralBackground};
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	width: 100%;
	overflow: scroll;
`;

export const LogoContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
`;

export const Logo = styled.img`
	width: 86px;
`;

export const ProgressBarContainer = styled.div`
	margin: 30px 0px 0px;
	width: 100%;
`;

export const ProgressBar = styled.div`
	height: 10px;
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 3px;
`;

export const ProgressLeft = styled.div`
	background-color: ${ColorValueCreator().statusInfoLighter};
	flex: 1 1 0px;
`;

export const ProgressRight = styled.div`
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	flex: 1 1 0px;
`;

export const ContentContainer = styled.div`
	flex-grow: 1;
	position: relative;
	z-index: 2;
	align-items: start;
	justify-content: center;
	display: flex;
`;

export const Content = styled.div`
	width: 100%;
	margin-top: 40px;

	h2 {
		text-align: left;
	}
`;

export const NotificationsGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	margin: 30px 0px 0px;
	gap: 12px;
`;

export const NotificationRow = styled.div`
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	display: flex;
	flex-direction: row;
	gap: 14px;
	align-items: center;
	justify-content: start;
	height: 73px;
	padding: 0px 30px;

	* {
		color: ${ColorValueCreator().white};
	}
	svg {
		font-size: 2.4rem;
	}
`;

export const NotificationSelector = styled.div`
	background-color: ${({ $isActive, theme }) => ($isActive ? theme.brandSecondaryBase : ColorValueCreator().white)};
	width: 22px;
	height: 22px;
	border-radius: 50%;
	cursor: pointer;
`;

export const BottomActionContainer = styled.div`
	z-index: 2;
	margin: 0px 0px 30px;

	& > div {
		align-items: end;
	}
	button {
		margin: 0px 0px 0px;
		padding-right: 0px;
		padding-left: 0px;

		h4 {
			text-decoration: underline;
			text-decoration-color: ${({ theme }) => theme.brandSecondaryBase};
			color: ${({ theme }) => theme.brandPrimaryBase};
		}
		svg {
			font-size: 1.6rem;
			color: ${({ theme }) => theme.brandPrimaryBase};
		}
	}
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
	align-items: start;
`;
