/*
 * File: Map.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on April 18, 2022 at 11:27 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: November 9, 2023 at 11:09 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';

// Icons
import {
	faStarSharp as featuredIcon,
} from '@fortawesome/pro-solid-svg-icons';

// Constants
import { UI_MODE_OPTIONS } from '../../../Constants';

// Styles
import * as S from './Map.styles';


/**
 * Configuration
 */

mapboxgl.accessToken = process.env.MAPBOX_PUBLIC_TOKEN;


/**
 * State
 */

let mapMarkers = [];


/**
 * Component
 */

export const Map = ({
	className, locationId, center, flyTo, pointsOfInterest, zoom
}) => {

	// Create map references
	const mapContainer = useRef(null);
	const map = useRef(null);

	// Set state
	const [longitude, setLongitude] = useState(center?.longitude || 0);
	const [latitude, setLatitude] = useState(center?.latitude || 0);
	const [centerLongitude, setCenterLongitude] = useState(center?.longitude || 0);
	const [centerLatitude, setCenterLatitude] = useState(center?.latitude || 0);
	const [zoomLevel, setZoomLevel] = useState(zoom);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle rendering points of interest
	const renderPointsOfInterest = () => {

		// Remove all markers
		mapMarkers.forEach((marker) => marker.remove());

		// Initialize map markers
		mapMarkers = [];

		// Create markers for points of interest
		pointsOfInterest.forEach((point, i) => {

			// Get point parameters
			const {
				id, coordinates, type, index, onClick
			} = point;

			// Create marker
			if (type === 'featured') {

				// Create featured icon
				const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
				const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
				svgElem.setAttributeNS(null, 'viewBox', `0 0 ${featuredIcon.icon[0]} ${featuredIcon.icon[1]}`);
				path.setAttributeNS(null, 'd', featuredIcon.icon[4]);
				svgElem.appendChild(path);

				// Create marker element
				const el = document.createElement('div');
				el.className = 'marker';
				el.appendChild(svgElem);
				const marker = new mapboxgl.Marker(el).setLngLat([
					coordinates.longitude,
					coordinates.latitude
				]).addTo(map.current);

				// Add marker to collection
				mapMarkers.push(marker);

				// Set on click action
				if (onClick) {
					el.addEventListener('click', () => {
						onClick(id);
					});
				} else {
					el.addEventListener('click', () => {
						map.current.flyTo({
							center: [
								coordinates.longitude,
								coordinates.latitude
							],
							zoom: zoomLevel
						});
					});
				}
			} else if (type === 'numbered') {

				// Create numbered label
				const label = document.createElement('p');
				label.innerHTML = `${index || i + 1}`;

				// Create marker element
				const el = document.createElement('div');
				el.className = 'marker';
				el.appendChild(label);
				const marker = new mapboxgl.Marker(el).setLngLat([
					coordinates.longitude,
					coordinates.latitude
				]).addTo(map.current);

				// Add marker to collection
				mapMarkers.push(marker);

				// Set on click action
				if (onClick) {
					el.addEventListener('click', () => {
						onClick(id);
					});
				} else {
					el.addEventListener('click', () => {
						map.current.flyTo({
							center: [
								coordinates.longitude,
								coordinates.latitude
							],
							zoom: zoomLevel
						});
					});
				}
			}
		});
	};

	// Handle actions on component render
	useEffect(() => {

		// Only initialize map once
		if (map.current) return;

		// Create new Mapbox map
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: uiMode.mode === UI_MODE_OPTIONS.DARK ? process.env.MAPBOX_DARK_STYLE : process.env.MAPBOX_LIGHT_STYLE,
			center: [longitude, latitude],
			zoom: zoomLevel,
			attributionControl: false
		});

		// Listen for map move events
		map.current.on('move', () => {
			setLongitude(map.current.getCenter().lng.toFixed(4));
			setLatitude(map.current.getCenter().lat.toFixed(4));
			setZoomLevel(map.current.getZoom().toFixed(2));
		});

		// Update coordinates if necessary
		if (locationId != null) {
			renderPointsOfInterest();
		}
	});

	// Handle actions on map parameter changes
	useEffect(() => {

		// Wait for map to initialize
		if (!map.current) return;

		// Set map parameters
		if (center != null) {
			setLongitude(centerLatitude);
			setLatitude(centerLongitude);
			map.current.setCenter([
				centerLongitude,
				centerLatitude
			]);
		}
		map.current.setZoom(zoom);

		// Render points of interest
		renderPointsOfInterest();

	}, [centerLongitude, centerLatitude, locationId]);

	// Handle actions on center updated
	useEffect(() => {

		// Set new center parameters
		setCenterLongitude(center?.longitude || 0);
		setCenterLatitude(center?.latitude || 0);

	}, [center]);

	// Handle actions on fly to point updated
	useEffect(() => {

		// Wait for map to initialize
		if (!map.current || !flyTo) return;

		// Fly to point
		map.current.flyTo({
			center: [
				flyTo.longitude,
				flyTo.latitude
			],
			zoom: zoomLevel,
			duration: 3000
		});
	}, [flyTo]);

	// Handle actions on UI mode updated
	useEffect(() => {

		// Wait for map to initialize
		if (!map.current) return;

		// Update theme
		map.current.setStyle(uiMode.mode === UI_MODE_OPTIONS.DARK ? process.env.MAPBOX_DARK_STYLE : process.env.MAPBOX_LIGHT_STYLE);

	}, [uiMode]);

	// Return component
	return <S.Map ref={mapContainer} className={className} />;
};


/**
 * Configuration
 */

Map.displayName = 'Map';
Map.propTypes = {
	className: PropTypes.string,
	locationId: PropTypes.string,
	center: PropTypes.shape({
		latitude: PropTypes.number,
		longitude: PropTypes.number
	}),
	flyTo: PropTypes.shape({
		latitude: PropTypes.number,
		longitude: PropTypes.number
	}),
	zoom: PropTypes.number,
	pointsOfInterest: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		coordinates: PropTypes.shape({
			latitude: PropTypes.number,
			longitude: PropTypes.number,
		}),
		type: PropTypes.oneOf(['featured', 'numbered']),
		index: PropTypes.number,
		onClick: PropTypes.func
	}))
};
Map.defaultProps = {
	className: null,
	locationId: null,
	center: {
		latitude: 35.776611,
		longitude: -78.645416,
	},
	flyTo: null,
	zoom: 15,
	pointsOfInterest: []
};
