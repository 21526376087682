/*
 * File: Superhero.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on June 13, 2024 at 12:53 PM
 * Copyright © 2024 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 13, 2024 at 3:07 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValueCreator } from '../../../../styles/colors';

// Colors
const ColorValues = ColorValueCreator();


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	position: relative;
`;

export const LoadingContainer = styled.div``;

export const PrimarySuperhero = styled.div`
	max-width: 700px;
	text-align: center;
	width: 84%;
	position: relative;
	z-index: 2;

	* {
		color: ${ColorValues.white};
	}
	h3 {
		margin-top: 15px;
		color: ${({ theme }) => theme.primaryText};
		background-color: #FFBE0B;
		box-shadow: ${ColorValues.elementDarkerShadow};
		border: 4px solid ${({ theme }) => theme.primaryText};
		padding: 10px;
		text-transform: uppercase;
		margin-top: -20px;
		z-index: 2;
		position: relative;

		span {
			text-decoration: underline;
			text-decoration-color: white;
			color: ${({ theme }) => theme.primaryText};
		}
	}
	p {
		margin-top: 10px;
		background-color: ${({ theme }) => theme.primaryBackground};
		color: ${({ theme }) => theme.primaryText};
		padding: 20px;
		box-shadow: ${ColorValues.elementDarkerShadow};
		border: 4px solid ${({ theme }) => theme.primaryText};
		z-index: 3;
		position: relative;
		left: 30px;
		margin-top: -10px;
	}
`;

export const SuperheroImage = styled.img`
	width: 100%;
	max-width: 450px;
	box-shadow: ${ColorValues.elementDarkShadow};
	border: 6px solid white;
`;

export const SuperheroCollection = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	width: 100%;
	padding: 50px;
	gap: 60px;
	z-index: 1;
	opacity: 0.4;
`;

export const Super = styled.div`
	max-width: 170px;
	text-align: center;

	p {
		margin-top: 10px;
		background-color: ${({ theme }) => theme.primaryBackground};
		color: ${({ theme }) => theme.primaryText};
		padding: 8px;
		box-shadow: ${ColorValues.elementDarkerShadow};
		border: 4px solid ${({ theme }) => theme.primaryText};
		z-index: 3;
		position: relative;
		left: 30px;
		margin-top: -20px;
		text-transform: uppercase;
	}
`;
