/*
 * File: cityApp.slice.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on April 22, 2024 at 12:31 PM
 * Copyright © 2024 Lithios, LLC. All rights reserved.
 *
 * Last Modified: May 2, 2024 at 1:29 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Utilities
import { getStorageItem, removeStorageItem, storeItem } from '../../../utilities/storage';

// Services
import { getCityApp } from '../../../services/cityapp';

// Constants
import { STORAGE_KEYS } from '../../../../Constants';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: getStorageItem(STORAGE_KEYS.CITYAPP) || {}
};


/**
 * Functions
 */

export const fetchCityApp = createAsyncThunk('cityApp/fetchCityApp', async ({ accessId, overwrite = true }) => {

	// Get stored data
	const storedData = getStorageItem(STORAGE_KEYS.CITYAPP);

	// Check if should fetch new data
	if (overwrite || storedData == null) {

		// Fetch current city app
		const { data: { cityApp } } = await getCityApp({ accessId });

		// Return city app
		return cityApp;
	}

	// Return stored data
	return storedData;
});


/**
 * Slices
 */

export const cityAppSlice = createSlice({
	name: 'cityApp',
	initialState,
	reducers: {
		updateCityApp: (state, action) => {

			// Get city app
			const cityApp = action.payload;

			// Set state
			state.value = {
				...cityApp,
			};

			// Update asset storage
			storeItem(STORAGE_KEYS.CITYAPP, cityApp);
		},
		clearCityApp: (state) => {

			// Clear state
			state.value = {};
			state.status = 'idle';

			// Update asset storage
			removeStorageItem(STORAGE_KEYS.CITYAPP);
		},
	},
	extraReducers: {
		[fetchCityApp.pending]: (state) => {
			state.status = 'loading';
		},
		[fetchCityApp.fulfilled]: (state, action) => {

			// Set status
			state.status = 'succeeded';

			// Set value
			state.value = {
				...action.payload,
			};

			// Update asset storage
			storeItem(STORAGE_KEYS.CITYAPP, action.payload);
		},
		[fetchCityApp.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
	},
});


/**
 * Exports
 */

export const { updateCityApp, clearCityApp } = cityAppSlice.actions;

export default cityAppSlice.reducer;
