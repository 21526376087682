/*
 * File: HeroCreationStep.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 13, 2024 at 12:40 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import validator from 'validator';

// Utilities
import { toastError } from '../../../../../utilities/toaster';

// Services
import { updateSuperheroBuildSession } from '../../../../../services/buildsession';

// Components
import {
	Typography,
	TextInput,
} from '../../../../../components';

// Styles
import * as S from './HeroCreationStep.styles';


/**
 * Component
 */

export const HeroCreationStep = forwardRef(({
	updateStep, className, sessionId, updateSuperheroState
}, ref) => {

	// Create state handlers
	const [superheroNameError, setSuperheroNameError] = useState(null);
	const [superpowerError, setSuperpowerError] = useState(null);
	const [creatorNameError, setCreatorNameError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle continue function
	const handleContinue = async () => {

		// Get parameters
		const { superheroName, superpower, creatorName } = inputValues;

		// Validate parameters
		if (!superheroName || validator.isEmpty(superheroName, { ignore_whitespace: true })) {
			setSuperheroNameError('Please enter the name of your Superhero');
			return;
		}
		if (!superpower || validator.isEmpty(superpower, { ignore_whitespace: true })) {
			setSuperheroNameError('Please enter your hero\'s Superpower');
			return;
		}
		if (!creatorName || validator.isEmpty(creatorName, { ignore_whitespace: true })) {
			setSuperheroNameError('Please enter your name');
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Update build session
		updateSuperheroBuildSession({
			superheroName, superheroPower: superpower, creatorName, sessionId
		}).then(() => {

			// Set loading state
			setIsLoading(false);

			// Update state
			updateSuperheroState({ superheroName, superheroPower: superpower, creatorName });

			// Move to next step
			updateStep(2);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setSuperheroNameError(null);
			setSuperpowerError(null);
			setCreatorNameError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, null, response?.data?.message);
			} else {
				toastError(uiMode, null, 'Whoops. We\'re having trouble creating your Superhero! Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Logo */}
			<S.LogoContainer>
				<S.Logo src={`${process.env.CDN_URL}/public/assets/lithios-logo.png`} />
			</S.LogoContainer>

			{/* Form */}
			<S.Form>

				{/* Content */}
				<Typography tag="h2" weight="bold">
					Create a brand new
					{' '}
					<span> Superhero</span>
					{' '}
					to save the day!
				</Typography>

				{/* Inputs */}
				<TextInput
					label="What is your Superhero called?"
					name="superheroName"
					type="text"
					error={superheroNameError}
					placeholder="e.g. Magnet Man"
					value={inputValues.superheroName || ''}
					containerClassName="formInput"
					onFocus={() => { setSuperheroNameError(null); }}
					onKeyUp={() => { setSuperheroNameError(null); }}
					onBlur={() => { setSuperheroNameError(null); }}
					onChange={handleOnChange}
				/>
				<TextInput
					label="What is your hero's Superpower?"
					name="superpower"
					type="text"
					error={superpowerError}
					placeholder="e.g. Invisibility"
					value={inputValues.superpower || ''}
					containerClassName="formInput"
					onFocus={() => { setSuperpowerError(null); }}
					onKeyUp={() => { setSuperpowerError(null); }}
					onBlur={() => { setSuperpowerError(null); }}
					onChange={handleOnChange}
				/>
				<TextInput
					label="What is your name?"
					name="creatorName"
					type="text"
					error={creatorNameError}
					placeholder="e.g. Tony"
					value={inputValues.creatorName || ''}
					containerClassName="formInput"
					onFocus={() => { setCreatorNameError(null); }}
					onKeyUp={() => { setCreatorNameError(null); }}
					onBlur={() => { setCreatorNameError(null); }}
					onChange={handleOnChange}
				/>

				{/* Continue Action */}
				<S.ActionButton disabled={isLoading} isLoading={isLoading} onClick={handleContinue} size="large" className="floatShadow">Create my hero!</S.ActionButton>

			</S.Form>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

HeroCreationStep.displayName = 'HeroCreationStep';
HeroCreationStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string,
	sessionId: PropTypes.string,
	updateSuperheroState: PropTypes.func
};
HeroCreationStep.defaultProps = {
	className: null,
	sessionId: null,
	updateSuperheroState: null
};
