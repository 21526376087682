/*
 * File: Superhero.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on June 13, 2024 at 12:53 PM
 * Copyright © 2024 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 13, 2024 at 4:30 PM
 * Modified By: Brendan Michaelsen
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Lottie from 'react-lottie-player';

// Utilities
import { createStateLocale } from '../../../../utilities/locale';

// Services
import { getSuperhero, getSuperheroes } from '../../../../services/superhero';

// Components
import {
	ErrorComponent,
	Meta,
	Typography
} from '../../../../components';

// Animation
import powAnimationData from '../../../../assets/animations/pow.json';

// Constants
import { CITY_APP_ROLES } from '../../../../../Constants';

// Styles
import * as S from './Superhero.styles';


/**
 * Component
 */

const Superhero = ({ meta, locale }) => {

	// Get component parameters
	const { accessId } = useParams();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();
	const role = searchParams.get('role');

	// Use hooks
	const navigate = useNavigate();

	// Create state handlers
	const [pageStatus, setPageStatus] = useState('idle');
	const [currentSuperhero, setCurrentSuperhero] = useState(null);
	const [superheroes, setSuperheroes] = useState([]);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Create reference for components
	const isMounted = useRef(true);

	// Handle fetch data for page
	const fetchDataForPage = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Fetch data
			const { data: { superhero } } = await getSuperhero({ accessId });
			const { data: { superheroes: superheroesArray } } = await getSuperheroes();

			// Check if component is mounted
			if (isMounted.current) {

				// Update page state
				setCurrentSuperhero(superhero);
				setSuperheroes(superheroesArray.filter((s) => s.id !== superhero.id));

				// Update page status
				setPageStatus('success');
			}

		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle actions on page status change
	useEffect(() => {

		// Set page status if necessary
		if (pageStatus === 'idle') {

			// Fetch data state for page
			fetchDataForPage();

		}
	}, [pageStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Start timer if public role
		if (role === CITY_APP_ROLES.PUBLIC) {
			setTimeout(() => {

				// Navigate back to welcome screen
				navigate('/welcome');

			}, 1 * 60 * 1000);
		}

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render content
	const renderContent = () => {
		if (pageStatus === 'idle' || pageStatus === 'loading') {
			return (
				<S.LoadingContainer>
					<Lottie
						loop
						play
						animationData={powAnimationData}
						style={{
							width: '100%',
							height: '100%'
						}}
					/>
				</S.LoadingContainer>
			);
		} if (pageStatus === 'error') {
			return <ErrorComponent />;
		}
		return (
			<>
				{/* Primary Superhero */}
				<S.PrimarySuperhero>

					<S.SuperheroImage
						src={currentSuperhero.superheroImage}
						alt={currentSuperhero.superheroName}
						title={currentSuperhero.superheroName}
					/>
					<Typography tag="h3" fontStyle="italic" weight="bold">
						Thanks for creating me,
						{' '}
						<span>{currentSuperhero.creatorName}</span>
						! I am
						{' '}
						<span>{currentSuperhero.superheroName}</span>
						.
					</Typography>
					<Typography
						tag="p"
						fontStyle="italic"
						weight="medium"
					>
						{currentSuperhero.superheroDescription}
					</Typography>

				</S.PrimarySuperhero>

				{/* Superhero Collection */}
				{role === CITY_APP_ROLES.PUBLIC && (
					<S.SuperheroCollection>
						{superheroes.map((superObj) => (
							<S.Super key={superObj.id}>
								<S.SuperheroImage
									src={superObj.superheroImage}
									alt={superObj.superheroName}
									title={superObj.superheroName}
								/>
								<Typography
									tag="p"
									fontStyle="italic"
									weight="semibold"
									variation="3"
								>
									Created by
									{' '}
									{superObj.creatorName}
								</Typography>
							</S.Super>
						))}
					</S.SuperheroCollection>
				)}

			</>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<S.Wrapper>{renderContent()}</S.Wrapper>
		</>
	);
};


/**
 * Configuration
 */

Superhero.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Superhero.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Superhero;
