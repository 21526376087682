/*
 * File: LocaleLink.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 9, 2022 at 9:36 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: November 6, 2023 at 10:43 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';
import { Link as ReactLink } from 'react-router-dom';


/**
 * Styles
 */

export const LocaleLink = styled(ReactLink)`
	display: inline;
`;

export const BaseLink = styled.a`
	display: inline;
`;
