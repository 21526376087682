/*
 * File: index.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on December 5, 2023 at 11:50 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: April 21, 2024 at 9:02 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const sendChatMessage = ({ text, cityAppId }) => api.post('/chat', { text, cityAppId });
