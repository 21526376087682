/*
 * File: superhero.slice.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on June 11, 2024 at 12:42 PM
 * Copyright © 2024 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 12:45 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Utilities
import { getStorageItem, removeStorageItem, storeItem } from '../../../utilities/storage';

// Services
import { getSuperhero } from '../../../services/superhero';

// Constants
import { STORAGE_KEYS } from '../../../../Constants';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: getStorageItem(STORAGE_KEYS.SUPERHERO) || {}
};


/**
 * Functions
 */

export const fetchSuperhero = createAsyncThunk('superhero/fetchSuperhero', async ({ accessId, overwrite = true }) => {

	// Get stored data
	const storedData = getStorageItem(STORAGE_KEYS.SUPERHERO);

	// Check if should fetch new data
	if (overwrite || storedData == null) {

		// Fetch current superhero
		const { data: { superhero } } = await getSuperhero({ accessId });

		// Return superhero
		return superhero;
	}

	// Return stored data
	return storedData;
});


/**
 * Slices
 */

export const superheroSlice = createSlice({
	name: 'superhero',
	initialState,
	reducers: {
		updateSuperhero: (state, action) => {

			// Get superhero
			const superhero = action.payload;

			// Set state
			state.value = {
				...superhero,
			};

			// Update asset storage
			storeItem(STORAGE_KEYS.SUPERHERO, superhero);
		},
		clearSuperhero: (state) => {

			// Clear state
			state.value = {};
			state.status = 'idle';

			// Update asset storage
			removeStorageItem(STORAGE_KEYS.SUPERHERO);
		},
	},
	extraReducers: {
		[fetchSuperhero.pending]: (state) => {
			state.status = 'loading';
		},
		[fetchSuperhero.fulfilled]: (state, action) => {

			// Set status
			state.status = 'succeeded';

			// Set value
			state.value = {
				...action.payload,
			};

			// Update asset storage
			storeItem(STORAGE_KEYS.SUPERHERO, action.payload);
		},
		[fetchSuperhero.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
	},
});


/**
 * Exports
 */

export const { updateSuperhero, clearSuperhero } = superheroSlice.actions;

export default superheroSlice.reducer;
