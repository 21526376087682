/*
 * File: Login.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:21 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import validator from 'validator';

// Utilities
import { createStateLocale } from '../../../../../utilities/locale';

// Components
import {
	AppNavigation,
	Button,
	LocaleLink,
	Meta,
	TextInput,
	Typography
} from '../../../../../components';

// Content
import BlueWaveDecoration from '../../../../../assets/images/BlueWaveDecoration.svg';

// Styles
import * as S from './Login.styles';


/**
 * Component
 */

const Login = ({ meta, locale }) => {

	// Create state handlers
	const [usernameError, setUsernameError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get actions from hooks
	const navigate = useNavigate();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Handle log in function
	const handleLogin = async () => {

		// Get parameters
		const { username, password } = inputValues;

		// Validate parameters
		if (!username || validator.isEmpty(username, { ignore_whitespace: true })) {
			setUsernameError({ message: 'Please enter your email or username' });
			return;
		}
		if (!password || validator.isEmpty(password, { ignore_whitespace: true })) {
			setPasswordError({ message: 'Please enter your password' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Log in user
		setTimeout(() => {

			// Set loading state
			setIsLoading(false);

			// Navigate to the dashboard
			navigate(`${stateLocale.localePath}/app/${currentCityApp?.accessId}${searchParams.toString() ? `?${searchParams.toString()}` : ''}#home`);

		}, 3000);
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Render component function
	const renderComponent = () => (
		<>
			{/* Logo Container */}
			<S.LogoContainer>
				<S.Logo
					alt={`${currentCityApp?.municipalityName} Logo`}
					title={`${currentCityApp?.municipalityName} Logo`}
					src={currentCityApp?.logoImage}
				/>
			</S.LogoContainer>

			{/* Login Form */}
			<S.FormContainer>
				<S.ContentPadding left right>
					<Typography tag="h2" weight="bold">Login</Typography>
					<TextInput
						label="Email or Username"
						name="username"
						type="text"
						containerClassName="fullWidthInput"
						error={usernameError}
						value={inputValues.username || ''}
						autoComplete="email"
						onFocus={() => { setUsernameError(null); }}
						onKeyUp={() => { setUsernameError(null); }}
						onBlur={() => { setUsernameError(null); }}
						onChange={handleOnChange}
						smartField
					/>
					<TextInput
						label="Password"
						type="password"
						name="password"
						containerClassName="fullWidthInput"
						error={passwordError}
						value={inputValues.password || ''}
						autoComplete="current-password"
						onFocus={() => { setPasswordError(null); }}
						onKeyUp={() => { setPasswordError(null); }}
						onBlur={() => { setPasswordError(null); }}
						onChange={handleOnChange}
						smartField
					/>
					<Button variant="text"><Typography variation="button-small" weight="semibold">Reset Password</Typography></Button>
				</S.ContentPadding>
			</S.FormContainer>

			{/* Bottom Container */}
			<S.BottomActionContainer>
				<S.ContentPadding left right>
					<Button
						variant="solid"
						size="large"
						disabled={isLoading}
						isLoading={isLoading}
						onClick={() => { handleLogin(); }}
					>
						Login
					</Button>
					<Typography tag="p" variation="2">
						Don&apos;t Have An Account?
						{' '}
						<LocaleLink to={`/app/${currentCityApp?.accessId}/onboarding${searchParams.toString() ? `?${searchParams.toString()}` : ''}`}>
							<Typography weight="semibold">Sign Up</Typography>
						</LocaleLink>
					</Typography>
				</S.ContentPadding>
			</S.BottomActionContainer>

			{/* Decoration */}
			<S.Decoration
				$color={currentCityApp?.primaryColor}
				dangerouslySetInnerHTML={{ __html: BlueWaveDecoration }}
			/>
		</>
	);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{ app: currentCityApp || { municipalityName: 'MyCity' } }} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>{renderComponent()}</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Login.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Login.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Login;
