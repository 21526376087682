/*
 * File: MapTab.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 6, 2023 at 11:59 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:21 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValueCreator } from '../../../../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	position: relative;
`;

export const MapContainer = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;

export const DetailOverlay = styled.div`
	position: absolute;
	width: 100%;
	background-color: white;
	z-index: 99;
	bottom: 0px;
	overflow: scroll;

	height: ${({ $height }) => $height};
`;

export const Grabber = styled.div`
	width: 20px;
	height: 3px;
	background-color: ${ColorValueCreator().neutralForm};
	margin: 10px auto 0px;
	border-radius: 2px;
`;
