/*
 * File: locale.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:39 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: November 6, 2023 at 10:43 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Constants
import { AVAILABLE_LOCALES } from '../../Constants';


/**
 * Utilities
 */

export const createLocaleVariants = (route) => {

	// Get parameters
	const { path } = route;
	const routes = [route];

	// Create route for each locale
	AVAILABLE_LOCALES.forEach((locale) => {
		routes.push({
			...route,
			path: `/${locale}${path}`,
			localeId: locale,
			isAlternative: true
		});
	});

	// Return routes
	return routes;
};

export const createStateLocale = (clientLocale, serverLocale) => {
	const stateLocale = serverLocale && Object.keys(serverLocale).length > 0 ? serverLocale : clientLocale;
	return clientLocale?.isSet ? clientLocale : stateLocale;
};
