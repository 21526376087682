/*
 * File: RepresentativeStep.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:23 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Button } from '../../../../../components/Button';

// Constants
import { mobileBreakpoint, smallBreakpoint } from '../../../../../styles/constants';


/**
 * Styles
 */

export const StepContainer = styled.div`
	width: 100%;
`;

export const Form = styled.form`
	width: 100%;
	max-width: 480px;
	margin: 0px auto;

	@media only screen {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	@media only screen and (min-width: ${smallBreakpoint}em) {
		padding-top: 100px;
		padding-bottom: 50px;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding-top: 140px;
		padding-bottom: 50px;
	}
	h2 {
		text-align: left;
		margin: 0px 0px 50px;

		span {
			text-decoration: underline;
			text-decoration-color: ${({ theme }) => theme.brandSecondaryBase};
		}
	}
	p {
		text-align: left;
   		margin-bottom: 20px;
	}
	button {
		width: 100%;
	}
`;

export const LogoContainer = styled.div`
	margin: 20px 0px 0px;
	text-align: center;
`;

export const Logo = styled.img`
	height: 40px;
`;

export const ActionButton = styled(Button)`
	margin: 40px auto 0px;
`;
