/*
 * File: LocationDetail.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 19, 2022 at 12:17 AM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 5, 2023 at 7:49 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Typography } from '../Typography';
import { IconButton } from '../IconButton';

// Styles
import * as S from './LocationDetail.styles';


/**
 * Component
 */

export const LocationDetail = ({ location }) => {

	// Create state handlers
	const [isFavorited, setIsFavorited] = useState(false);

	// Format review count
	const formatReviewCount = (n) => {
		if (n < 1e3) return n.toFixed(0);
		if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}k`;
		if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}m`;
		if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}b`;
		if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}t`;
		return null;
	};

	// Return component
	return (
		<S.Wrapper>
			<S.ContentPadding left right>

				{/* Overview */}
				<S.EventDetailSection>

					{/* Location Title */}
					<S.TitleRow>
						<Typography tag="h4" weight="bold">{location.title}</Typography>
						<IconButton
							icon={isFavorited ? ['fas', 'bookmark'] : ['fal', 'bookmark']}
							size={1.1}
							onClick={() => { setIsFavorited(!isFavorited); }}
						/>
					</S.TitleRow>

					{/* Review Summary */}
					<S.ReviewSummary>
						<Typography tag="p" weight="light">{location.reviewAverage}</Typography>
						<S.StarsContainer>
							<FontAwesomeIcon icon={[location.reviewAverage >= 1 ? 'fas' : 'fal', 'star']} />
							<FontAwesomeIcon icon={[location.reviewAverage >= 2 ? 'fas' : 'fal', 'star']} />
							<FontAwesomeIcon icon={[location.reviewAverage >= 3 ? 'fas' : 'fal', 'star']} />
							<FontAwesomeIcon icon={[location.reviewAverage >= 4 ? 'fas' : 'fal', 'star']} />
							<FontAwesomeIcon icon={[location.reviewAverage >= 5 ? 'fas' : 'fal', 'star']} />
						</S.StarsContainer>
						<Typography tag="p" variation="3" weight="light" className="secondary">
							(
							{formatReviewCount(location.reviewCount)}
							)
						</Typography>
					</S.ReviewSummary>

					{/* Images */}
					<S.ImageSection $columnNum={location.images.length}>
						{location.images.map((image) => <S.Image $image={image} key={image} />)}
					</S.ImageSection>

				</S.EventDetailSection>

				{/* Overview */}
				<S.EventDetailSection>

					{/* Section Title */}
					<S.EventDetailTitle>
						<Typography tag="p" variation="3" weight="regular">Overview</Typography>
					</S.EventDetailTitle>

					{/* Content */}
					<S.TitleRow>
						<Typography tag="h4" weight="bold">About</Typography>
					</S.TitleRow>

					{/* Description */}
					<S.Description>
						<Typography tag="p" weight="light" variation="2">
							{location.description}
						</Typography>
					</S.Description>

				</S.EventDetailSection>

				{/* Location Details */}
				<S.EventDetailSection>

					{/* Content */}
					<S.TitleRow>
						<Typography tag="h4" weight="bold">Location Details</Typography>
					</S.TitleRow>

					{/* Details */}
					<S.Details>
						{location.address && (
							<S.DetailRow>
								<FontAwesomeIcon icon={['fal', 'location-dot']} />
								<Typography tag="p" variation="2" weight="light">{location.address}</Typography>
							</S.DetailRow>
						)}
						{location.website && (
							<S.DetailRow>
								<FontAwesomeIcon icon={['fal', 'globe']} />
								<Typography tag="p" variation="2" weight="light">{location.website}</Typography>
							</S.DetailRow>
						)}
						{location.phone && (
							<S.DetailRow>
								<FontAwesomeIcon icon={['fal', 'phone']} />
								<Typography tag="p" variation="2" weight="light">{location.phone}</Typography>
							</S.DetailRow>
						)}
					</S.Details>
				</S.EventDetailSection>

				{/* Reviews */}
				<S.EventDetailSection>

					{/* Content */}
					<S.TitleRow>
						<Typography tag="h4" weight="bold">Reviews</Typography>
						<Typography tag="p" variation="3" weight="light" className="secondary">
							(
							{formatReviewCount(location.reviewCount)}
							)
						</Typography>
					</S.TitleRow>

					{/* Reviews */}
					<S.ReviewsContainer>
						{location.reviews.map((review) => (
							<S.ReviewCard key={review.id}>
								<S.StarsContainer>
									<FontAwesomeIcon icon={[review.stars >= 1 ? 'fas' : 'fal', 'star']} />
									<FontAwesomeIcon icon={[review.stars >= 2 ? 'fas' : 'fal', 'star']} />
									<FontAwesomeIcon icon={[review.stars >= 3 ? 'fas' : 'fal', 'star']} />
									<FontAwesomeIcon icon={[review.stars >= 4 ? 'fas' : 'fal', 'star']} />
									<FontAwesomeIcon icon={[review.stars >= 5 ? 'fas' : 'fal', 'star']} />
								</S.StarsContainer>
								<Typography tag="p" variation="2" weight="light">
									&ldquo;
									{review.quote}
									&rdquo;
								</Typography>
								<Typography tag="p" variation="3" className="secondary" weight="light">{review.date}</Typography>
							</S.ReviewCard>
						))}
					</S.ReviewsContainer>

					{/* Footer */}
					<S.Footer />

				</S.EventDetailSection>
			</S.ContentPadding>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

LocationDetail.displayName = 'LocationDetail';
LocationDetail.propTypes = {
	location: PropTypes.shape()
};
LocationDetail.defaultProps = {
	location: {}
};
