/*
 * File: Select.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on June 20, 2022 at 1:18 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: April 25, 2024 at 3:22 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 999;

	& > label {
		cursor: pointer;
		text-align: left;
	 	color: ${({ theme, error }) => (error ? theme.statusDangerBase : theme.secondaryText)};
	}
`;

export const IconWrapper = styled.div`
	position: absolute;
	right: 2rem;
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
	padding: 0 0.75rem;

	svg {
		color: ${({ theme }) => theme.inputPlaceholder};

		&:hover {
			opacity: 0.8;
		}
	}
`;

export const FloatingWrapper = styled.div`
	position: absolute;
	bottom: -20px;
	color: ${({ theme }) => theme.statusDangerBase};
	max-width: 100%;

	p {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;
