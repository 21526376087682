/*
 * File: AppNavigation.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:52 PM
 * Copyright © 2022 Lithios LLC. All rights reserved.
 *
 * Last Modified: April 23, 2024 at 12:55 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { AppNavContent } from '../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	max-width: ${AppNavContent}rem;
	margin: 0px auto;
`;
