/*
 * File: TextInput.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 9, 2022 at 4:02 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: April 25, 2024 at 9:55 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: flex;
 	flex-direction: column;
 	position: relative;

 	& > label {
	 	cursor: pointer;
		text-align: left;
	 	color: ${({ theme, error }) => (error ? theme.statusDangerBase : theme.secondaryText)};
 	}
`;

const SIZES = {
	fit: css`
		width: 100%;
		font-size: 0.9rem;
	`,
	small: css`
		font-size: 0.75rem;
		min-height: 2.25rem;
 `,
	medium: css`
		font-size: 1rem;
		min-height: 2.5125rem;
 `,
};

export const TextInput = styled.input`
 	appearance: none;
 	padding: 0 0rem;
	border-radius: 0px;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid ${({ theme }) => theme.dark};
	color: ${({ theme }) => theme.primaryText};
	line-height: normal;
	width: 100%;
	outline: none;
	background-color: transparent;

 	${({ size }) => SIZES[size]}

	&[type='password'] {
		letter-spacing: 0.25rem;
	}

	&:focus {
		border-color: ${({ theme }) => theme.dark};
	}

	&:read-only {
		background-color: transparent;
		border-color: ${({ theme }) => theme.dark};
	}

 	${({ withIcon }) => withIcon && css`
		padding-left: 2.25rem;
	`}

 	${({ error }) => error && css`
		color: ${({ theme }) => theme.statusDangerBase};
		border-color: ${({ theme }) => theme.statusDangerBase};

		:focus {
			border-color: ${({ theme }) => theme.statusDangerBase};
		}
	`}
`;

export const FloatingWrapper = styled.div`
 	position: absolute;
 	bottom: -20px;
 	color: ${({ theme }) => theme.statusDangerBase};
 	max-width: 100%;

 	p {
		text-align: left;
	 	white-space: nowrap;
	 	text-overflow: ellipsis;
	 	overflow: hidden;
 	}
`;

export const IconWrapper = styled.div`
 	position: absolute;
 	display: flex;
 	height: 100%;
 	justify-content: center;
 	align-items: center;
 	padding: 0 0.75rem;
 	pointer-events: none;

 	svg {
	 	color: ${({ theme }) => theme.inputPlaceholder};
 	}
`;
