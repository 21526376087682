/*
 * File: StartStep.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 9, 2023 at 9:09 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:20 AM
 * Modified By: Brendan Michaelsen
 */

// Modules
import React, { useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

// Components
import {
	Typography, Button
} from '../../../../../../components';

// Styles
import * as S from './StartStep.styles';


/**
 * Component
 */

export const StartStep = forwardRef(({
	className, updateStep
}, ref) => {

	// Get current city app from hook
	const currentCityApp = useSelector((state) => state.cityApp.value);

	// Perform actions on component load
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className ? `${className} scroller` : 'scroller'} id="scroller-start-step">

			{/* Logo Container */}
			<S.LogoContainer>
				<S.Logo
					alt={`${currentCityApp?.municipalityName} Logo`}
					title={`${currentCityApp?.municipalityName} Logo`}
					src={currentCityApp?.logoImage}
				/>
			</S.LogoContainer>

			{/* Content Container */}
			<S.ContentContainer>
				<S.ContentPadding left right>
					<S.Content>
						<Typography tag="h1" weight="bold">
							Welcome to
							{' '}
							{currentCityApp?.municipalityName}
						</Typography>
						<Typography tag="h4" weight="semibold">Let&apos;s get you started.</Typography>
					</S.Content>
				</S.ContentPadding>
			</S.ContentContainer>

			{/* Bottom Container */}
			<S.BottomActionContainer>
				<S.ContentPadding left right>
					<Button
						variant="text"
						size="large"
						variation="white"
						onClick={() => { updateStep(2); }}
					>
						<Typography tag="h4" weight="semibold">Start</Typography>
						<FontAwesomeIcon icon={['fal', 'arrow-right']} />
					</Button>
				</S.ContentPadding>
			</S.BottomActionContainer>

			{/* Decoration */}
			<S.Decoration src={`${process.env.CDN_URL}/public/assets/building-decoration-white.png`} />

		</S.StepContainer>
	);
});


/**
 * Configuration
 */

StartStep.displayName = 'StartStep';
StartStep.propTypes = {
	className: PropTypes.string,
	updateStep: PropTypes.func
};
StartStep.defaultProps = {
	className: null,
	updateStep: null
};
