/*
 * File: Constants.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on January 16, 2021 at 9:11 PM
 * Copyright © 2021 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 12:43 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Roles
 */

export const ROLES = {
	ALL: 'all',
	STANDARD: 'standard'
};


/**
 * Available Locales
 */

export const AVAILABLE_LOCALES = ['en'];


/**
 * UI Mode Options
 */

export const UI_MODE_OPTIONS = {
	LIGHT: 'light',
	DARK: 'dark'
};


/**
 * General
 */

export const DEFAULT_TIMEZONE = 'America/New_York';
export const DEFAULT_LOCALE = 'en-US';
export const MAX_QUERY_LIMIT = 1000;
export const SOCKET_COMMAND_KEY = 'command';


/**
 * Cookies
 */

export const SESSION_TOKEN_COOKIE = 'e_s_token';
export const LOCALE_COOKIE = 'pl_locale';
export const UI_MODE_COOKIE = 'ui_mode';
export const CONSENT_ACTION_TAKEN_COOKIE = 'c_action_taken';
export const CONSENT_PERFORMANCE_ENABLED_COOKIE = 'c_performance_enabled';
export const CONSENT_ANALYTICS_ENABLED_COOKIE = 'c_analytics_enabled';
export const CONSENT_TARGETING_ENABLED_COOKIE = 'c_targeting_enabled';


/**
 * Cookie Domain
 */

export const COOKIE_DOMAIN = () => {
	switch (process.env.ENV) {
		case 'development':
			return null;
		case 'qa':
			return 'qa.mycity.lithiosapps.com';
		case 'staging':
			return 'staging.mycity.lithiosapps.com';
		case 'production':
			return 'lithiosapps.com';
		default:
			return null;
	}
};


/**
 * Headers
 */

export const SESSION_TOKEN_HEADER = 'x-rivers-e-s-token';


/**
 * Expirations
 */

export const SESSION_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const CONSENT_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const LOCALE_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const UI_MODE_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year


/**
 * Core Domain Whitelists
 */

export const CORE_DOMAIN_WHITELIST = () => [
	'https://*.lithiosapps.com',
	'https://mycity-lithios-*.herokuapp.com',
	process.env.ENV === 'development' && [
		'http://localhost:*'
	]
].filter(Boolean);


/**
 * Alert Types
 */

export const ALERT_STYLES = {
	ERROR: 'error',
	INFO: 'info'
};


/**
 * Error Messages
 */

export const GENERAL_ERROR_MESSAGE = 'Something went wrong, try again later!';


/**
 * State Options
 */

export const STATE_ABBREVIATIONS = [
	'AL',
	'AK',
	'AS',
	'AZ',
	'AR',
	'CA',
	'CO',
	'CT',
	'DE',
	'DC',
	'FM',
	'FL',
	'GA',
	'GU',
	'HI',
	'ID',
	'IL',
	'IN',
	'IA',
	'KS',
	'KY',
	'LA',
	'ME',
	'MH',
	'MD',
	'MA',
	'MI',
	'MN',
	'MS',
	'MO',
	'MT',
	'NE',
	'NV',
	'NH',
	'NJ',
	'NM',
	'NY',
	'NC',
	'ND',
	'MP',
	'OH',
	'OK',
	'OR',
	'PW',
	'PA',
	'PR',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VT',
	'VI',
	'VA',
	'WA',
	'WV',
	'WI',
	'WY'
];

export const STATE_OPTIONS = [
	{
		name: 'Alabama',
		abbreviation: 'AL'
	},
	{
		name: 'Alaska',
		abbreviation: 'AK'
	},
	{
		name: 'American Samoa',
		abbreviation: 'AS'
	},
	{
		name: 'Arizona',
		abbreviation: 'AZ'
	},
	{
		name: 'Arkansas',
		abbreviation: 'AR'
	},
	{
		name: 'California',
		abbreviation: 'CA'
	},
	{
		name: 'Colorado',
		abbreviation: 'CO'
	},
	{
		name: 'Connecticut',
		abbreviation: 'CT'
	},
	{
		name: 'Delaware',
		abbreviation: 'DE'
	},
	{
		name: 'District Of Columbia',
		abbreviation: 'DC'
	},
	{
		name: 'Federated States Of Micronesia',
		abbreviation: 'FM'
	},
	{
		name: 'Florida',
		abbreviation: 'FL'
	},
	{
		name: 'Georgia',
		abbreviation: 'GA'
	},
	{
		name: 'Guam',
		abbreviation: 'GU'
	},
	{
		name: 'Hawaii',
		abbreviation: 'HI'
	},
	{
		name: 'Idaho',
		abbreviation: 'ID'
	},
	{
		name: 'Illinois',
		abbreviation: 'IL'
	},
	{
		name: 'Indiana',
		abbreviation: 'IN'
	},
	{
		name: 'Iowa',
		abbreviation: 'IA'
	},
	{
		name: 'Kansas',
		abbreviation: 'KS'
	},
	{
		name: 'Kentucky',
		abbreviation: 'KY'
	},
	{
		name: 'Louisiana',
		abbreviation: 'LA'
	},
	{
		name: 'Maine',
		abbreviation: 'ME'
	},
	{
		name: 'Marshall Islands',
		abbreviation: 'MH'
	},
	{
		name: 'Maryland',
		abbreviation: 'MD'
	},
	{
		name: 'Massachusetts',
		abbreviation: 'MA'
	},
	{
		name: 'Michigan',
		abbreviation: 'MI'
	},
	{
		name: 'Minnesota',
		abbreviation: 'MN'
	},
	{
		name: 'Mississippi',
		abbreviation: 'MS'
	},
	{
		name: 'Missouri',
		abbreviation: 'MO'
	},
	{
		name: 'Montana',
		abbreviation: 'MT'
	},
	{
		name: 'Nebraska',
		abbreviation: 'NE'
	},
	{
		name: 'Nevada',
		abbreviation: 'NV'
	},
	{
		name: 'New Hampshire',
		abbreviation: 'NH'
	},
	{
		name: 'New Jersey',
		abbreviation: 'NJ'
	},
	{
		name: 'New Mexico',
		abbreviation: 'NM'
	},
	{
		name: 'New York',
		abbreviation: 'NY'
	},
	{
		name: 'North Carolina',
		abbreviation: 'NC'
	},
	{
		name: 'North Dakota',
		abbreviation: 'ND'
	},
	{
		name: 'Northern Mariana Islands',
		abbreviation: 'MP'
	},
	{
		name: 'Ohio',
		abbreviation: 'OH'
	},
	{
		name: 'Oklahoma',
		abbreviation: 'OK'
	},
	{
		name: 'Oregon',
		abbreviation: 'OR'
	},
	{
		name: 'Palau',
		abbreviation: 'PW'
	},
	{
		name: 'Pennsylvania',
		abbreviation: 'PA'
	},
	{
		name: 'Puerto Rico',
		abbreviation: 'PR'
	},
	{
		name: 'Rhode Island',
		abbreviation: 'RI'
	},
	{
		name: 'South Carolina',
		abbreviation: 'SC'
	},
	{
		name: 'South Dakota',
		abbreviation: 'SD'
	},
	{
		name: 'Tennessee',
		abbreviation: 'TN'
	},
	{
		name: 'Texas',
		abbreviation: 'TX'
	},
	{
		name: 'Utah',
		abbreviation: 'UT'
	},
	{
		name: 'Vermont',
		abbreviation: 'VT'
	},
	{
		name: 'Virgin Islands',
		abbreviation: 'VI'
	},
	{
		name: 'Virginia',
		abbreviation: 'VA'
	},
	{
		name: 'Washington',
		abbreviation: 'WA'
	},
	{
		name: 'West Virginia',
		abbreviation: 'WV'
	},
	{
		name: 'Wisconsin',
		abbreviation: 'WI'
	},
	{
		name: 'Wyoming',
		abbreviation: 'WY'
	}
];


/**
 * Events
 */

export const SAMPLE_EVENTS = (cityApp) => [
	{
		id: '1',
		title: 'Andrew Otto',
		slug: 'andrew-otto',
		date: 'Oct 2, 2023 - Oct 24, 2024',
		time: '5:30pm - 8:30pm',
		location: 'Irregardless Cafe',
		price: 'Free',
		description: `Andrew Otto will perform live at Irregardless Cafe! Music every night and Sunday morning from ${(STATE_OPTIONS.find((state) => state.abbreviation === cityApp.state) || {}).name} groups. Come see the best of blues, country, acoustic, rock n' roll, and more!`,
		tags: [
			{
				id: 'concerts', title: 'Concerts', icon: ['fal', 'music'], colorBase: 'statusInfo'
			},
			{
				id: 'location', title: 'Downtown', icon: ['fal', 'map-pin'], colorBase: 'statusSuccess'
			}
		],
		image: `${process.env.CDN_URL}/public/assets/irregardless-sign.png`,
		coordinates: cityApp.coordinateOptions[0]
	},
	{
		id: '2',
		title: 'Easy Honey',
		slug: 'easy-honey',
		date: 'Sept 24, 2024',
		time: '9pm - Midnight',
		location: 'The Pour House Music Hall',
		price: '$10-$15',
		description: 'Get ready for a night of unforgettable music with Easy Honey, as they bring their unique blend of melodies to The Pour House Music Hall! Don\'t miss this electrifying performance where every note promises to leave you wanting more.',
		tags: [
			{
				id: 'concerts', title: 'Concerts', icon: ['fal', 'music'], colorBase: 'statusInfo'
			},
			{
				id: 'location', title: 'Downtown', icon: ['fal', 'map-pin'], colorBase: 'statusSuccess'
			}
		],
		image: `${process.env.CDN_URL}/public/assets/easy-honey.png`,
		coordinates: cityApp.coordinateOptions[1]
	},
	{
		id: '3',
		title: 'African American Theater Preview',
		slug: 'african-american-theatre-preview',
		date: 'Aug 11, 2023 - Aug 28, 2024',
		location: 'Online',
		price: '$5',
		description: 'Join us for an evening of powerful performances that showcase the vibrant stories and talents of the African American community.',
		tags: [
			{
				id: 'arts', title: 'Arts', icon: ['fal', 'theater-masks'], colorBase: 'statusInfo'
			},
		],
		image: `${process.env.CDN_URL}/public/assets/african-american-theater-preview.png`
	},
	{
		id: '4',
		title: cityApp.sportsEventHeadline,
		slug: 'nc-state-vs-unc',
		date: 'July 26, 2024',
		time: 'TBA',
		location: 'PNC Arena',
		price: 'View website',
		description: 'Gear up for a classic rivalry – it\'s basketball at its best! Join the crowd, feel the buzz, and catch all the action-packed moments in this not-to-be-missed game.',
		tags: [
			{
				id: 'sports', title: 'Sports', icon: ['fal', 'basketball-hoop'], colorBase: 'statusInfo'
			},
		],
		image: `${process.env.CDN_URL}/public/assets/nc-state-unc.png`,
		coordinates: cityApp.coordinateOptions[2]
	}
];


/**
 * Points of Interest
 */

export const SAMPLE_POINTS_OF_INTEREST = (cityApp) => [
	{
		id: '1',
		title: `${cityApp.municipalityName} Union Station`,
		description: `${cityApp.municipalityName} Union Station is a multi-modal transportation hub in downtown ${cityApp.municipalityName} that includes the adaptive reuse of the industrial Viaduct Building, rail improvements, and increased connectivity within the downtown community.`,
		reviewAverage: 4.3,
		reviewCount: 218,
		reviews: [
			{
				id: '1',
				stars: 4,
				quote: 'great location, free parking',
				date: '5/23/21'
			},
			{
				id: '2',
				stars: 4,
				quote: 'smooth ticketing experience',
				date: '4/20/21'
			},
			{
				id: '3',
				stars: 4,
				quote: 'Beautiful lobby area!',
				date: '4/5/21'
			}
		],
		address: `510 W Martin St, ${cityApp.municipalityName}, ${cityApp.state} 27601`,
		images: [
			'https://static1.squarespace.com/static/635828ed41614511e4d40a8d/t/635846b981bf777ac606a4a9/1666729668208/Emory+%26+Nic+Wedding+-727.jpg?format=1500w',
			'https://upload.wikimedia.org/wikipedia/commons/7/78/2018.05.02_Union_Station_Tour_01.jpg'
		],
		website: 'https://www.amtrak.com/stations/rgh',
		phone: '(800) 872-7245',
		coordinates: cityApp.coordinateOptions[3]
	},
	{
		id: '2',
		title: `${cityApp.municipalityName} Founded`,
		description: `At ${cityApp.municipalityName} Founded, you’ll work alongside a community of entrepreneurs, innovators, and change-makers. Whether you own a business or work remotely, ${cityApp.municipalityName} Founded has everything you need to make 9-5 the best part of the day.`,
		reviewAverage: 4.7,
		reviewCount: 158,
		reviews: [
			{
				id: '1',
				stars: 4,
				quote: 'Energetic place to work!',
				date: '5/23/21'
			},
			{
				id: '2',
				stars: 4,
				quote: 'great networking opportunities',
				date: '4/20/21'
			}
		],
		address: `310 S Harrington St, ${cityApp.municipalityName}, ${cityApp.state} 27603`,
		images: [
			'https://assets-global.website-files.com/63fe7e8eebafb04e0c50b71a/63fe7e8eebafb0a1a150b7f5_wh.jpg',
			'https://coworkingmag.com/wp-content/uploads/sites/76/2019/12/Raleigh-Founded.jpg'
		],
		website: 'https://www.raleighfounded.com',
		phone: '(919) 300-6917',
		coordinates: cityApp.coordinateOptions[4]
	},
	{
		id: '3',
		title: 'Big Ed\'s City Market Restaurant',
		description: 'Big Ed’s Restaurant has been a staple since the 1950’s. We invite you to stop by one of our locations for a dining experience full of southern hospitality, joy, and laughter.',
		reviewAverage: 4.6,
		reviewCount: 2600,
		reviews: [
			{
				id: '1',
				stars: 4,
				quote: `Amazing food. A ${cityApp.municipalityName} classic`,
				date: '5/23/21'
			},
			{
				id: '2',
				stars: 4,
				quote: 'great atmosphere',
				date: '4/20/21'
			}
		],
		address: `220 Wolfe St, ${cityApp.municipalityName}, ${cityApp.state} 27601`,
		images: [
			'https://media-cdn.tripadvisor.com/media/photo-s/10/72/b1/27/dining-room.jpg',
			'https://c8.alamy.com/zooms/9/3a00836fcba94c42b3be68713d105651/an246a.jpg'
		],
		website: 'https://www.bigedsnc.com',
		phone: '(919) 836-9909',
		coordinates: cityApp.coordinateOptions[5]
	}
];


/**
 * City App Commands
 */

export const CITY_APP_COMMANDS = {
	SCROLL: 'scroll',
	CLICK: 'click'
};


/**
 * Storage Keys
 */

export const STORAGE_KEYS = {
	CITYAPP: 'cityapp',
	SUPERHERO: 'superhero'
};


/**
 * Socket Actions
 */

export const SOCKET_ACTIONS = {
	START_SESSION: 'start_session',
	UPDATE_SESSION: 'update_session',
	COMPLETE_SESSION: 'complete_session',
	CITY_APP_COMMAND: 'city_app_command'
};


/**
 * City App Roles
 */

export const CITY_APP_ROLES = {
	PUBLIC: 'public',
	COMMANDER: 'commander'
};
