/*
 * File: SetupStep.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:23 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, forwardRef, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';
import { flatten } from 'lottie-colorify';

// Services
import { updateBuildSession } from '../../../../../services/buildsession';

// Components
import {
	Typography
} from '../../../../../components';

// Animation
import animationData from '../../../../../assets/animations/spinner.json';

// Styles
import * as S from './SetupStep.styles';


/**
 * Constants
 */

const STEP_LENGTHS = [
	4000,
	4000,
	3500
];
const STEP_DELAYS = [
	300,
	0,
	0
];
const START_DELAY = 800;
const CONTINUE_DELAY = 1500;


/**
 * Component
 */

export const SetupStep = forwardRef(({
	updateStep, isVisible, sessionId, municipalityState, className,
}, ref) => {

	// Create state handlers
	const [completedSteps, setCompletedSteps] = useState([]);
	const [accountCreationStepIndex, setAccountCreationStepIndex] = useState(0);

	// Create references
	const completedStepsRef = useRef();
	const accountCreationStepIndexRef = useRef();

	// Set current loop value
	completedStepsRef.current = completedSteps;
	accountCreationStepIndexRef.current = accountCreationStepIndex;

	// Begin confirmation flow
	const beginConfirmationFlow = async () => {

		// Update display
		setCompletedSteps([
			...completedStepsRef.current,
			accountCreationStepIndexRef.current
		]);

		// If final step completed, return
		if (accountCreationStepIndexRef.current > 2) {

			// Move to next step
			setTimeout(async () => {

				// Update build session
				updateBuildSession({ sessionId, setupComplete: true }).then(() => {

					// Update state
					updateStep(3);

				});

			}, CONTINUE_DELAY);

		} else {

			// Get schedule parameters
			const interval = STEP_LENGTHS[accountCreationStepIndexRef.current];
			const delay = STEP_DELAYS[accountCreationStepIndexRef.current];

			// Schedule delay
			setTimeout(() => {

				// Increment step
				setAccountCreationStepIndex(accountCreationStepIndexRef.current + 1);

				// Schedule timer
				setTimeout(() => {
					beginConfirmationFlow();
				}, interval);
			}, delay);
		}
	};

	// Perform actions on visibility change
	useEffect(() => {
		if (isVisible === true) {

			// Begin confirmation flow
			setTimeout(() => {
				beginConfirmationFlow();
			}, START_DELAY);
		}
	}, [isVisible]);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Logo */}
			<S.LogoContainer>
				<S.Logo src={`${process.env.CDN_URL}/public/assets/lithios-logo.png`} />
			</S.LogoContainer>

			{/* Form */}
			<S.Form>

				{/* Content */}
				<Typography tag="h2" weight="bold">
					Hang tight, we&apos;re building the perfect Smart City app for
					{' '}
					<span>{municipalityState.municipalityName}</span>
				</Typography>

				{/* Setup - Step 1 */}
				<S.SetupRow className="first animate">
					<S.ProgressHolder className={completedSteps.includes(1) && 'complete'}>
						{accountCreationStepIndex === 1 && !completedSteps.includes(1) && (
							<S.AnimationContainer>
								<Lottie
									loop
									play
									animationData={flatten('#3AD98C', animationData)}
									width={500}
									height={500}
									style={{
										width: '160px',
										position: 'absolute',
										left: '-57px',
										top: '-18px'
									}}
								/>
							</S.AnimationContainer>
						)}
						{accountCreationStepIndex < 1 && (
							<S.PendingState />
						)}
						<S.CompleteIcon icon={['fas', 'check']} className={completedSteps.includes(1) && 'show'} />
					</S.ProgressHolder>
					<S.ContentHolder>
						<Typography tag="p" variation="1">
							Importing content and applying
							{' '}
							{municipalityState.municipalityName}
							&apos;s branding to the app
						</Typography>
					</S.ContentHolder>
				</S.SetupRow>

				{/* Setup - Step 2 */}
				<S.SetupRow className="animate">
					<S.ProgressHolder className={completedSteps.includes(2) && 'complete'}>
						{accountCreationStepIndex === 2 && !completedSteps.includes(2) && (
							<S.AnimationContainer>
								<Lottie
									loop
									play
									animationData={flatten('#3AD98C', animationData)}
									style={{
										width: '160px',
										position: 'absolute',
										left: '-57px',
										top: '-18px'
									}}
								/>
							</S.AnimationContainer>
						)}
						{accountCreationStepIndex < 2 && (
							<S.PendingState />
						)}
						<S.CompleteIcon icon={['fas', 'check']} className={completedSteps.includes(2) && 'show'} />
					</S.ProgressHolder>
					<S.ContentHolder>
						<Typography tag="p" variation="1">Finding local events and recommendations for residents.</Typography>
					</S.ContentHolder>
				</S.SetupRow>

				{/* Setup - Step 3 */}
				<S.SetupRow className="animate">
					<S.ProgressHolder className={completedSteps.includes(3) && 'complete'}>
						{accountCreationStepIndex === 3 && !completedSteps.includes(3) && (
							<S.AnimationContainer>
								<Lottie
									loop
									play
									animationData={flatten('#3AD98C', animationData)}
									style={{
										width: '160px',
										position: 'absolute',
										left: '-57px',
										top: '-18px'
									}}
								/>
							</S.AnimationContainer>
						)}
						{accountCreationStepIndex < 3 && (
							<S.PendingState />
						)}
						<S.CompleteIcon icon={['fas', 'check']} className={completedSteps.includes(3) && 'show'} />
					</S.ProgressHolder>
					<S.ContentHolder>
						<Typography tag="p" variation="1">Polishing your new Smart City app and tidying up your preferences.</Typography>
					</S.ContentHolder>
				</S.SetupRow>
			</S.Form>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

SetupStep.displayName = 'SetupStep';
SetupStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string,
	sessionId: PropTypes.string,
	isVisible: PropTypes.bool,
	municipalityState: PropTypes.shape()
};
SetupStep.defaultProps = {
	className: null,
	isVisible: false,
	sessionId: null,
	municipalityState: {}
};
