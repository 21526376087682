/*
 * File: Chat.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on December 4, 2023 at 11:50 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:22 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Padding } from '../../../../../components';

// Styles
import { ColorValueCreator } from '../../../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

export const NavigationBar = styled.div`
	height: 58px;
	color: ${ColorValueCreator().white};
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: ${ColorValueCreator().navigationShadow};
	position: relative;
	z-index: 99;

	h1 {
		color: ${({ theme }) => theme.brandPrimaryBase};
		flex-grow: 1;
		text-align: left;
	}
	svg {
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
	& > div {
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
	align-items: start;
`;

export const ConversationContainer = styled.div`
	flex-grow: 1;
	overflow: scroll;
	height: 100%;
	background-color: ${({ theme }) => theme.secondaryBackground};

	.fullHeight {
		height: 100%;
	}
`;

export const ChatContent = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding-top: 30px;
`;

export const MessageBar = styled.div`
	height: 56px;
	color: ${ColorValueCreator().white};
	flex-shrink: 0;
	display: flex;
	bottom: 0px;
	box-shadow: ${ColorValueCreator().elementDarkShadow};
	align-items: center;
	width: 100%;
	padding: 10px 20px;
	gap: 12px;

	input {
		height: 100%;
		border-radius: 6px;
		appearance: none;
		flex-grow: 1;
		border: 1px solid ${ColorValueCreator().neutralForm};
		padding: 0px 12px;
		font-weight: 400;
		outline: none;

		&:focus,
		&:active {
			border: 1px solid ${({ theme }) => theme.secondaryText};
		}
	}
	button {
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;

export const RightMessageContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: end;
`;

export const LeftMessageContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 4px;
`;

export const RightMessage = styled.div`
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	border-radius: 18px;
	padding: 6px 16px;
	display: inline-flex;
	max-width: 80%;

	* {
		text-align: right;
		color: ${ColorValueCreator().white};
		white-space: pre-wrap;
		width: 100%;
		overflow-x: hidden;
	}
`;

export const LeftMessage = styled.div`
	background-color: ${ColorValueCreator().white};
	border: 1px solid ${({ theme }) => theme.layoutBorder};
	border-radius: 18px;
	padding: 6px 16px;
	display: inline-flex;
	max-width: 80%;

	* {
		text-align: left;
		white-space: pre-wrap;
		width: 100%;
		overflow-x: hidden;
	}
`;

export const ChatIcon = styled.div`
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		font-size: 0.8rem;
		color: ${ColorValueCreator().white};
	}
`;

export const ConversationFooter = styled.div`
	width: 100%;
	height: 80px;
	flex-shrink: 0;
`;
