/*
 * File: constants.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:32 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: April 23, 2024 at 12:54 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Breakpoints
 */

// Max Width
export const smallBreakpoint = 25; // 25em
export const mediumBreakpoint = 45; // 45em
export const largeBreakpoint = 60; // 60em
export const xLargeBreakpoint = 100; // 100em
export const xxLargeBreakpoint = 140; // 140em

// Min Width
export const mobileBreakpoint = 62; // 62em


/**
 * Dimensions
 */

export const AppNavSidebarWidth = 340; // 340px
export const AppNavCartWidth = 520; // 520px
export const AppNavTopbarHeight = 68; // 68px
export const AppNavContent = 45; // 45em


/**
 * Delays
 */

export const navigationDropdownDelay = 600;
