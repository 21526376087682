/*
 * File: PublicScreen.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 13, 2024 at 4:28 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useMemo, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import socketIOClient from 'socket.io-client';
import { v4 } from 'uuid';
import { useWindowResize } from 'beautiful-react-hooks';
import Lottie from 'react-lottie-player';
import { flatten } from 'lottie-colorify';

// Utilities
import { createStateLocale } from '../../../../utilities/locale';
import { parseFirstLastName } from '../../../../../utilities/utilities';

// Slices
import { clearSuperhero } from '../../../../store/slices/superhero/superhero.slice';

// Components
import {
	Emoji,
	Meta,
	Typography,
	ConfettiBurst
} from '../../../../components';

// Constants
import { CITY_APP_ROLES, SOCKET_ACTIONS, SOCKET_COMMAND_KEY } from '../../../../../Constants';

// Animation
import pulseAnimationData from '../../../../assets/animations/pulse.json';
import spinnerAnimationData from '../../../../assets/animations/spinner.json';
import powAnimationData from '../../../../assets/animations/pow.json';

// Styles
import * as S from './PublicScreen.styles';


/**
 * Component
 */

const PublicScreen = ({ meta, locale }) => {

	// Create reference for step components
	const [qrStepHeight, setQRStepHeight] = useState(0);
	const [welcomeStepHeight, setWelcomeStepHeight] = useState(0);
	const [buildStepHeight, setBuildStepHeight] = useState(0);
	const [completeStepHeight, setCompleteStepHeight] = useState(0);
	const qrStepRef = useRef();
	const welcomeStepRef = useRef();
	const buildStepRef = useRef();
	const completeStepRef = useRef();

	// Create state handlers
	const [qrCodeURI, setQRCodeURI] = useState(null);
	const [superheroName, setSuperheroName] = useState(null);
	const [creatorName, setCreatorName] = useState(null);
	const [currentStep, setCurrentStep] = useState(1);
	const [shouldUpdateHeight, setShouldUpdateHeight] = useState(true);
	const [fireConfetti, setFireConfetti] = useState(false);
	const [setupComplete, setSetupComplete] = useState(false);

	// Generate build session id
	const sessionId = useMemo(() => v4(), []);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Use hooks
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// Create refs
	const socketRef = useRef();
	const confettiTargetRef = useRef();

	// Generate QR code
	const generateQRCode = async () => {
		const code = await QRCode.toDataURL(`https://superhero.lithiosapps.com/build/${sessionId}`, {
			scale: 10
		});
		setQRCodeURI(code);
	};

	// Handle actions on session id change
	useEffect(() => {

		// Generate QR code URI
		generateQRCode();

		// Disconnect and refresh socket
		if (socketRef.current) socketRef?.current?.disconnect();

		// Create a WebSocket connection
		socketRef.current = socketIOClient(process.env.SOCKET_URL, {
			transports: ['websocket'],
			query: { sessionId },
		});

		// Listen for incoming commands
		socketRef.current.on(SOCKET_COMMAND_KEY, (message) => {

			// Get parameters from payload
			const {
				action, accessId, superheroName: name, creatorName: myName, setupComplete: isSetupComplete
			} = message.payload;

			// Handle action
			if (action === SOCKET_ACTIONS.START_SESSION) {

				// Update current step
				setCurrentStep(2);

			} else if (action === SOCKET_ACTIONS.UPDATE_SESSION) {

				// Check if session complete
				if (!isSetupComplete) {

					// Update step with data
					setSuperheroName(name);
					setCreatorName(myName);
					setCurrentStep(3);

				} else {

					// Update step with setup
					setSetupComplete(true);
				}

			} else if (action === SOCKET_ACTIONS.COMPLETE_SESSION) {

				// Update current step
				setCurrentStep(4);

				// Fire confetti
				setTimeout(() => {
					setFireConfetti(true);
				}, 100);

				// Reset confetti
				setTimeout(() => {
					setFireConfetti(false);
				}, 5000);

				// Redirect to newly created superhero
				setTimeout(() => {

					// Move to superhero
					navigate(`/superhero/${accessId}?role=${CITY_APP_ROLES.PUBLIC}`);

				}, 5000);
			}
		});

		// Destroys the socket reference when the connection is closed
		return () => { socketRef?.current?.disconnect(); };

	}, [sessionId]);

	// Handle actions on component load
	useEffect(() => {

		// Clear superhero
		dispatch(clearSuperhero());

	}, []);

	// Handle actions on component load
	useEffect(() => {
		if (shouldUpdateHeight) {

			// Set component heights
			setQRStepHeight(qrStepRef?.current?.clientHeight);
			setWelcomeStepHeight(welcomeStepRef?.current?.clientHeight);
			setBuildStepHeight(buildStepRef?.current?.clientHeight);
			setCompleteStepHeight(completeStepRef?.current?.clientHeight);

			// Update state
			setShouldUpdateHeight(false);
		}
	}, [shouldUpdateHeight]);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set component heights
		setQRStepHeight(qrStepRef?.current?.clientHeight);
		setWelcomeStepHeight(welcomeStepRef?.current?.clientHeight);
		setBuildStepHeight(buildStepRef?.current?.clientHeight);
		setCompleteStepHeight(completeStepRef?.current?.clientHeight);
	});

	// Get step height for step component
	const getStepHeight = () => {
		switch (currentStep) {
			case 1:
				return qrStepHeight;
			case 2:
				return welcomeStepHeight;
			case 3:
				return buildStepHeight;
			case 4:
				return completeStepHeight;
			default:
				return 0;
		}
	};

	// Handle render component
	const renderComponent = () => (
		<>
			<S.StepContainer className="animate" stepHeight={getStepHeight()}>

				{/* QR Step */}
				<S.Step className={currentStep === 1 ? 'animate show' : 'animate'}>
					<S.StepInner ref={qrStepRef}>
						<S.StepContentContainer>

							{/* Instructions */}
							<S.InstructionsContainer>
								<Typography tag="h3" weight="semibold">
									<Emoji symbol="⚠️" size={1.5} label="Warning" />
									The city needs your help!
								</Typography>
								<Typography tag="h1" weight="bold">
									Create your own
									{' '}
									<span>Superhero</span>
									{' '}
									at the
									{' '}
									<span>speed of light</span>
									!
								</Typography>
							</S.InstructionsContainer>

							{/* Content */}
							<S.ContentContainer>
								<S.QRCodeContainer>
									<S.QRCode src={qrCodeURI} />
									<S.AnimationContainer $opacity={0.7}>
										<Lottie
											loop
											play
											animationData={flatten('#FFFFFF', pulseAnimationData)}
											style={{
												width: '100%',
												height: '100%'
											}}
										/>
									</S.AnimationContainer>
								</S.QRCodeContainer>
							</S.ContentContainer>
						</S.StepContentContainer>
					</S.StepInner>
				</S.Step>

				{/* Welcome Step */}
				<S.Step className={currentStep === 2 ? 'animate show' : 'animate'}>
					<S.StepInner ref={welcomeStepRef}>
						<S.StepContentContainer>

							{/* Content */}
							<S.ContentContainer>
								<Typography tag="h3" weight="bold">
									<span style={{ marginRight: '8px' }}>It&apos;s time to save the day</span>
									<Emoji symbol="💪" size={1.8} label="Wave" />
								</Typography>
								<Typography tag="h1" weight="bold">
									Use your
									{' '}
									<span>top secret</span>
									{' '}
									mobile communicator to create your
									{' '}
									<span>Superhero</span>
									!
								</Typography>

								{/* Animation */}
								<S.AnimationContainer className="largeAnimation" $opacity={0.7}>
									<Lottie
										loop
										play
										animationData={flatten('#FFFFFF', pulseAnimationData)}
										style={{
											width: '100%',
											height: '100%'
										}}
									/>
								</S.AnimationContainer>

							</S.ContentContainer>
						</S.StepContentContainer>
					</S.StepInner>
				</S.Step>

				{/* Build Step */}
				<S.Step className={currentStep === 3 ? 'animate show' : 'animate'}>
					<S.StepInner ref={buildStepRef}>
						<S.StepContentContainer>

							{/* Instructions */}
							<S.InstructionsContainer>
								{!setupComplete ? (
									<Typography tag="h1" weight="bold">
										Powering up the generation chamber to create
										<br />
										<span>{superheroName}</span>
										...
									</Typography>
								) : (
									<Typography tag="h1" weight="bold">
										Synchronizing everything cool about
										{' '}
										<span>{parseFirstLastName(creatorName).firstName}</span>
										{' '}
										with
										{' '}
										<span>{superheroName}</span>
									</Typography>
								)}
							</S.InstructionsContainer>

							{/* Content */}
							<S.ContentContainer>
								<S.BuildProgressContainer>
									<S.AnimationContainer $opacity={1}>
										<Lottie
											loop
											play
											animationData={flatten('#3AD98C', spinnerAnimationData)}
											style={{
												width: '100%',
												height: '100%'
											}}
										/>
									</S.AnimationContainer>
								</S.BuildProgressContainer>
							</S.ContentContainer>
						</S.StepContentContainer>
					</S.StepInner>
				</S.Step>

				{/* Complete Step */}
				<S.Step className={currentStep === 4 ? 'animate show' : 'animate'}>
					<S.StepInner ref={completeStepRef}>
						<S.StepContentContainer>

							{/* Content */}
							<S.ContentContainer>
								<Typography tag="h3" weight="bold">
									<span>Is it a bird, is it a plane?</span>
								</Typography>
								<Typography tag="h1" weight="bold">
									<span>{superheroName}</span>
									{' '}
									is ready to save the day,
									{' '}
									<span>{creatorName}</span>
									!
								</Typography>
								<S.CompletionIconContainer>
									<Lottie
										loop
										play
										animationData={powAnimationData}
										style={{
											position: 'absolute',
											width: '200%',
											height: '200%'
										}}
									/>
									<S.ConfettiTarget ref={confettiTargetRef} />
								</S.CompletionIconContainer>
							</S.ContentContainer>

						</S.StepContentContainer>

						{/* Confetti */}
						{fireConfetti && (
							<ConfettiBurst
								fireAway={fireConfetti}
								targetRef={confettiTargetRef}
								force={0.4}
								duration={3000}
								particleCount={80}
							/>
						)}
					</S.StepInner>
				</S.Step>
			</S.StepContainer>

			{/* Decoration */}
			<S.Decoration src={`${process.env.CDN_URL}/public/assets/building-decoration-white-extended.png`} />
		</>
	);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{}} locale={stateLocale} />

			{/* Component Content */}
			<S.Wrapper>{renderComponent()}</S.Wrapper>
		</>
	);
};


/**
 * Configuration
 */

PublicScreen.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
PublicScreen.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default PublicScreen;
