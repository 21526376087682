/*
 * File: locale.slice.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 10:02 AM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: November 6, 2023 at 10:43 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice } from '@reduxjs/toolkit';

// Constants
import { DEFAULT_LOCALE } from '../../../../Constants';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: {
		isSet: false,
		localeId: DEFAULT_LOCALE,
		localeBaseId: DEFAULT_LOCALE.split('-')[0],
		localePath: ''
	},
};


/**
 * Slices
 */

export const localeSlice = createSlice({
	name: 'locale',
	initialState,
	reducers: {
		updateLocale: (state, action) => {

			// Set state
			state.value = {
				...action.payload,
				isSet: true
			};
		},
		clearLocale: (state) => {

			// Clear state
			state.value = {
				...initialState.value,
				isSet: true
			};
		},
	},
	extraReducers: {},
});


/**
 * Exports
 */

export const { updateLocale, clearLocale } = localeSlice.actions;

export default localeSlice.reducer;
