/*
 * File: BuildFlow.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 13, 2024 at 1:20 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useWindowResize } from 'beautiful-react-hooks';

// Utilities
import { createStateLocale } from '../../../../utilities/locale';

// Services
import { startSuperheroBuildSession } from '../../../../services/buildsession';

// Slices
import { clearSuperhero } from '../../../../store/slices/superhero/superhero.slice';

// Components
import {
	Meta,
} from '../../../../components';

// Step Components
import { HeroCreationStep } from './HeroCreationStep';
import { SetupStep } from './SetupStep';
import { ConfirmationStep } from './ConfirmationStep';

// Content
import BlueWaveDecoration from '../../../../assets/images/BlueWaveDecoration.svg';

// Styles
import * as S from './BuildFlow.styles';


/**
 * Component
 */

const BuildFlow = ({ meta, locale }) => {

	// Get component parameters
	const { sessionId } = useParams();

	// Create reference for step components
	const [heroCreationStepHeight, setHeroCreationStepHeight] = useState(0);
	const [setupStepHeight, setSetupStepHeight] = useState(0);
	const [confirmationStepHeight, setConfirmationStepHeight] = useState(0);
	const heroCreationStepRef = useRef();
	const setupStepRef = useRef();
	const confirmationStepRef = useRef();

	// Create state handlers
	const [currentStep, setCurrentStep] = useState(1);
	const [shouldUpdateHeight, setShouldUpdateHeight] = useState(true);
	const [superheroState, setSuperheroState] = useState({});

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Use hooks
	const dispatch = useDispatch();

	// Create reference for components
	const isMounted = useRef(true);

	// Initialize component data function
	const notifyBeginSession = async () => {
		try {

			// Begin build session
			await startSuperheroBuildSession({ sessionId });

		} catch (error) {}
	};

	// Handle update superhero state
	const updateSuperheroState = (payload) => {
		setSuperheroState({ ...superheroState, ...payload });
	};

	// Handle actions on component load
	useEffect(() => {

		// Notify begin build session
		notifyBeginSession();

		// Clear superhero
		dispatch(clearSuperhero());

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle actions on component load
	useEffect(() => {
		if (shouldUpdateHeight) {

			// Set component heights
			setHeroCreationStepHeight(heroCreationStepRef?.current?.clientHeight);
			setSetupStepHeight(setupStepRef?.current?.clientHeight);
			setConfirmationStepHeight(confirmationStepRef?.current?.clientHeight);

			// Update state
			setShouldUpdateHeight(false);
		}
	}, [shouldUpdateHeight]);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set component heights
		setHeroCreationStepHeight(heroCreationStepRef?.current?.clientHeight);
		setSetupStepHeight(setupStepRef?.current?.clientHeight);
		setConfirmationStepHeight(confirmationStepRef?.current?.clientHeight);
	});

	// Get step height for step component
	const getStepHeight = () => {
		switch (currentStep) {
			case 1:
				return heroCreationStepHeight;
			case 2:
				return setupStepHeight;
			case 3:
				return confirmationStepHeight;
			default:
				return 0;
		}
	};

	// Handle render content
	const renderComponent = () => (
		<>
			{/* Step Container */}
			<S.StepPadding left right>
				<S.StepContainer className="animate" stepHeight={getStepHeight()}>
					<S.Step className={currentStep === 1 ? 'animate show' : 'animate'}>
						<HeroCreationStep
							ref={heroCreationStepRef}
							updateStep={setCurrentStep}
							updateHeight={setShouldUpdateHeight}
							sessionId={sessionId}
							updateSuperheroState={updateSuperheroState}
						/>
					</S.Step>
					<S.Step className={currentStep === 2 ? 'animate show' : 'animate'}>
						<SetupStep
							ref={setupStepRef}
							updateStep={setCurrentStep}
							updateHeight={setShouldUpdateHeight}
							superheroState={superheroState}
							sessionId={sessionId}
							isVisible={currentStep === 2}
							updateSuperheroState={updateSuperheroState}
						/>
					</S.Step>
					<S.Step className={currentStep === 3 ? 'animate show' : 'animate'}>
						<ConfirmationStep
							ref={confirmationStepRef}
							updateStep={setCurrentStep}
							updateHeight={setShouldUpdateHeight}
							isVisible={currentStep === 4}
							superheroState={superheroState}
						/>
					</S.Step>
				</S.StepContainer>
			</S.StepPadding>

			{/* Decoration */}
			<S.Decoration
				dangerouslySetInnerHTML={{ __html: BlueWaveDecoration }}
			/>
		</>
	);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{}} locale={stateLocale} />

			{/* Component Content */}
			<S.Wrapper>{renderComponent()}</S.Wrapper>
		</>
	);
};


/**
 * Configuration
 */

BuildFlow.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
BuildFlow.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default BuildFlow;
