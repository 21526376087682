/*
 * File: conversation.slice.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on December 5, 2023 at 9:25 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 5, 2023 at 10:35 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice } from '@reduxjs/toolkit';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: {
		isSet: false,
		messages: [{ userSent: false, text: 'We heard you needed some help. How can we help you?' }]
	},
};


/**
 * Slices
 */

export const conversationSlice = createSlice({
	name: 'conversation',
	initialState,
	reducers: {
		pushMessageToConversation: (state, action) => {

			// Set state
			state.value = {
				isSet: true,
				messages: [
					...state.value.messages,
					action.payload
				]
			};
		},
		clearConversation: (state) => {

			// Clear state
			state.value = {
				...initialState.value,
				isSet: true
			};
		},
	},
	extraReducers: {},
});


/**
 * Exports
 */

export const { pushMessageToConversation, clearConversation } = conversationSlice.actions;

export default conversationSlice.reducer;
