/*
 * File: Spinner.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:32 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 4, 2023 at 12:24 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import Lottie from 'react-lottie-player';
import PropTypes from 'prop-types';

// Animation
import animationData from './animations/loader.json';

// Styles
import * as S from './Spinner.styles';


/**
 * Component
 */

export const Spinner = ({ className }) => (
	<S.Wrapper className={className}>
		<Lottie loop play animationData={animationData} style={{ width: 120, height: 120 }} />
	</S.Wrapper>
);


/**
 * Configuration
 */

Spinner.displayName = 'Spinner';
Spinner.propTypes = {
	className: PropTypes.string,
};
Spinner.defaultProps = {
	className: null,
};
