/*
 * File: ProfileTab.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on November 6, 2023 at 11:59 AM
 * Copyright © 2023 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:21 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Padding } from '../../../../../../components';

// Styles
import { ColorValueCreator } from '../../../../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

export const Banner = styled.div`
	width: 100%;
	height: 102px;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: cover;
	flex-shrink: 0;
`;

export const ProfileImageContainer = styled.div`
	width: 70px;
	height: 70px;
	background-color: ${ColorValueCreator().neutralForm};
	padding: 1px;
	border-radius: 50%;
`;

export const ProfileImage = styled.img`
	width: 100%;
	height: 100%;
	border-radius: 50%;
	overflow: hidden;
`;

export const DetailContainer = styled.div`
	margin: 8px 0px 0px;
`;

export const EventsSection = styled.div`
	background-color: ${ColorValueCreator().neutralBackground};
	flex-grow: 1;

	.upcomingTitle {
		color: ${({ theme }) => theme.brandPrimaryBase};
		text-align: left;
		margin: 25px 0px 14px;
	}
`;

export const EventsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 30px;
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
`;

export const ProfileSection = styled(Padding)`
	flex-direction: column;
	padding-bottom: 14px;
	margin-top: -35px;

	* {
		text-align: left;
	}
	svg {
		margin-right: 6px;
		color: ${ColorValueCreator().brandPrimaryBase};
	}
`;
