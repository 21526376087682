/*
 * File: Routes.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:03 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 13, 2024 at 12:55 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import { Navigate } from 'react-router-dom';

// Utilities
import { createLocaleVariants } from '../../../utilities/locale';
import { filterProps, prepareComponent } from '../../../utilities/routes';

// Slice
import { initialState as initialLocale } from '../../../store/slices/locale/locale.slice';

// Navigation
import { PublicRouteWrapper } from '../../../navigation/PublicRouteWrapper';

// Pages
import {
	BuildFlow,
	PublicScreen,
	Error,
	Superhero
} from '../pages';


/**
 * Routes
 */

export const Routes = (props) => {

	// Get current locale from props
	const localeObj = props?.locale?.localePath != null ? props?.locale : initialLocale.value;

	// Get current meta from props
	const metaObj = props?.meta;

	// Render routes
	return [].concat(...[

		/**
		 * Core
		 */

		prepareComponent(createLocaleVariants({
			path: '/build/:sessionId',
			exact: true,
			shouldIndex: true,
			priority: '1.00',
			functions: [],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<BuildFlow
							{...filterProps(path, props)}
							meta={{
								title: 'Create Your Superhero! | Lithios Apps',
								description: '',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/welcome',
			exact: true,
			shouldIndex: true,
			priority: '1.00',
			functions: [],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<PublicScreen
							{...filterProps(path, props)}
							meta={{
								title: 'Welcome | Lithios Apps',
								description: '',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/superhero/:accessId',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Superhero
							{...filterProps(path, props)}
							meta={{
								title: 'My Superhero | Lithios Apps',
								description: '',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Redirects
		 */

		prepareComponent(createLocaleVariants({
			path: '/',
			exact: true,
			component() {
				return (
					<Navigate to="/welcome" replace />
				);
			}
		})),


		/**
		 * Error
		 */

		prepareComponent({
			path: '*',
			shouldIndex: false,
			isError: true,
			code: 404,
			component(path) {
				return (
					<Error
						code={404}
						{...filterProps(path, props)}
						locale={localeObj}
						meta={{
							title: 'Page Not Found | Lithios Apps',
							description: 'Whoops! We can\'t seem to find the page you\'re looking for.',
							bodyClasses: 'hide-captcha',
							showSocial: false,
							shouldIndex: false,
							...metaObj
						}}
					/>
				);
			}
		})
	].filter(Boolean));
};
