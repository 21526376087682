/*
 * File: Modal.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 21, 2022 at 12:38 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: November 8, 2023 at 11:03 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { IconButton } from '../IconButton';

// Constants
import { AppNavTopbarHeight } from '../../styles/constants';


/**
 * Helpers
 */

const buildWidth = (variant) => {
	if (variant === 'standard') {
		return '520px';
	} if (variant === 'large') {
		return '1000px';
	}
	return '90%';
};


/**
 * Styles
 */

export const ModalContainer = styled.div`
	position: fixed;
	z-index: 9999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, ${({ showOverlay }) => (showOverlay ? 0.6 : 0)});
	opacity: 0;
	visibility: hidden;
	transform: scale(1.1);
	transition: visibility 0s linear 0.3s, opacity 0.3s 0s, transform 0.3s;
	overflow: scroll;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${AppNavTopbarHeight}px 10px;
	pointer-events: none;

	&.show-modal {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
		transition: visibility 0s linear 0s, opacity 0.3s 0s, transform 0.3s;
		pointer-events: ${({ showOverlay }) => (showOverlay ? 'inherit' : 'none')};
		overflow: hidden;
	}
	* {
		pointer-events: none !important;
	}
	&.show-modal * {
		pointer-events: all !important;
	}
`;

export const Modal = styled.div`
	position: relative;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.primaryBackground};
	border: 1px solid ${({ theme }) => theme.layoutBorder};
	width: 100%;
	max-height: 100%;
	overflow: scroll;
	max-width: ${({ variant }) => buildWidth(variant)};
`;

export const CloseButton = styled(IconButton)`
	position: absolute;
	top: 20px;
	right: 20px;
	color: ${({ theme }) => theme.secondaryButton};
	z-index: 9;
`;

export const ModalWrapper = styled.div`
	padding: 35px 0px 0px;
`;
