/*
 * File: Home.styles.js
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: June 11, 2024 at 11:21 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Padding } from '../../../../../components';

// Styles
import { ColorValueCreator } from '../../../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

export const NavigationBar = styled.div`
	height: 58px;
	color: ${ColorValueCreator().white};
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: ${ColorValueCreator().navigationShadow};
	position: relative;
	z-index: 99;

	h1 {
		color: ${({ theme }) => theme.brandPrimaryBase};
		text-align: left;
		width: 120px;
	}
	svg {
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
	& > div {
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
`;

export const NavButton = styled.div`
	width: 120px;
	text-align: right;
	display: flex;
	justify-content: end;
`;

export const LogoContainer = styled.div`
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Logo = styled.img`
	height: 30px;
	border-radius: 3px;
`;

export const TabContent = styled.div`
	flex-grow: 1;
	overflow: scroll;
`;

export const TabBar = styled.div`
	height: 50px;
	color: ${ColorValueCreator().white};
	flex-shrink: 0;
	display: flex;
	justify-content: space-between;
	bottom: 0px;
	box-shadow: ${ColorValueCreator().elementDarkShadow};
`;

export const TabContainer = styled.div`
	flex: 1 1 0px;
	min-width: 0;

	div {
		height: 100% !important;
	}
`;

export const Tab = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 4px;
	cursor: pointer;

	* {
		color: ${({ $isActive, theme }) => ($isActive ? theme.brandPrimaryBase : ColorValueCreator().dark)}
	}
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
	align-items: start;
`;
