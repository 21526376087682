/*
 * File: ErrorComponent.jsx
 * Project: interactive-city-app
 *
 * Created by Brendan Michaelsen on February 19, 2022 at 12:17 AM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: April 23, 2024 at 12:21 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

// Components
import { Meta } from '../Meta';
import { Typography } from '../Typography';
import { Button } from '../Button';

// Styles
import * as S from './ErrorComponent.styles';


/**
 * Component
 */

export const ErrorComponent = ({
	locale, title, description, buttons, className
}) => (
	<>
		{/* Meta */}
		<Meta
			meta={{
				title: 'Page Not Found | Lithios Apps',
				description: 'We searched high and low but couldn\'t find what you\'re looking for. Let\'s find a better place for you to go.',
				bodyClasses: 'hide-captcha',
				showSocial: false,
			}}
			locale={locale}
		/>

		{/* Component Content */}
		<S.Wrapper className={className}>
			<S.ContentContainer>
				<S.MessageContainer>
					<Typography tag="h1" weight="semibold">
						{title || 'Whoops!'}
					</Typography>
					<S.DescriptionContainer>
						<Typography tag="h4" weight="light">
							{description || 'We searched high and low but couldn\'t find what you\'re looking for. Let\'s find a better place for you to go.'}
						</Typography>
					</S.DescriptionContainer>
				</S.MessageContainer>
				<S.ActionContainer fullWidth={!buttons}>
					{
						buttons ? buttons.map((button) => (
							<S.ButtonLink to={button.link} key={button.text}>
								<Button size={button.size} variant={button.variant || 'solid'}>{button.text}</Button>
							</S.ButtonLink>
						)) : (
							<S.ButtonLink to={`/build/${v4()}`}>
								<Button size="large">Get Started</Button>
							</S.ButtonLink>
						)
					}
				</S.ActionContainer>
			</S.ContentContainer>
		</S.Wrapper>
	</>
);


/**
 * Configuration
 */

ErrorComponent.displayName = 'ErrorComponent';
ErrorComponent.propTypes = {
	locale: PropTypes.shape(),
	title: PropTypes.string,
	description: PropTypes.node,
	buttons: PropTypes.arrayOf(PropTypes.shape()),
	className: PropTypes.string
};
ErrorComponent.defaultProps = {
	locale: {},
	title: null,
	description: null,
	buttons: null,
	className: null
};
